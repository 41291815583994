
import { useState } from "react";


import { ContentType } from '../../../_mock/contentTypes';

import { Content, ContentImage, ContentAudio, ContentVideo, ContentText, ContentPanorama, ContentPuzzle, ContentWordPuzzle, ContentQuiz, ContentPictureFinder, ContentModel, ContentImageQuiz, ContentVideoQuiz, ContentModelQuiz, ContentGuide, ContentBeforeAfterQuiz, ContentAudioQuiz, ContentVideoTap, GuideState, ContentTapQuiz, ContentDragPuzzle, ContentTapFullscreen } from '.';

// ----------------------------------------------------------------------



export default function ContentAssignment(content) {

  const { id, contentId, route, fromMulti, multiTitle, onComplete, childContentRef } = content;
  const contents = route.content.filter((c) => contentId && contentId.includes(c.id) && c.type !== ContentType.Multi && c.type !== ContentType.MultiGrid && c.type !== ContentType.RouteComplete && c.type !== ContentType.RouteCompleteVideo);

  const [selectedContentIndex, setSelectedContentIndex] = useState(0);



  function nextContent() {
    if (selectedContentIndex < contents.length - 1) {
      const newIndex = selectedContentIndex + 1;
      setSelectedContentIndex(newIndex);
    } else {
      onComplete();
    }
  }

  /*
  const setAssignmentProgress = useCallback((content) => {
    const progress = contents.findIndex(c => c.id === content.id);
    localStorage.setItem(`${route.id}-${id}`, progress);
  }, [contents, id, route.id]);

  function getAssignmentProgress() {
    const progress = localStorage.getItem(`${route.id}-${id}`);

    if (progress !== (undefined || null) && progress > -1) {
      return progress;
    }
    else {
      return 0;
    }
  }
  */

  function prevContent() {
    if(selectedContentIndex > 0) {
      setSelectedContentIndex(selectedContentIndex - 1);
    }
  }

  function getGuideState(index) {
    if (index === 0) {
      return GuideState.Before;
    } else if (index === contents.length - 1) {
      return GuideState.After;
    } else {
      return GuideState.Content;
    }
  }


  let editedMultiTitle = multiTitle;
      // Find if title needs a letter added, like 'Opdracht 4A'
  if(contents.length > 3) {
    const guideCount = contents.filter(c => c.type === ContentType.Guide).length;
    const additions = new Array(contents.length).fill('');
    // regard as split assignment if multiple guides
    if(guideCount > 2) {
      const letters = ['A', 'B', 'C', 'D', 'E', 'F'];
      let letterIndex = 0;

      // update letter for assignments split by guide:   (Guide (a) , block a, Guide (b), block b, Guide)
      // check for double guides  (Guide (a), block a, Guide (a), Guide(b), block (b), Guide)
      for(let i = 0; i < contents.length; i += 1) {
        if( i > 0 && contents[i].type === ContentType.Guide 
          && (i === contents.length -1 || (i < contents.length && contents[i+1].type !== ContentType.Guide))
          ) {
          letterIndex += 1;
        }
        if(i < contents.length - 1) {
          additions[i] = letters[letterIndex];
        }
        if(i === selectedContentIndex) {
          editedMultiTitle += additions[i];
          break;
        }
      }
    }
  }


  return (

    <>
      {(() => {
        const selectedContent = contents[selectedContentIndex];
        if (selectedContent) {
          switch (selectedContent.type) {
            case ContentType.Image: return <ContentImage multiTitle={editedMultiTitle} contentRef={childContentRef} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.ImageQuiz: return <ContentImageQuiz multiTitle={editedMultiTitle} contentRef={childContentRef} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.BeforeAfterQuiz: return <ContentBeforeAfterQuiz multiTitle={editedMultiTitle} contentRef={childContentRef} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.Audio: return <ContentAudio multiTitle={editedMultiTitle}  {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.AudioQuiz: return <ContentAudioQuiz multiTitle={editedMultiTitle}  {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.Guide: return <ContentGuide route={route} multiTitle={editedMultiTitle}  {...selectedContent} guideState={getGuideState(selectedContentIndex)} onComplete={() => nextContent()} onBack={() => prevContent()}/>;
            case ContentType.Video: return <ContentVideo multiTitle={editedMultiTitle} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()}/>;
            case ContentType.VideoTap: return <ContentVideoTap multiTitle={editedMultiTitle} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()}/>;
            case ContentType.VideoQuiz: return <ContentVideoQuiz multiTitle={editedMultiTitle} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.Text: return <ContentText multiTitle={editedMultiTitle}  {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.Panorama: return <ContentPanorama multiTitle={editedMultiTitle} contentRef={childContentRef} height={`calc(100vh - 64px)`} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.Puzzle: return <ContentPuzzle multiTitle={editedMultiTitle} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.WordPuzzle: return <ContentWordPuzzle multiTitle={editedMultiTitle} {...selectedContent} onComplete={() => { nextContent() }} />;
            case ContentType.TapQuiz: return <ContentTapQuiz multiTitle={editedMultiTitle} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.DragPuzzle: return <ContentDragPuzzle multiTitle={editedMultiTitle} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.Tap2: return <ContentTapFullscreen multiTitle={editedMultiTitle} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
      //      case ContentType.BeforeAfter: return <ContentBeforeAfter multiTitle={editedMultiTitle} contentRef={childContentRef} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.Quiz: return <ContentQuiz multiTitle={editedMultiTitle} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()}/>;
      //      case ContentType.Share: return <ContentShare multiTitle={editedMultiTitle} contentRef={childContentRef} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.PictureFinder: return <ContentPictureFinder multiTitle={editedMultiTitle} contentRef={childContentRef} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
      //    case ContentType.Navigate: return <ContentNavigate multiTitle={editedMultiTitle} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.Model: return <ContentModel multiTitle={editedMultiTitle} contentRef={childContentRef} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            case ContentType.ModelQuiz: return <ContentModelQuiz multiTitle={editedMultiTitle} contentRef={childContentRef} {...selectedContent} onComplete={() => nextContent()} onBack={() => prevContent()} />;
            default: return <Content />
          }
        } else {
          return <></>
        }
      })()}
    </>
  );

}