/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */


import React from "react";
import { Motion, spring } from "react-motion";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { getMatrixPosition, getVisualPosition } from "./helpers";
import { TILE_COUNT, GRID_SIZE } from "./constants"



function Tile(props) {
  const { tile, index, width, height, handleTileClick, imgUrl, completed, selected, boardSize } = props;
  const { row, col } = getMatrixPosition(index);
  const visualPos = getVisualPosition(row, col, width, height);

  const motionStyle = {
    translateX: spring(visualPos.x),
    translateY: spring(visualPos.y)
  }

  const StyledTile = styled('li')(({ theme }) => ({
    width: `calc(100% / ${GRID_SIZE})`,
    height: `calc(100% / ${GRID_SIZE})`,
    translateX: visualPos.x,
    translateY: visualPos.y,
    backgroundImage: `url(${imgUrl})`,
    backgroundSize: `${boardSize}px calc(${GRID_SIZE}*100%)`,
    backgroundPosition: `${(100 / (GRID_SIZE - 1)) * (tile % GRID_SIZE)}% ${(100 / (GRID_SIZE - 1)) * (Math.floor(tile / GRID_SIZE))}%`,
    outline: selected ? `3px solid ${theme.palette.primary.main}` : 'none',
    outlineOffset: '-3px',
    // Is last tile?
    opacity: (tile === TILE_COUNT - 1 && !completed) ? 0 : 1,
  }));

  return (
    <Motion style={motionStyle}>
      {({ translateX, translateY }) => (
        <StyledTile
          style={{
            transform: `translate3d(${translateX}px, ${translateY}px, 0)`,
          }}
          className="tile"
          onClick={() => handleTileClick(index)}
        >
          {!imgUrl && `${tile + 1}`}
        </StyledTile>
      )}
    </Motion>
  );
}

Tile.propTypes = {
  tile: PropTypes.any,
  index: PropTypes.number,
  boardSize: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  imgUrl: PropTypes.string.isRequired,
  handleTileClick: PropTypes.func,
  completed: PropTypes.bool,
  selected: PropTypes.bool
};


export default Tile;
