// @mui
import { Box, Stack, Container, Button } from '@mui/material';

import { useRef, useState } from "react";

// components
import AudioPlayer from 'react-h5-audio-player';
import { styled } from '@mui/material/styles';
import 'react-h5-audio-player/lib/styles.css';
import '../../../components/audio2/style.css'

import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack } from '../../../components/content';

import { QuestionComponent } from '../../../components/quiz';
import HintOverlay from '../../../components/hint-overlay/hintOverlay';
import { formatUrl } from '../../../utils/formatUrl';
import { HintDialog } from '../../../components/hint-overlay';

// ----------------------------------------------------------------------

export default function ContentAudioQuiz(content) {

  const { url, title, text, question, answers, hintImage, hintButtonText, hintIcon, hintContent = null, hintDelay = 0, onComplete, fromMulti, multiTitle, onBack } = content;

  const questionRef = useRef();
  const [completed, setCompleted] = useState(false);

  function onPlay() {
    console.log('onPlay');
  }

  // override some colors from stle.css to use theme values
  const StyledAudioPlayer = styled(AudioPlayer)(({ theme }) => ({
    '.rhap_play-pause-button': {
      color: theme.palette.primary.main,
    },
    '.rhap_progress-indicator': {
      backgroundColor: theme.palette.primary.main,
    },
    '.rhap_progress-filled': {
      backgroundColor: theme.palette.primary.main,
    },

     /* '.rhap_container': { */
    backgroundColor: theme.palette.background.alternative,
    background: `linear-gradient(0deg, ${theme.palette.background.appbar}, ${theme.palette.background.alternative})`,

  }));

  function nextButton() {
    questionRef.current?.clearTextField();
    setCompleted(false);
    onComplete();
  }

  function checkAnswer() {
    if(questionRef.current?.checkAnswer()) {
      setCompleted(true);
    }

  }

  return (
    <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pt: '8px' }}>
      {
        hintContent && hintDelay > 0 ?
          <HintDialog hintContent={hintContent} delay={hintDelay} /> : null
      }
      <ContentStack spacing={2} sx={{ paddingBottom: {xs:'88px', sm:'112px'} }}> 
        <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexGrow:'0' }}>
            <Stack alignItems='center' spacing={2}>
            <CombinedTitle title={title} subtitle={multiTitle}/>
            <SubTitle variant="body1">
                {text}
            </SubTitle>
            </Stack>
        </Container>
        <Container sx={{ alignItems: 'center', display: 'flex', p: '16px !important', justifyContent: 'center', position: 'relative', flexGrow: 2 }}>
          <StyledAudioPlayer
            autoPlay={false}
            src={formatUrl(url)}
            loop={false}
            onPlay={() => onPlay()}
            hasDefaultKeyBindings={false}
            style={{ maxWidth: '600px' }}
            // hide most default buttons
            showSkipControls={false}
            showJumpControls={false}
            customAdditionalControls={[]}
          />
        </Container>
        
        <QuestionComponent sx={{flexGrow:'0'}} question={question} answers={answers} myRef={questionRef} disabled={completed} ignoreCase hint={hintContent} />  
               
               { hintImage ? 
                 <Container sx={{width:'100%', height:'auto', position:'relative', display:'flex', flexDirection:'column', flexGrow:'0', padding: '0px 16px !important'}}>
                   <HintOverlay buttonText={hintButtonText} image={hintImage} iconifyIcon={hintIcon} /> 
                 </Container> : null 
               }
        <ButtonsContainer>
            { completed ? 
                <Button sx={{ mb: '0px' }} variant="contained" onClick={() => { nextButton() }}>
                    {fromMulti ? "Terug naar overzicht" : "Verder"}
                </Button>
            :
                <Button sx={{ mb: '0px' }} variant="contained" onClick={() => { checkAnswer() }}>
                    {"Controleer antwoord"}
                </Button>
            }
            {
                onBack ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button>: null
            }
        </ButtonsContainer>
      </ContentStack>
    </Box>
  );
}
