import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Container, Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';


import { useState, useEffect, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { isFirefox, isIOS } from 'react-device-detect';
import { RequestCamera } from '../utils/permission';
import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack,  } from '../components/content';
import { AlertDialog } from '../components/dialog';

import { SupportsWebGL } from "../utils/supportsWebgl";


const useWebgl = SupportsWebGL();


// ----------------------------------------------------------------------

const StyledContent = styled('div')(() => ({
  boxSizing: 'border-box',
  margin: 0,
  flexDirection: 'row',
  flexBasis: '100%',
  flexGrow: '0',
  maxWidth: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'unset',
  alignItems: 'center',
  padding: '0 !important'
}));

export default function PermissionsPage() {
  const [geolocationPermission, setGeolocationPermission] = useState('prompt');
  const [cameraPermission, setCameraPermission] = useState('prompt');
  const [deviceOrientationPermission, setDeviceOrientationPermission] = useState('prompt');

  const [permissionDialogState, SetPermissionDialogState] = useState(false); // denied permission Dialog
  const [permissionSkipState, SetPermissionSkipState] = useState(false); // skip permission Dialog

  const navigate = useNavigate();

  const CheckPermission = useCallback((permissionName, descriptor) => {
    try {
      navigator.permissions.query({ name: permissionName, ...descriptor })
        .then((permission) => {
          SetPermission(permissionName, permission.state);
          permission.onchange = () => {
            SetPermission(permissionName, permission.state);
          };
        }).catch((e) => {
          console.log(e);
          SetPermission(permissionName, 'unsupported');
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    CheckPermission("geolocation");
    if (isFirefox) {
      SetPermission('camera', 'unknown');
    }
    else {
      CheckPermission("camera");
    }

  }, [CheckPermission]);

  function SetPermission(permissionName, state) {
    switch (permissionName) {
      case "geolocation":
        setGeolocationPermission(state);
        break;
      case "camera":
        setCameraPermission(state);
        break;
      case "deviceorientation":
        setDeviceOrientationPermission(state);
        break;
      default:
        break;
    }
  }

  function TranslatedPermissionState(permissionState) {
    switch (permissionState) {
      case "granted":
        return "Geaccepteerd"
      case "unknown":
        return "Onbekend"
      case "denied":
        return "Geweigerd"
      case "unsupported":
        return "Niet ondersteund"
      case "prompt":
        return "Vraag toestemming"  
      default:
        return "Onbekend"
    }
  }

  function RequestCameraPermission() {
    if (cameraPermission === 'denied') {
      SetPermissionDialogState(true)
    } else  {
      RequestCamera( 
        () => SetPermission('camera', 'granted'), 
        () => SetPermission('camera', 'denied'),
        () => SetPermission('camera', 'unsupported'),
      );
    }
  }

  // Device Orientation
  const RequestDeviceOrientationPermission = useCallback(() => {
    if (window.location.protocol !== "https:") {
      console.log('not secure');
      return;
    }

    if (typeof (DeviceMotionEvent) !== "undefined" && typeof (DeviceMotionEvent.requestPermission) === "function") {
      DeviceMotionEvent.requestPermission()
        .then(response => {
          SetPermission('deviceorientation', response)
        })
        .catch(console.error)
    } else {
      alert("DeviceMotionEvent is not defined");
    }
  }, []);



  // Location
  function RequestLocationPermission() {
    if (geolocationPermission === 'denied') {
      SetPermissionDialogState(true)
    } else {
      navigator.geolocation.getCurrentPosition(onLocationFetchSuccess, onLocationFetchFailure);
    }
  }

  const onLocationFetchSuccess = () => {
    setGeolocationPermission('granted');
  };

  const onLocationFetchFailure = (error = {}) => {
    // Error code 1 corresponds to user denying/blocking the location permission
    setGeolocationPermission('denied');
    if (error.code === 1) {
      // Respond to failure case as required
    }
  };

  return (
    <>
      <Helmet>
        <title> Toestemmingen </title>
      </Helmet>


      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
        <ContentStack spacing={2}>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Stack alignItems='center' spacing={2}>
              <CombinedTitle title='Apparaat toestemmingen'/>
              <SubTitle variant="body1">
                Deze web app kan gebruik maken van hardware zoals je camera. Voor de beste gebruikerservaring raden we aan om alle onderstaande toestemmingen te accepteren.
              </SubTitle>
            </Stack>
          </Container>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative', flexGrow: '2', maxWidth: '900px !important', flexDirection: 'column'}}>

            { /*
            	<Stack sx={{ flexDirection: "row", flexWrap: 'wrap', maxWidth: '600px', mb: '8px', justifyContent: 'space-between', width: '100%', backgroundColor: 'background.paper', padding: '16px', alignItems: 'center', boxShadow: '0 2px 2px rgb(0 0 0 / 12%)' }}>
                
                <Stack sx={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '230px', flex: '2', margin: '4px' }}>
                  
                  <Typography variant="body1" sx={{ mr: '4px', fontWeight: '500', fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)', fontFamily: 'nitti-typewriter-cameo' }}>
                    Locatie
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)',  color: geolocationPermission === 'granted' ? '#50994B' : '#CC5652', mt: '4px' }}>
                    {TranslatedPermissionState(geolocationPermission)}
                  </Typography>

                </Stack>

                <Button disabled={geolocationPermission === 'granted' || geolocationPermission === 'unsupported'} onClick={() => RequestLocationPermission()} variant="text" sx={{m: '4px', background: 'rgb(172 48 62 / 8%)', padding: '6px 16px', width: {xs: '100%', sm: 'auto'} }}>
                  Toestemming vragen
                </Button>

              </Stack>
              */}

              <Stack sx={{ flexDirection: "row", flexWrap: 'wrap', maxWidth: '600px', mb: '8px', justifyContent: 'space-between', width: '100%', backgroundColor: 'background.paper', padding: '16px', alignItems: 'center', boxShadow: '0 2px 2px rgb(0 0 0 / 12%)' }}>
                
                <Stack sx={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '230px', flex: '2', margin: '4px' }}>
                  
                <Typography variant="body1" sx={{ mr: '4px', fontWeight: '500', fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)', fontFamily: 'nitti-typewriter-cameo' }}>
                    Camera
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)', color: cameraPermission === 'granted' ? '#50994B' : '#CC5652', mt: '4px'}}>
                    {TranslatedPermissionState(cameraPermission)}
                  </Typography>
                </Stack>
                  
                <Button disabled={cameraPermission === 'granted' || cameraPermission === 'unsupported'} onClick={() => RequestCameraPermission()} variant="text" sx={{m: '4px', background: 'rgb(172 48 62 / 8%)', padding: '6px 16px', width: {xs: '100%', sm: 'auto'} }}>
                  Toestemming vragen
                </Button>

              </Stack>


              <Stack sx={{ flexDirection: "row", flexWrap: 'wrap', maxWidth: '600px', mb: '8px', justifyContent: 'space-between', width: '100%', backgroundColor: 'background.paper', padding: '16px', alignItems: 'center', boxShadow: '0 2px 2px rgb(0 0 0 / 12%)' }}>

                <Stack sx={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '230px', flex: '2', margin: '4px' }}>

                  <Typography variant="body1" sx={{ mr: '4px', fontWeight: '500', fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)', fontFamily: 'nitti-typewriter-cameo' }}>
                    Orientatie
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)', color: (!isIOS || deviceOrientationPermission === 'granted') ? '#50994B' : '#CC5652', mt: '4px'}}>
                    {isIOS ? TranslatedPermissionState(deviceOrientationPermission) : 'Geaccepteerd'}
                  </Typography>

                </Stack>
                
                {isIOS ?
                  <Button disabled={deviceOrientationPermission === 'granted' || deviceOrientationPermission === 'unsupported'} onClick={() => { RequestDeviceOrientationPermission(); }} variant="text" sx={{m: '4px', background: 'rgb(172 48 62 / 8%)', padding: '6px 16px', width: {xs: '100%', sm: 'auto'} }} >
                    Toestemming vragen
                  </Button>
                  :
                  <Button disabled variant="text" sx={{m: '4px', background: 'rgb(0 0 0 / 8%)', color: 'rgb(0 0 0 / 32%) !important' , padding: '6px 16px', width: {xs: '100%', sm: 'auto'}}}>
                    Toestemming vragen
                  </Button>
                }

              </Stack>

              <Stack sx={{ flexDirection: "row", flexWrap: 'wrap', maxWidth: '600px', mb: '8px', justifyContent: 'space-between', width: '100%', backgroundColor: 'background.paper', padding: '16px', alignItems: 'center', boxShadow: '0 2px 2px rgb(0 0 0 / 12%)' }}>
                
                <Stack sx={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '230px', flex: '2', margin: '4px' }}>
                  
                <Typography variant="body1" sx={{ mr: '4px', fontWeight: '500', fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)', fontFamily: 'nitti-typewriter-cameo' }}>
                    WebGL
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)', color: cameraPermission === 'granted' ? '#50994B' : '#CC5652', mt: '4px'}}>
                    {useWebgl ? "Toegestaan" : "Niet toegestaan"}
                  </Typography>
                </Stack>
                  

              </Stack>

          </Container>

          
          <ButtonsContainer>
            <Button onClick={() => { navigate('/', { replace: true }); }} fullWidth sx={{ mb: '0px' }} variant="contained">Terug</Button>
          </ButtonsContainer>
        </ContentStack>
      </Box>

      {/* exit dialog */}
      <AlertDialog open={permissionDialogState} handleClose={() => SetPermissionDialogState(false)} title="Toestemming geweigerd"
        content="Deze toestemming is geweigerd. Ga naar je browserinstellingen om deze toestemming aan te passen."
        primaryButtonText="Ik begrijp het" secondaryButtonText=""
        primaryButtonFunc={() => { }} secondaryButtonFunc={() => { }} />

      {/* skip dialog */}
      <AlertDialog open={permissionSkipState} handleClose={() => SetPermissionSkipState(false)} title="Overslaan"
        content="Mogelijk werken sommige van de applicatie niet. Weet je zeker dat je verder wilt gaan?"
        primaryButtonText="Verder" secondaryButtonText="Terug"
        primaryButtonFunc={() => { }} secondaryButtonFunc={() => { }} />
    </>
  );
}
