export const ContentType = {
    Text: "text",
    Audio: "audio",
    AudioQuiz: "audioquiz",
    Video: "video",
    VideoTap: "videotap",
    VideoQuiz: "videoquiz",
    Image: "image",
    ImageQuiz: "imagequiz",
    Panorama: "panorama",
    Puzzle: "puzzle",
    WordPuzzle: "wordpuzzle",
    // Tap: "tap",
    Tap2: "tap2",
    TapQuiz: "tapquiz",
    DragPuzzle: "dragpuzzle",
    // BeforeAfter: "beforeafter",
    BeforeAfterQuiz: "beforeafterquiz",
    Quiz: "quiz",
    // Share: "share",
    PictureFinder: "picturefinder",
    Multi: "multi",
    MultiGrid: "multigrid",
    // Navigate: "navigate",
    Model: "model",
    ModelQuiz: 'modelquiz',
    RouteComplete: "routecomplete",
    RouteCompletePreview: "routecompletepreview",
    RouteCompleteVideo: "routecompletevideo",
    Assignment: 'assignment',
    Guide: 'guide',
  };
  