import { Helmet } from 'react-helmet-async';

// @mui
import { Grid } from '@mui/material';

// components
import { useParams, useNavigate, useOutletContext } from "react-router-dom";

import useMediaQuery from '@mui/material/useMediaQuery';

import { useState, useRef, useEffect, useCallback, useImperativeHandle } from 'react';
import { Content, ContentImage, ContentAudio, ContentVideo, ContentText, ContentPanorama, ContentPuzzle, ContentQuiz, ContentPictureFinder, ContentMulti, ContentMultiGrid, ContentModel, ContentRouteComplete, ContentVideoQuiz, ContentImageQuiz, ContentAudioQuiz, ContentModelQuiz, ContentRouteCompleteVideo, ContentRouteCompletePreview } from '../sections/@route/content';
import { route } from '../_mock/routes';
import { ContentType } from '../_mock/contentTypes';

import { AlertDialog } from '../components/dialog';

// ----------------------------------------------------------------------

const APP_BAR_HEIGHT = 56;

export default function ContentPage() {

  const {myRef, current} = useOutletContext();
  const [currentContent, setCurrentContent] = current;

  const params = useParams();
  const navigate = useNavigate();

  // const isCompactHeight = useMediaQuery('(min-width:1200px)');

  const multiContentRef = useRef({});
  const contentRef = useRef({});


  const backIndex = useRef(0);
  const r = route(params.routeId);

  const [skipDialogState, setSkipDialogState] = useState(false); // Skip Dialog
  const [exitDialogState, setExitDialogState] = useState(false); // Exit Dialog
  const [continueRouteState, setContinueRouteState] = useState((getRouteProgress() > 0)); // Continue Route Dialog

  const previousContent = getPreviousContent();
  const nextContent = getNextContent();

  function getPreviousContent() {
    const currentIndex = r.content.findIndex(c => c.id === parseInt(currentContent.id, 10));
    if(currentIndex > 0) {
      return r.content[currentIndex - 1];
    }
    return undefined;
  }

  function getNextContent() {
    const currentIndex = r.content.findIndex(c => c.id === parseInt(currentContent.id, 10));
    if(currentIndex < r.content.length -1 && currentContent.type !== ContentType.RouteComplete && currentContent.type !== ContentType.RouteCompleteVideo) {
      return r.content[currentIndex + 1];
    }
    return undefined;
  }

  const setRouteProgress = useCallback((content) => {
    const progress = r.content.findIndex(c => c.id === content.id);
    localStorage.setItem(r.id, progress);
  }, [r.content, r.id]);

  const next = useCallback(() => {
    if (nextContent !== undefined) {
      backIndex.current += 1;
      setRouteProgress(nextContent);
      setCurrentContent(nextContent);
    }

  }, [nextContent, setRouteProgress, setCurrentContent]);

  const previous = useCallback(() => {
    if (previousContent !== undefined) {
      backIndex.current -= 1;
      setCurrentContent(previousContent);
    }
  }, [previousContent, setCurrentContent]);


  const isInsideMultiContent = useCallback(()=>{
    if ((currentContent.type === ContentType.Multi || currentContent.type === ContentType.MultiGrid) && multiContentRef.current.selectedContent && multiContentRef.current.selectedContent.id >= 0) {
      return true;
    }
    else {
      return false;
    }
  },[currentContent.type]);

  // on browser back button
  const back = useCallback(() => {

    if(contentRef.current && (typeof contentRef.current.requestBack === "function") && contentRef.current.requestBack() ) {

      // content block intercepted back
    } else if(isInsideMultiContent()) {
       multiContentRef.current.returnToMenu();
    }
    // go back a content block
    else if (backIndex.current > 0) {
      window.history.pushState({}, '');
      previous();
    } else {
      // normal back button behaviour
      window.history.back();
    }


  }, [isInsideMultiContent, previous]);

  const returnMultiToMenu = useCallback(() => {
    if(isInsideMultiContent()) {
      multiContentRef.current.returnToMenu();
   }
  }, [isInsideMultiContent]);

  const onComplete = useCallback(() => {
    next()
  }, [next]);


  useImperativeHandle(myRef, () => ({
   returnMultiToMenu,
   onComplete,
  }), [returnMultiToMenu, onComplete])

  // cleanup back button event
  useEffect(() => () => {
    window.removeEventListener('popstate', back);
  }, [back]);

  // attach back button event
  useEffect(() => {
    window.history.pushState({}, '');
    window.removeEventListener('popstate', back);
    window.addEventListener('popstate', back);
  }, [back]);

  return <>
    <Helmet>
      <title> Expeditie - {r.name} </title>
    </Helmet>
    <Grid sx={{ height: /* (isCompactHeight && currentContent.type !== ContentType.Navigate) ? '87vh' : */ `100%`, display: 'flex', flexDirection: 'column', justifyContent: 'unset', alignItems: 'center' }} item xs={12}>
      {(() => {
        switch (currentContent.type) {
          case ContentType.Image: return <ContentImage contentRef={contentRef} {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.ImageQuiz: return <ContentImageQuiz contentRef={contentRef} {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Audio: return <ContentAudio {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.AudioQuiz: return <ContentAudioQuiz {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Video: return <ContentVideo {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.VideoQuiz: return <ContentVideoQuiz {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Text: return <ContentText {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Panorama: return <ContentPanorama contentRef={contentRef} height={`calc(100vh - ${APP_BAR_HEIGHT}px)`} {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Puzzle: return <ContentPuzzle {...currentContent} onComplete={() => { onComplete(); }} />;
      //    case ContentType.Tap: return <ContentTap {...currentContent} onComplete={() => { onComplete(); }} />;
      //    case ContentType.BeforeAfter: return <ContentBeforeAfter contentRef={contentRef} {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Quiz: return <ContentQuiz {...currentContent} onComplete={() => { onComplete(); }} />;
      //    case ContentType.Share: return <ContentShare contentRef={contentRef} {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.PictureFinder: return <ContentPictureFinder {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Multi: return <ContentMulti childContentRef={contentRef} {...currentContent} route={r} myRef={multiContentRef} onComplete={() => { onComplete(); }} />;
          case ContentType.MultiGrid: return <ContentMultiGrid childContentRef={contentRef} {...currentContent} route={r} myRef={multiContentRef} onComplete={() => { onComplete(); }} />;
      //    case ContentType.Navigate: return <ContentNavigate {...currentContent} route={r} onComplete={() => { onComplete(); }} />;
          case ContentType.Model: return <ContentModel contentRef={contentRef} {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.ModelQuiz: return <ContentModelQuiz contentRef={contentRef} {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.RouteComplete: return <ContentRouteComplete {...currentContent} route={r} />;
          case ContentType.RouteCompletePreview: return <ContentRouteCompletePreview {...currentContent} route={r} onComplete={() => { onComplete(); }} />;
          case ContentType.RouteCompleteVideo: return <ContentRouteCompleteVideo {...currentContent} route={r} />;
          default: return <Content />
        }
      })()}
    </Grid>

    {/* Skip dialog */}
    <AlertDialog open={skipDialogState} handleClose={() => setSkipDialogState(false)} title="Overslaan"
      content="Weet je zeker dat je dit deel van het verhaal wilt overslaan?"
      primaryButtonText="Overslaan" secondaryButtonText="Terug"
      primaryButtonFunc={() => { next(); }} secondaryButtonFunc={() => { }} />

    {/* exit dialog */}
    <AlertDialog open={exitDialogState} handleClose={() => setExitDialogState(false)} title="Terug naar home"
      content="Weet je zeker dat je terug wilt gaan naar de homepagina?"
      primaryButtonText="Naar homepagina" secondaryButtonText="Terug"
      primaryButtonFunc={() => { navigate(`/`, { replace: true }); }} secondaryButtonFunc={() => { }} />

    {/* continue route dialog */}
    <AlertDialog open={continueRouteState} handleClose={() => setContinueRouteState(false)} title="Mysterie vervolgen"
      content="Wil je verder gaan waar je gebleven was?"
      primaryButtonText="Start vanaf begin" secondaryButtonText="Verder"
      primaryButtonFunc={() => { removeProgress(); setCurrentContent(r.content[0]) }} secondaryButtonFunc={() => { }} />
  </>

  function isFirst() {
    return previousContent === undefined
  }

  function isLast() {
    return nextContent === undefined
  }

  function onNext() {
    setSkipDialogState(true);
  }

  function onPrevious() {
    previous();
  }

  function onExit() {
    setExitDialogState(true);
  }

  function LoadContent() {
    const progress = getRouteProgress();
    if (progress !== (undefined || null)) {
      return r.content[progress];
    }
    else {
      return r.content[0];
    } 
  };

  function getRouteProgress() {
    const progress = localStorage.getItem(r.id);

    if (progress !== (undefined || null) && progress > -1) {
      return progress;
    }
    else {
      return 0;
    }
  }

  function removeProgress() {
    const progress = localStorage.getItem(r.id);
    if (progress !== (undefined || null)) {
      localStorage.removeItem(r.id);
    }
    // remove any saves for ContentMulti
    for(let i = 0; i < r.content.length; i +=1){
      if(r.content[i].type === ContentType.Multi || r.content[i].type === ContentType.MultiGrid /* || r.content[i].type === ContentType.Assignment */) {
        localStorage.removeItem(`${r.id}-${r.content[i].id}`);
      }
    }
  }

}
