import { useRef, useEffect, useCallback, useState, useImperativeHandle } from "react";

import { Stack, Box, Button, Container } from '@mui/material';

import {
  SnapList,
  SnapItem,
  useScroll,
} from 'react-snaplist-carousel';

import { isMobile } from "react-device-detect";
import Iconify from "../../../components/iconify";

import { Content, ContentImage, ContentAudio, ContentVideo, ContentText, ContentPanorama, ContentPuzzle, ContentQuiz, ContentPictureFinder, ContentModel, ContentImageQuiz, ContentAudioQuiz, ContentVideoQuiz, ContentModelQuiz, ContentAssignment, ContentTapQuiz, ContentDragPuzzle, ContentTapFullscreen } from '.';
import { ContentType } from '../../../_mock/contentTypes';
import { CarouselItem } from '../../../components/content-multi';
import { AlertDialog } from '../../../components/dialog';

import { CarouselNavigationButton } from "../../../components/carousel";

import { ButtonsContainer, Title, SubTitle, ContentStack, ButtonsContainerHeightBlocker } from '../../../components/content';


export default function ContentMulti(content) {

  const snapList = useRef(null);
  const goToSnapItem = useScroll({ ref: snapList });

  const [isComplete, setCompleteState] = useState(false);
  const [completeDialogState, SetCompleteDialogState] = useState(false); // complete Dialog
  const [incompleteDialogState, SetIncompleteDialogState] = useState(false); // complete Dialog

  // const CAROUSEL_ITEM_SIZE = 300;

  const [selectedContent, setSelectedContent] = useState(content.selectedContent);
  const [selectedContentIndex, setSelectedContentIndex] = useState(0);

  const { title, text, route, myRef, contentId, onComplete, childContentRef, allowSkip = false } = content;
  const contents = route.content.filter((c) => contentId.includes(c.id));

  const completionText = 'Je hebt alle content bekeken. Lekker bezig!';


  const incompleteText = "Je hebt nog niet alle onderdelen voltooid. Weet je zeker dat je ze wilt overslaan?";

  // Keep track of the state of the image buttons inside an array
  const [contentItemStates, setContentItemStates] = useState(loadMultiProgress());

  // Whenever a state is changed, check if the game is completed
  useEffect(() => {
    function isGameComplete() {
      return contentItemStates.every(c => c === true);
    }

    goToSnapItem(selectedContentIndex);

    if (!isComplete && isGameComplete()) {
      setCompleteState(true);

      setTimeout(() => {
        if (!completeDialogState) {
          SetCompleteDialogState(true);
        }
      }, 1000);
    }

  }, [completeDialogState, contentItemStates, goToSnapItem, isComplete, selectedContentIndex, onComplete]);

  const saveMultiProgress = useCallback((states) => {
    try {
      localStorage.setItem(`${route.id}-${content.id}`, JSON.stringify(states));
    } catch(e) {
      console.log("failed to set multi progress");
    }
  },[content.id, route.id]);

  function loadMultiProgress() {
    let states = Array(contents.length).fill(false);
    try {
      const json = localStorage.getItem(`${route.id}-${content.id}`);
      if(json) {
        const savedStates = JSON.parse(json);
        if(savedStates.length === contents.length) {
          states = savedStates;
        }
      }
    } catch(e) {
      console.log("failed to load multi progress");
    }
    return states;
  }

  const completeSelectedContent = useCallback(() => {
    if(!contentItemStates[selectedContentIndex]) {
      const contentStates = contentItemStates.slice(); // Create a copy of the bool array
      contentStates[selectedContentIndex] = true; // Toggle the bool
      setContentItemStates(contentStates); // Update state
      saveMultiProgress(contentStates);
    }
  },[contentItemStates, saveMultiProgress, selectedContentIndex]);

  const returnToMenu = useCallback((completed) => {
    setSelectedContent(undefined);
    if(completed) {
      completeSelectedContent();
    }
  },[completeSelectedContent])

  const returnWithCompletion = () => returnToMenu(true);

  useImperativeHandle(myRef, () => ({
    returnToMenu, selectedContent
  }), [returnToMenu, selectedContent])

  const items = getContents(contents.filter(c => c.type !== ContentType.Navigate && c.type !== ContentType.Multi && c.type !== ContentType.MultiGrid && c.type !== ContentType.RouteComplete && c.type !== ContentType.RouteCompleteVideo && c.type !== ContentType.RouteCompleteVideo && c.type !== ContentType.ContentGuide));

  function getContents(contents) {
    const contentItems = [];
    for (let i = 0; i < contents.length; i += 1) {
        contentItems.push(
          <SnapItem key={i} margin={{ left: '12px', right: '12px' }} snapAlign="center">
              <CarouselItem selected={i === selectedContentIndex} completed={contentItemStates[i]} content={contents[i]} callback={() => { setSelectedContentIndex(i); if(selectedContentIndex === i) { setSelectedContent(contents[selectedContentIndex]); } else { goToSnapItem(i); } }}/> 
          </SnapItem>
        );
    }
    return contentItems;
  }

  function nextContent(){
    selectContent(selectedContentIndex + 1);
  }

  function previousContent(){
    selectContent(selectedContentIndex - 1);
  }

  function selectContent(i){
    setSelectedContentIndex(i);
    goToSnapItem(i); 
  }

  function completeMulti() {
    if(isComplete) {
      onComplete();
    } else {
      SetIncompleteDialogState(true);
    }
  }

  return (
    <>
    { (selectedContent === undefined) ?  

      <>
        <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pt: '8px' }}>
          <ContentStack spacing={2}>
            <Container sx={{alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
            <Stack alignItems='center' spacing={2}>
              <Title variant="h1">
                {title}
              </Title>
              <SubTitle variant="body1">
                {text}
              </SubTitle>
            </Stack>
            </Container>
            <Container sx={{alignItems: 'center', display: 'flex', p: '0px !important', justifyContent: 'center', position: 'relative'}}>
              <SnapList
                ref={snapList}
                tabIndex={0} // so it can receive focus and can be scrolled with keyboard
                role="region" // context for screen readers
                aria-label="carousel" // for screen readers to read out loud on focus
                className="carousel"
                style={{ scrollPadding: '0px', padding: '0px 12px', WebkitMaskImage: 'linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%)', maskImage: 'linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%)'}}
              >
                {items}
              </SnapList>

              { !isMobile && 
                // hide scroll buttons if mobile touchscreen
                <>
                  { selectedContentIndex !== 0 && 
                    <CarouselNavigationButton onClick={() => previousContent()} sx={{left: '8px'}} aria-label="previous content">
                      <Iconify sx={{width: '24px', height: '24px', color: '#FFF'}} icon='ic:baseline-navigate-before'/> 
                    </CarouselNavigationButton>
                  }
                  
                  { selectedContentIndex < items.length - 1 && 
                    <CarouselNavigationButton onClick={() => nextContent()} sx={{right: '8px'}} aria-label="next content">
                      <Iconify sx={{width: '24px', height: '24px', color: '#FFF'}} icon='ic:baseline-navigate-next'/> 
                    </CarouselNavigationButton>                         
                  }
                </>
             }
            </Container>
    
        <ButtonsContainerHeightBlocker />
        <ButtonsContainer>
              {
              allowSkip ? <Button sx={{ mb: '0px' }} variant={isComplete ? "contained" : "outlined"} onClick={() => { completeMulti() }}>{isComplete ? "Verder" : "Overslaan"}</Button>
              : <Button sx={{ mb: '0px' }} variant="contained" disabled={!isComplete} onClick={() => { onComplete() }}>{"Verder"}</Button>
              }        
            </ButtonsContainer>
          </ContentStack>
        </Box>

        {/* complete dialog */}
        <AlertDialog open={completeDialogState} handleClose={() => SetCompleteDialogState(false)} title="Voltooid"
        content={completionText} 
        primaryButtonText="Sluiten" secondaryButtonText="" 
        primaryButtonFunc={() => { SetCompleteDialogState(false)}} secondaryButtonFunc={() => { }}/>
        {/* incomplete dialog */}
        <AlertDialog open={incompleteDialogState} handleClose={() => SetIncompleteDialogState(false)} title="Overslaan"
        content={incompleteText} 
        primaryButtonText="Terug" secondaryButtonText="Overslaan" 
        primaryButtonFunc={() => { SetIncompleteDialogState(false)}} secondaryButtonFunc={() => { onComplete(); }}/>

      </>

      : 

      <>
          {(() => {
                switch (selectedContent.type) {
                  case ContentType.Image: return <ContentImage contentRef={childContentRef} multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion(); }} />;
                  case ContentType.ImageQuiz: return <ContentImageQuiz contentRef={childContentRef} multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion(); }} />;
                  case ContentType.Audio: return <ContentAudio multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
                  case ContentType.AudioQuiz: return <ContentAudioQuiz multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
                  case ContentType.Assignment: return <ContentAssignment {...selectedContent} route={route} multiTitle={title} fromMulti contentRef={childContentRef}  onComplete={() => { returnWithCompletion(); }} />;
                  case ContentType.Video: return <ContentVideo multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
                  case ContentType.VideoQuiz: return <ContentVideoQuiz multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
                  case ContentType.Text: return <ContentText multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
                  case ContentType.Panorama: return <ContentPanorama contentRef={childContentRef} multiTitle={title} fromMulti height={`calc(100vh - 64px)`} {...selectedContent} onComplete={() => { returnWithCompletion(); }} />;
                  case ContentType.Puzzle: return <ContentPuzzle multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
                  case ContentType.TapQuiz: return <ContentTapQuiz multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
                  case ContentType.DragPuzzle: return <ContentDragPuzzle multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
                  case ContentType.Tap2: return <ContentTapFullscreen multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
               //   case ContentType.BeforeAfter: return <ContentBeforeAfter contentRef={childContentRef} multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
                  case ContentType.Quiz: return <ContentQuiz multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
               //   case ContentType.Share: return <ContentShare contentRef={childContentRef} multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
                  case ContentType.PictureFinder: return <ContentPictureFinder contentRef={childContentRef} multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
               //  case ContentType.Navigate: return <ContentNavigate multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
                  case ContentType.Model: return <ContentModel contentRef={childContentRef} multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
                  case ContentType.ModelQuiz: return <ContentModelQuiz contentRef={childContentRef} multiTitle={title} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              default: return <Content/>
            }
          })()}
      </>
    }
    </>
  );
}