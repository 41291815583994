import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Drawer, Divider, Stack } from '@mui/material';
import { formatUrl } from '../../../utils/formatUrl';

// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';

import { Title } from '../../../components/content';

//
import navConfig from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column', backgroundColor: 'primary.lighter' },
        '&::before': {
          content: '""',
          position: 'absolute',
          width: 'calc(100% + 8px)',
          height: 'calc(100% + 16px)',
          top: '-8px',
          left: '-4px',
          filter: 'url(#combined-noise-displacement)',
          zIndex: 0,
          backgroundColor: 'primary.lighter',
          boxShadow: '0 0 2px 0 rgb(0 0 0 / 20%), 0 12px 24px -4px rgb(0 0 0 / 12%)',
        }
      }}
    >

      <Box sx={{ pt: 3 }}>
        <Logo />
      </Box>

      <Divider sx={{ my: 3 }} />

      <Box sx={{ mx: 2.5 }}>
        <Title variant="caption" primary={"true"} sx={{ marginRight: 'auto', padding: '2px 8px' }}>
          Algemeen
        </Title>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
      <Divider sx={{}} />
      <Stack sx={{ p: 3, flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
        <img style={{ height: '32px' }} alt="Kunstwerk logo" src={formatUrl('assets/logos/kunstwerk.svg')} />
        <img style={{ height: '48px' }} alt="Leven in de Liemers logo" src={formatUrl('assets/logos/lidl-logo.png')} />
      </Stack>
      <span className="unselectable" style={{ position: 'absolute', bottom: '8px', left: '16px', opacity: '0.15', width: '100%', fontSize: '10px' }}> {process.env.REACT_APP_VERSION}</span>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              '& > div': { overflow: 'visible' },
              overflowY: 'visible',
              width: NAV_WIDTH,
              borderRightStyle: 'solid',
              borderColor: 'rgba(255, 255, 255, 0.08)'
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          anchor="left"
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH, overflow: 'unset', },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
