/* eslint-disable react/prop-types */
import { useDroppable, useDraggable, DndContext } from '@dnd-kit/core';


import {
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';


import { Box } from '@mui/material';

import PropTypes from 'prop-types';
import { useRef, useState, useEffect, useCallback } from 'react';


 function restrictToBoundingRect(
  transform,
  rect,
  boundingRect
) {
  const value = {
    ...transform,
  };

  if (rect.top + transform.y <= boundingRect.top) {
    value.y = boundingRect.top - rect.top;
  } else if (
    rect.bottom + transform.y >=
    boundingRect.top + boundingRect.height
  ) {
    value.y = boundingRect.top + boundingRect.height - rect.bottom;
  }

  if (rect.left + transform.x <= boundingRect.left) {
    value.x = boundingRect.left - rect.left;
  } else if (
    rect.right + transform.x >=
    boundingRect.left + boundingRect.width
  ) {
    value.x = boundingRect.left + boundingRect.width - rect.right;
  }

  return value;
}


// restrict only undropped draggables to parent.
export const customRestrictToParentElement = (props) => {

  const {
    containerNodeRect,
    draggingNodeRect,
    transform,
    active
  } = props;
  if (!draggingNodeRect || !containerNodeRect) {
    return transform;
  }
  if(active && active.id.includes('dropped')) {
    return transform;
  }

  return restrictToBoundingRect(transform, draggingNodeRect, containerNodeRect);
};





function WordPuzzle({ words, prefilled = [], showIncorrect = false, onComplete, children }) {


  function cleanInput(input) {
    const cleaned = input.toLowerCase().split('');
    // make sure rows dont start with a space.
   // let removed = 0;
    while(cleaned.length > 10 && cleaned[10] === ' ') {
      cleaned.splice(10,1);
     // removed += 1;
    }
    while(cleaned.length > 20 && cleaned[20] === ' ') {
      cleaned.splice(10,1);
      // removed += 1;
    }
    return cleaned;
  }

  const input = cleanInput(words);

  const [initialized, setInitialized] = useState(false);
  const resizeTimeout = useRef(null);

  const [completed, setCompleted] = useState(false);
  const [allDroppedOnce, setAllDroppedOnce] = useState(false);

  const ref = useRef(null);

  const dropsToContent = useRef({});
  const dragsToDestination = useRef({});
  const lockedDrops = useRef({});

  const generateInitLocations = useCallback(() => {
    const dict = {};
    const width = ref.current.offsetWidth;
    const height = ref.current.offsetHeight;

    for (let i = 0; i < input.length; i += 1) {
      // random absolute position for tiles
      dict[i.toString()] = { x: getRandomInt(0.1 * width, 0.9 * width), y: getRandomInt(0.1 * height, 0.5 * height) };
    }
    return dict;
  }, [input.length]);

  const onResize = useCallback(() => {
    clearTimeout(resizeTimeout.current);
    resizeTimeout.current = setTimeout(() => {
        // force shuffle on resize to avoid tile going offscreen
      setInitialized(false);
    }, 100);
  },[]);

  const dragPositions = useRef(undefined);

  useEffect(() => {
    if (!initialized) {
      dragPositions.current = generateInitLocations();

      // Drop any letters that need to be filled in already
      for(let i = 0; i< prefilled.length; i+=1 ) {
        const j = prefilled[i];
        if(j !== ' ') {
          if(j >= 0 && j < input.length) {
            dropDraggable(j.toString(), `${j}${input[j]}`);
            lockedDrops.current[`${j}${input[j]}`] = true;
          }
        }
      }

      setInitialized(true);     // trigger simple state change
    }

  }, [generateInitLocations, setInitialized, initialized, input, prefilled, onResize])

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    }

  }, [onResize])





  function checkCorrect() {
    let correct = true;
    let allDropped = true;


    for(let i = 0; i< input.length; i+=1) {
      // check if letter in the id matches the desired letter.
      if(input[i] === ' ' || (dragsToDestination.current[i] && dragsToDestination.current[i].slice(-1)[0]  === input[i]))
      {
        // alright
      } else {
        correct = false;
        if(allDroppedOnce) {
          break;
        } else if(allDropped && !dragsToDestination.current[i]) {
          allDropped = false;
          break;
        }
      }  
    }
    if(correct) {
      setCompleted(true);
      if(onComplete) {
        onComplete();
      }
    } 

    if(!allDroppedOnce && allDropped) {
      setAllDroppedOnce(true);
    }
    
  }




  function dropDraggable(dragId, dropId) {
    dropsToContent.current[dropId] = dragId;
    dragsToDestination.current[dragId] = dropId;
  }

  function removeDraggable(dragId, dropId) {
    dragsToDestination.current[dragId] = undefined;
    dropsToContent.current[dropId] = undefined;
  }

  // make sure the last row is filled out to max size
  const rowInput = input;
  const multiplier = Math.ceil(rowInput.length / 10);
  if(multiplier > 1) {
    while(rowInput.length < (multiplier * 10)) {
      rowInput.push(' ');
    }
  }

  return (
    <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToWindowEdges, customRestrictToParentElement]}>
      <Box ref={ref} id="WordPuzzle" sx={{ width:'calc(100% - 64px)', height: '100%', position: 'relative', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'column' }} >
        
        { children }

        { /* dragable tiles */ }
        {input.map((letter, index) => letter !== ' ' ? <Dragable id={index.toString()} key={index.toString()}>{letter.toUpperCase()}</Dragable> : null)}
        
        { /* rows of drop targets, max 10 per row */ }
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent:'start', gap: '8px' }}>
          {rowInput.slice(0,10).map((letter, index) => <Droppable id={index.toString() + letter} key={index.toString() + letter} letter={letter} />)}
        </Box>
        {
          input.length > 10 ? 
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px',justifyContent:'start', marginTop:'8px' }}>
          {rowInput.slice(10, 20).map((letter, index) => <Droppable id={(index+10).toString() + letter} key={(index+10).toString() + letter} letter={letter} />)}
         
        </Box>
         : null 
         }
          {
          input.length > 20 ? 
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems:'start', marginTop:'8px' }}>
          {rowInput.slice(20, 30).map((letter, index) => <Droppable id={(index+20).toString() + letter} key={(index+20).toString() + letter} letter={letter} />)}
         
        </Box>
         : null 
         }
         {/* <Box sx={{height:{xs:'32px', sm:'64px'}}} /> */}
      </Box>
    </DndContext>
  );

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }


  function handleDragEnd(event) {
    const { active, over } = event;

    // trim unique id used for placed tiles
    const dragId = active.id.replace('dropped-', '');

    // hovering on a target
    if (over) {

      const dropId = over.id;

      const previousDrop = dragsToDestination.current[dragId];
      const alreadyInTarget = dropsToContent.current[dropId];

      // swap item in target to source
      if (previousDrop && alreadyInTarget && previousDrop !== dropId && alreadyInTarget !== dragId) {
        dropDraggable(alreadyInTarget, previousDrop);

        dropDraggable(dragId, dropId);
      } else {

        // if something is already dropped here, remove it.
        if (alreadyInTarget && alreadyInTarget !== dragId) {
          removeDraggable(alreadyInTarget, dropId);
        }
        // if we dragged from another drop point
        else if (previousDrop && previousDrop !== dropId) {
          removeDraggable(dragId, previousDrop);
        }

        dropDraggable(dragId, dropId);
      }
      checkCorrect();

    } else {
      // not over a dropable
      const currentDropId = dragsToDestination.current[dragId];

      if (currentDropId) {
        removeDraggable(dragId, currentDropId);
      } else {
        active.data.current?.updateOffset(event.delta.x, event.delta.y);
      }

    }
  }


  function Dragable(props) {

    const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
      id: props.id,
      disabled: props.disabled || completed,
      data: { updateOffset }
    });


    function updateOffset(x, y,) {
      const current = dragPositions.current[props.id];
      dragPositions.current[props.id] = { x: current.x + x, y: current.y + y };
    }


    const style = {
      visibility: dragsToDestination.current[props.id] ? 'hidden' : '',
      transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : '',
      position: 'absolute',
      top: props.dropped || !dragPositions.current ? 'unset' : `${dragPositions.current[props.id].y}px`,
      left: props.dropped || !dragPositions.current ? 'unset' : `${dragPositions.current[props.id].x}px`,
      display: 'flex',
      alignItems: 'center',
      fontSize: {xs:'20px', sm:'24px'},
      justifyContent: 'center',
      zIndex: isDragging ? '4' : '3',
      width: { xs: 'calc(10vw - 12px)', sm: '50px' },
      height: { xs: 'calc(10vw - 12px)', sm: '50px' },
      backgroundColor: '#FFF8F7',
      outline: `2px ${ (showIncorrect && allDroppedOnce && props.incorrect) ? '#AC303E' : '#ECE0DF'} solid`,
      borderRadius: '0.075em',
      boxShadow: '0px 2px 4px #000000',
      cursor: props.disabled ? 'default' : 'grab',
      touchAction: 'none', // fix mobile chrome performance
    };

    return (
      <Box ref={setNodeRef} sx={style} {...listeners} {...attributes}>
        {props.children}
      </Box>
    );
  }


  function Droppable(props) {
    const { isOver, setNodeRef } = useDroppable({
      id: props.id,
      disabled: props.letter === ' ' || completed,
    });

    const style = {
      // letter ' ' means filler tile to pad the UI
      visibility: props.letter === ' ' ? 'hidden' : 'unset',
      backgroundColor: isOver ? 'rgba(162, 135, 135, 1)' : 'rgba(162, 135, 135, 0.58)',
      borderRadius: '0.075em',
      outline: '1px rgba(162, 135, 135, 1) solid',
      width: { xs: 'calc(10vw - 12px)', sm: '50px' },
      height: { xs: 'calc(10vw - 12px)', sm: '50px' },
    };

    const disableDrag = lockedDrops.current[props.id] ?? false;
    return (
      <Box ref={setNodeRef} sx={style}>
        {
          dropsToContent.current[props.id] ?
            <Dragable disabled={disableDrag} id={`dropped-${dropsToContent.current[props.id]}`} dropped="true" incorrect={input[dropsToContent.current[props.id]] !== props.letter} >{input[dropsToContent.current[props.id]].toUpperCase()}</Dragable>
            : null
        }
      </Box>
    );

    
  }

}







WordPuzzle.propTypes = {
  onComplete: PropTypes.func,
  words: PropTypes.string,
  prefilled: PropTypes.arrayOf(PropTypes.number),
};

export default WordPuzzle;
