import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Title = styled(Typography)(({ primary = false, backgroundcolor= '#362F2F', theme}) => ({
  textAlign: 'center',
  position:'relative',
  color:'#FBEEED',
  textTransform: 'uppercase',
  zIndex:'0',
  '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: primary ? theme.palette.primary.main: backgroundcolor,
      top: '0px',
      left: '0px',
      filter: 'url(#combined-noise-displacement)',
      zIndex: '-1',
  }
}));

export default Title;