// import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0px', // Number(theme.shape.borderRadius) * 4,
          height: '48px',
          [theme.breakpoints.down('sm')]: {
            height: '40px',
          },
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: 'theme.palette.common.white',
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          color: 'theme.palette.common.white',
          maxWidth: '300px',
          // [theme.breakpoints.down('sm')]: {
          //   maxWidth: 'unset',
          // },
          flexGrow: '1',
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        outlinedInherit: {
          border: `1px solid primary.main`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlinedPrimary: {
          border: `1px solid primary.main`,
          maxWidth: '300px',
          flexGrow: '1',
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
