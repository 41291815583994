// basically a copy of ContentGuide, but without play button and transcription

// @mui
import { Box, Container, Button, Stack } from '@mui/material';

// components
import ReactPlayer from 'react-player/lazy';
import { isIOS, isSafari } from 'react-device-detect';
import { useState, useRef } from 'react';

import { ButtonsContainer, CombinedTitle, ContentStack, SubTitle } from '../../../components/content';
import { formatUrl } from '../../../utils/formatUrl';

// ----------------------------------------------------------------------

export const GuideState = {
    Before: 'before',
    Content: 'content',
    After: 'after',
};


export default function ContentVideoTap(content) {

    const { videoUrl, videoAppleUrl, title, text, fromMulti, multiTitle, onComplete, guideState, continueDelay = 10000, onBack } = content;

    let nextTimeout = null;

    const videoRef = useRef(null);


    const autoplay = false;

    const [ended, setEnded] = useState(false);
    const [playing, setPlaying] = useState(autoplay);
    const [videoReady, setVideoReady] = useState(false);

    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

    // refs of setState because useState gives old values inside setTimeout
    const playingRef = useRef(playing);

    function onCompleteButtonClick() {
        clearTimeout(nextTimeout);
        onComplete();
    }

    function setNextButtonTimeout() {
        if(!nextTimeout && nextButtonDisabled) {
            nextTimeout = setTimeout(() => {
                if(nextButtonDisabled) {
                    setNextButtonDisabled(false);
                }
            }, continueDelay);
        }
    }

    function toggleMedia() {
       
        if(ended) {
            videoRef.current?.seekTo(0);
            setEnded(false);
            setPlaying(true);
            playingRef.current = true;
        } else {
            const newPlayState = !playing;
            setPlaying(newPlayState);
            playingRef.current = newPlayState;
        }
    }


    function onMediaComplete() {
        if(nextButtonDisabled) {
            setNextButtonDisabled(false);
        }

        setPlaying(false);
        setEnded(true);
    }

    return (
        <>        
        <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
            <ContentStack spacing={2} sx={{ paddingBottom: {xs:'88px', sm:'112px'} }}> 
                <Container sx={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'center', flexGrow: '0' }}>
                    <Stack alignItems='center' spacing={2}>
                        <CombinedTitle title={title} subtitle={multiTitle}/>
                            <SubTitle variant="body1">
                                {text}
                            </SubTitle>
                    </Stack>
                </Container>
                <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative', flexGrow: '2'}} onClick={() => toggleMedia()}>
                    <ReactPlayer
                        ref={videoRef}
                        height='auto'
                        width='100%'
                        volume={1}
                        style={{ maxHeight: '54vh', display: 'flex', justifyContent: 'center', zIndex: 2, transition:'opacity 0.15s ease-in-out', opacity: videoReady ? '1' : '0'  }}
                        onReady={() => {
                            setVideoReady(true);
                        }}
                        config={{ file: { attributes: { playsInline: true, }, }}}
                        // video with transparent background
                        // HEVC for safari and iOS, webm for others
                        // will not be transparent on iOS <= 12 (iphone 6, 5s, ipad mini 3)
                        url={ formatUrl(((isIOS || isSafari) && videoAppleUrl) ? videoAppleUrl : videoUrl )}
                        playing={playing}
                        controls={false}
                        onPlay={() => {
                            setNextButtonTimeout();
                        }}
                        onEnded={() => onMediaComplete()}
                        onClick={() => toggleMedia}
                    />
                </Container>

                <ButtonsContainer>
                    <Button sx={{ mb: '0px' }} disabled={nextButtonDisabled} variant="contained" onClick={() => { onCompleteButtonClick() }}>{guideState === GuideState.After ? "Volgende opdracht" : guideState === GuideState.Before ? "Begin opdracht" : "Verder"}</Button>
                    {
                        onBack && guideState === GuideState.content ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button>: null
                    }
                </ButtonsContainer>
            </ContentStack>
        </Box>
        </>


    );

}