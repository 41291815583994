// ----------------------------------------------------------------------

// import { upperCase } from "lodash";

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({xs, sm, md, lg }) {
  return {
    '@media (max-width:599px)': {
      fontSize: pxToRem(xs),
    },
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'nitti-typewriter-normal'; 
const FONT_SECONDARY = 'veneer-two';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: { // Headline
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    lineHeight: 1,
    fontSize: pxToRem(48),
    ...responsiveFontSizes({xs:32, sm: 48, md: 56, lg: 64 }),
  },
  h2: { // Kompas afstand
    fontWeight: 500,
    lineHeight: 64 / 48,
    fontSize: pxToRem(33),
    ...responsiveFontSizes({ xs: 33, sm: 33, md: 37, lg: 41 }),
  },
  h3: { // Title L - Homepage card title, Routenaam in description
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ xs:16, sm: 20, md: 20, lg: 20 }),
    letterSpacing: 1,
  },
  h4: { // Title M - Content multi card title
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({xs:15, sm: 15, md: 16, lg: 16 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ xs:19, sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ xs:18, sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: { // Main body text
    lineHeight: 1.4,
    fontSize: pxToRem(16),
    // letterSpacing: 0.0125,
  },
  body2: { // Routebeschrijving, Video button labels, search bar
    lineHeight: 24 / 13,
    fontSize: pxToRem(14),
    // letterSpacing: 0.0125,
  },
  body3: { // Timecode video/audio, plaatsnaam route card
    lineHeight: 22 / 14,
    fontSize: pxToRem(12),
    // letterSpacing: 0.25,
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 500,
    lineHeight: 24 / 13,
    fontSize: pxToRem(13),
    letterSpacing: 0.5,
    textTransform: 'unset',
  },
};

export default typography;
