
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { AlertDialog } from '../dialog';



export default function HintOverlay({ hintContent = null, hintTitle = "Hint", delay = 25000, hintShownCallback = null}) {

  const [shownOnce, setShownOnce] = useState(false)
  const [hidden, setHidden] = useState(true);
  const timer = useRef(null);


  function close() {
    setShownOnce(true);
    setHidden(true)
  }

  function show() {
    setHidden(false);
    hintShownCallback?.invoke();
  }

  useEffect(() => {
        if(delay && delay > 0) {
          timer.current = setTimeout(() => {
              setHidden(false);
              hintShownCallback?.invoke();
          }, delay);
        }
    
        return () => {
            clearTimeout(timer.current);
        }
      
  }, [delay, hintShownCallback])

    return (
       <>
         <AlertDialog open={!hidden && !shownOnce} handleClose={() => {close()}} 
            title={hintTitle}
            content={hintContent}
            primaryButtonText="Sluiten" secondaryButtonText=""
            primaryButtonFunc={() => {close()}} 
            secondaryButtonFunc={() => { }} 
            />
       </>
    );

}



HintOverlay.propTypes = {
    hintContent: PropTypes.string,
    hintTitle: PropTypes.string,
    delay: PropTypes.number,
    hintShownCallback: PropTypes.func,
}
