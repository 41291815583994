// npm install / yarn add  panolens
// npm install / yarn add  three

// <Pano imageUrl="XXX.jpg"/>

import { /* useState, */ useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import * as PANOLENS from "panolens";
import { isMobile, isIOS } from 'react-device-detect';
import './style.css';
import { formatUrl } from '../../utils/formatUrl';

// iOS requires permission request over https: `DeviceMotionEvent.requestPermission();`

const Pano = ({ imageSrc, infoSpots = [], infoSpotCallback, infoSpotIcon, fov = 90 }) => {

    const url = imageSrc;
    const panorama = useRef(undefined);
    const viewer = useRef(undefined);
    const useGyro = useRef(false);


    // const infoSpots = useRef([]);

    const gyroUpdates = useRef(0);

    useEffect(() => {

        function onGyro(event) {
            if (event.rotationRate.alpha || event.rotationRate.beta || event.rotationRate.gamma) {
                if (!useGyro.current) {
                    useGyro.current = true;
                    viewer.current.enableControl(PANOLENS.CONTROLS.DEVICEORIENTATION);
                    window.removeEventListener("devicemotion", onGyro);
                }
            } else {
                // don't remove listener on first event, as first event on firefox might not contain rotationRate
                if (gyroUpdates.current > 3) {
                    window.removeEventListener("devicemotion", onGyro);
                }
                gyroUpdates.current += 1;
            }
        }


        // on load
        if (!viewer.current) {
            console.log("init panorama");
            panorama.current = new PANOLENS.ImagePanorama(url);
            viewer.current = new PANOLENS.Viewer({
                container: document.querySelector("#pano"),
                // controlBar: false, // hidden in css instead
                autoHideInfospot: false,
            });
            viewer.current.getCamera().fov = fov;
            viewer.current.getCamera().updateProjectionMatrix();

            viewer.current.add(panorama.current);

            for (let i = 0; i < infoSpots.length; i += 1) {

                let url;
                if(infoSpotIcon) {
                    url = formatUrl(`${infoSpotIcon}#${i}` );
                } else {
                    url = PANOLENS.DataImage.Arrow;
                }
                const infospot = new PANOLENS.Infospot(250, url);
                infospot.position.set(infoSpots[i].x, infoSpots[i].y, infoSpots[i].z);

                const div = document.createElement("div");
                div.style.minWidth = "100px";
                div.style.maxWidth = "340px";
                div.style.minHeight = "56px";
                div.style.padding = "16px";
                div.style.background = "#FFF8F7";
                div.style.color = "#201A1A";
                div.style.borderRadius = "4px";
                div.innerHTML = infoSpots[i].message;

                infospot.addHoverElement(div, -150);

                // eslint-disable-next-line no-inner-declarations
                function hideOthers() {
                    const children = document.getElementById('pano').querySelectorAll('.panolens-infospot');
                    for (let j = 0; j < children.length; j += 1) {
                        if (j !== i) {
                            children[j].style.setProperty('display', 'none');
                        }
                    }
                }
                infospot.addEventListener('click', () => {
                    hideOthers();
                    if(infoSpotCallback) {
                        infoSpotCallback(i);
                    }
                });
                infospot.addEventListener('hover', () => {
                    hideOthers();
                    if(infoSpotCallback) {
                        infoSpotCallback(i);
                    }
                });

                panorama.current.add(infospot);
            }

            // These crash when controlBar: false
            viewer.current.enableEffect(PANOLENS.MODES.NORMAL);

            if (isMobile || isIOS) {
                if (typeof (DeviceMotionEvent) !== "undefined" && typeof (DeviceOrientationEvent.requestPermission) === "function") {
                    DeviceOrientationEvent.requestPermission()
                        .then((response) => {
                            if (response === "granted") {
                                viewer.current.enableControl(PANOLENS.CONTROLS.DEVICEORIENTATION);
                            } else {
                                console.log('device orientation denied');
                            }
                        });
                }
                else {
                    // wait on gyro event to confirm support
                    window.addEventListener("devicemotion", onGyro);
                }
            }
            return undefined; // return something to satisfy eslint
        }

        // dispose cleanup
        return () => {
            console.log("cleanup panorama");
            if (infoSpots.current) {
                for (let i = 0; i < infoSpots.current.length; i += 1) {
                    infoSpots.current[i]?.dispose();
                }
            }

            if (viewer.current) {
                if (panorama.current) {
                    viewer.current.remove(panorama.current);
                }
                viewer.current.destroy();
                viewer.current = null;
            }
            if (document.querySelector("#pano")) {
                document.querySelector("#pano").innerHTML = "";
            }
            infoSpots.current = [];
            panorama.current = null;
            window.removeEventListener("devicemotion", onGyro);
        };
    }, [url, infoSpots, infoSpotCallback, infoSpotIcon, fov]);

    return (
        <>
            <div id="pano" style={{ height: '100%', width: "100%" }} />
        </>
    );
};

Pano.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    infoSpots: PropTypes.arrayOf(PropTypes.object),
    infoSpotCallback: PropTypes.func,
    infoSpotIcon: PropTypes.string,
    fov: PropTypes.number,
};

export default Pano;
