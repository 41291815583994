import { useState } from "react";

// @mui
import { Box, Typography, Stack, Button, Container } from '@mui/material';
import { isIOS } from "react-device-detect";

import { AlertDialog } from '../../../components/dialog';
import HintOverlay from '../../../components/hint-overlay/hintOverlay';

import { ButtonsContainer, ButtonsContainerHeightBlocker, CombinedTitle, ContentStack, SubTitle } from '../../../components/content';


// ----------------------------------------------------------------------

export default function ContentQuiz(content) {
  const { title, question, correctIndex, options, onComplete, fromMulti, multiTitle, onBack, hintImage, hintButtonText, hintIcon, allowSkip = false } = content;

  const [isComplete, setCompleteState] = useState(false);

  const [lastClicked, setLastClicked] = useState(-1);

  const [completeDialogState, SetCompleteDialogState] = useState(false); // complete Dialog
  const [wrongDialogState, SetWrongDialogState] = useState(false); // wrong answer Dialog

  const completionText = 'Je hebt het juiste antwoord gevonden. Goed bezig!';

  const letters = ['A', 'B', 'C', 'D', 'E', 'F'];
  const buttons = [];
  for (let i = 0; i < options.length; i += 1) {
    const option = options[i];
    buttons.push(
      <Box key={option}
        sx={{
          '&:hover': isIOS || isComplete ? {} : { cursor: 'pointer', transform: 'scale(1.02)' }, // avoid safari hover bug
          width: '100%',
          maxWidth: '600px',
          background: lastClicked === i ? (i === correctIndex ? '#0C7C59' : '#F8EBEA') : (theme) => theme.palette.background.alternative,
          mb: '8px',
          p: '12px',
          borderRadius: '8px',
          boxShadow: '0 0 2px 0 rgb(0 0 0 / 20%), 0 12px 24px -4px rgb(0 0 0 / 12%)',
          border: 'solid 1px transparent',
          borderColor: lastClicked === i ? (i === correctIndex ? '#0C7C59' : '#CC5652') : 'transparent',
          transition: '0.2s',
        }}
        onClick={() => { if (!isComplete || i === correctIndex) { SelectOption(i); } }}>
        <Stack sx={{ flexDirection: 'row' }}>
          <Box sx={{ flex: '0 1' }}>
            <Box sx={{
              height: '42px',
              width: '42px',
              background: '#AC303E',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '20px',
              fontWeight: '500',
              mr: '12px',
              lineHeight: '1',
              color: '#FBEEED',
            }}>
              {letters[i]}
            </Box>
          </Box>
          <Typography variant="body2" sx={{
            lineHeight: '1.666',
            display: 'flex',
            alignItems: 'center',
            color: lastClicked === i ? (i === correctIndex ? '#ffffff' : '#524343') : '#524343',
          }}> {option}
          </Typography>
        </Stack>

      </Box>
    )
  };

  function SelectOption(index) {
    setLastClicked(index);
    if (index === correctIndex || correctIndex === -1) {
      SetCompleteDialogState(true);
      setCompleteState(true);
    }
    else {
      SetWrongDialogState(true);
    }
  }

  return (
    <>
      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
        <ContentStack spacing={2} sx={{ paddingBottom: { xs: '88px', sm: '112px' } }}>
          <Container sx={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'center', flexGrow: '0' }}>
            <Stack alignItems='center' spacing={2}>
              <CombinedTitle title={title} subtitle={multiTitle} />
              <SubTitle variant="body1">
                {question}
              </SubTitle>
            </Stack>
          </Container>

          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative', flexGrow: '2' }}>
            <Stack sx={{ flexDirection: { xs: "column" }, flexGrow: 1, alignItems: 'center' }}>
              {buttons}
            </Stack>
          </Container>

          {hintImage ?
            <Container sx={{ width: '100%', height: 'auto', position: 'relative', display: 'flex', flexDirection: 'column', flexGrow: '0', padding: '0px 16px !important', }}>
              <HintOverlay buttonText={hintButtonText} image={hintImage} iconifyIcon={hintIcon} />
            </Container> : null
          }

          <ButtonsContainer>
            {
              allowSkip ? <Button sx={{ mb: '0px' }} variant={isComplete ? "contained" : "outlined"} onClick={() => { onComplete() }}>{fromMulti ? "Terug naar overzicht" : (isComplete ? "Verder" : "Overslaan")}</Button>
                : <Button sx={{ mb: '0px' }} variant="contained" disabled={!isComplete} onClick={() => { onComplete() }}>{fromMulti ? "Terug naar overzicht" : "Verder"}</Button>
            }
            {
              onBack ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button> : null
            }
          </ButtonsContainer>
        </ContentStack>
      </Box>

      {/* correct dialog */}
      <AlertDialog open={completeDialogState} handleClose={() => SetCompleteDialogState(false)} title="Voltooid"
        content={completionText}
        primaryButtonText="Sluiten" secondaryButtonText=""
        primaryButtonFunc={() => { SetCompleteDialogState(false) }} secondaryButtonFunc={() => { }} />

      {/* wrong answer dialog */}
      <AlertDialog open={wrongDialogState} handleClose={() => SetWrongDialogState(false)} title="Verkeerd"
        content="Dit is niet het juiste antwoord."
        primaryButtonText="Opnieuw proberen" secondaryButtonText=""
        primaryButtonFunc={() => { SetWrongDialogState(false) }} secondaryButtonFunc={() => { }} />
    </>
  );
}
