import { useRef, forwardRef, useState } from "react";

import { ButtonBase, Stack, Box, Typography, Button, Container } from '@mui/material';

import PropTypes from 'prop-types';

import {
  SnapList,
  SnapItem,
  useScroll,
} from 'react-snaplist-carousel';

import { styled } from '@mui/material/styles';
import { isMobile } from "react-device-detect";
import { AlertDialog } from '../../../components/dialog';

import { CarouselNavigationButton } from '../../../components/carousel';

import Iconify from "../../../components/iconify";

import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack } from '../../../components/content';
import HintOverlay from '../../../components/hint-overlay/hintOverlay';
import { formatUrl } from '../../../utils/formatUrl';
import { HintDialog } from "../../../components/hint-overlay";

export default function ContentPictureFinder(content) {
  const snapList = useRef(null);

  const [selectedItem, setSelectedItem] = useState(null);

  const [isComplete, setCompleteState] = useState(false);
  const [completeDialogState, SetCompleteDialogState] = useState(false); // complete Dialog
  const [wrongDialogState, SetWrongDialogState] = useState(false); // wrong answer Dialog

  const goToSnapItem = useScroll({ ref: snapList });

  const completionText = 'Je hebt de juiste afbeelding gevonden. Goed bezig!';

  // const CAROUSEL_ITEM_SIZE = 300;

  const { title, text, items, correctItem, onComplete, fromMulti, multiTitle, onBack, allowSkip = false, hintImage, hintButtonText, hintIcon, hintContent = null, hintDelay = 25000 } = content;

  const SelectionOverlay = styled('div')(({ theme }) => ({
    border: `4px solid ${theme.palette.primary.main}`,
    borderRadius: '8px',
    height: '100%',
    width: '100%',
    position: 'absolute',
  }));

  const CarouselImage = forwardRef(({ children, ...props }, ref) => (
    <div style={{ flexDirection: 'column' }}>
      <ButtonBase onClick={() => clickImage(props.index)}>
        <div
          style={{
            // maxWidth: '70vw',
            // width: `${CAROUSEL_ITEM_SIZE}px`,
            // height: `${CAROUSEL_ITEM_SIZE}px`,
            background: `url('${formatUrl(props.item.image)}')`,
            backgroundSize: 'cover',
            borderRadius: '8px',
            height: 'clamp(172px, 1rem + 26vh, 392px)',
            width: 'clamp(172px, 1rem + 26vh, 392px)'
          }}
          ref={ref}
        >
          {children}
        </div>

        {props.index === selectedItem ? <SelectionOverlay /> : null}
      </ButtonBase>
      <Typography variant='h4' sx={{ textAlign: 'center', mt: 2, textOverflow: 'ellipsis', width: 'clamp(172px, 1rem + 26vh, 392px)', whiteSpace: '', overflow: 'hidden' }}>{props.item.name}</Typography>
    </div>
  ));

  CarouselImage.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.object
  }

  CarouselImage.displayName = "carouselImage";

  const imageButtons = getImages(items);

  function clickImage(i) {
    goToSnapItem(i);
    setSelectedItem(i);
  }

  function isGameComplete() {
    if (!isComplete && (correctItem === selectedItem || correctItem === -1)) {
      setCompleteState(true);

      if (!completeDialogState) {
        SetCompleteDialogState(true);
      }
    }
    else {
      SetWrongDialogState(true);
    }
  }

  function nextImage() {
    clickImage(selectedItem + 1);
  }

  function previousImage() {
    clickImage(selectedItem - 1);
  }

  function getImages(items) {
    const carouselItems = [];
    for (let i = 0; i < items.length; i += 1) {
      carouselItems.push(
        <SnapItem key={i} margin={{ left: '15px', right: '15px' }} snapAlign="center">
          <CarouselImage item={items[i]} index={i} />
        </SnapItem>
      );
    }
    return carouselItems;
  }

  return (
    <>
      {
        hintContent && hintDelay > 0 ?
        <HintDialog hintContent={hintContent} delay={hintDelay} /> : null
      }
      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
        <ContentStack spacing={2} sx={{ paddingBottom: {xs:'88px', sm:'112px'} }}>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexGrow:'0' }}>
          <Stack alignItems='center' spacing={0}>
            <CombinedTitle title={title} subtitle={multiTitle}/>
            <SubTitle variant="body1">
              {text}
            </SubTitle>
          </Stack>
          </Container>
          <Container sx={{ alignItems: 'center', display: 'flex', p: '0px !important', justifyContent: 'center', position: 'relative', flexGrow: 2 }}>
            <SnapList
              ref={snapList}
              tabIndex={0} // so it can receive focus and can be scrolled with keyboard
              role="region" // context for screen readers
              aria-label="carousel" // for screen readers to read out loud on focus
              className="carousel"
              style={{ flexGrow: '3', scrollPadding: '0px', padding: '0px 12px', WebkitMaskImage: 'linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%)', maskImage: 'linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%)' }}
            >
              {imageButtons}
            </SnapList>

            { !isMobile && 
              // hide scroll buttons if mobile touchscreen
              <>
                {selectedItem !== 0 &&
                  <CarouselNavigationButton onClick={() => previousImage()} sx={{ left: '8px' }} aria-label="previous content">
                    <Iconify sx={{ width: '24px', height: '24px', color: '#FFF' }} icon='ic:baseline-navigate-before' />
                  </CarouselNavigationButton>
                }

                {selectedItem < items.length - 1 &&
                  <CarouselNavigationButton onClick={() => nextImage()} sx={{ right: '8px' }} aria-label="next content">
                    <Iconify sx={{ width: '24px', height: '24px', color: '#FFF' }} icon='ic:baseline-navigate-next' />
                  </CarouselNavigationButton>
                }
              </>  
            }
          </Container>
          
          { hintImage ? 
               <Container sx={{width:'100%', height:'auto', position:'relative', display:'flex', flexDirection:'column', flexGrow:'0', padding: '0px 16px !important', }}>
                  <HintOverlay buttonText={hintButtonText} image={hintImage} iconifyIcon={hintIcon} /> 
              </Container> : null 
            }

        
        <ButtonsContainer>
            { !isComplete ? <Button disabled={(selectedItem === null) || isComplete} sx={{  }} variant="contained" onClick={() => { isGameComplete() }}>Controleer antwoord</Button>
            : <Button sx={{ mb: '0px' }} variant={"contained"} onClick={() => { onComplete() }}>{fromMulti ? "Terug naar overzicht" :  "Verder"}</Button>
            }
            {
                onBack ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button>: 
                  allowSkip ? <Button variant="outlined" onClick={() => { onComplete(); }}>{"Overslaan"}</Button> : null
            }
          </ButtonsContainer>
        </ContentStack>
      </Box>

      {/* complete dialog */}
      <AlertDialog open={completeDialogState} handleClose={() => SetCompleteDialogState(false)} title="Voltooid"
        content={completionText}
        primaryButtonText="Sluiten" secondaryButtonText=""
        primaryButtonFunc={() => { SetCompleteDialogState(false); }} secondaryButtonFunc={() => { }} />

      {/* wrong answer dialog */}
      <AlertDialog open={wrongDialogState} handleClose={() => SetWrongDialogState(false)} title="Verkeerd"
        content={`Deze foto is niet de juiste.${hintContent ? `\n\nHint: ${hintContent}` : ''}`}
        primaryButtonText="Opnieuw proberen" secondaryButtonText=""
        primaryButtonFunc={() => { SetWrongDialogState(false) }} secondaryButtonFunc={() => { }} />
    </>
  );
}