import { useEffect, useRef, useCallback, useState, useImperativeHandle } from "react";

import { Stack, Box, Button, Container, Grid } from '@mui/material';

import { Content, ContentImage, ContentAudio, ContentVideo, ContentText, ContentPanorama, ContentPuzzle, ContentWordPuzzle, ContentQuiz, ContentPictureFinder, ContentModel, ContentAssignment, ContentImageQuiz, ContentAudioQuiz, ContentVideoQuiz, ContentModelQuiz, ContentTapQuiz, ContentDragPuzzle, ContentTapFullscreen } from '.';
import { ContentType } from '../../../_mock/contentTypes';
import { GridItem, GRID_ITEM_HEIGHT } from '../../../components/content-multi';
import { AlertDialog } from '../../../components/dialog';


import { ButtonsContainer, SubTitle, ContentStack, CombinedTitle } from '../../../components/content';

// import useResponsive from '../../../hooks/useResponsive';

export default function ContentMultiGrid(content) {

  const [isComplete, setCompleteState] = useState(false);
  const [completeDialogState, SetCompleteDialogState] = useState(false); // complete Dialog
  const [incompleteDialogState, SetIncompleteDialogState] = useState(false); // complete Dialog

  // const CAROUSEL_ITEM_SIZE = 300;

  const [selectedContent, setSelectedContent] = useState(content.selectedContent);
  const [selectedContentIndex, setSelectedContentIndex] = useState(0);

  const [completeAnyInSession, setCompleteAnyInSession] = useState(false);

  const { title, text, route, myRef, contentId, onComplete, childContentRef, allowSkip = false } = content;
  const contents = route.content.filter((c) => contentId.includes(c.id));

  const completionText = 'Je hebt alle onderdelen bekeken. Lekker bezig!';

  // const isDesktop = useResponsive('up', 'lg');

  const incompleteText = "Je hebt nog niet alle onderdelen voltooid. Weet je zeker dat je ze wilt overslaan?";

  // Keep track of the state of the image buttons inside an array
  const [contentItemStates, setContentItemStates] = useState(loadMultiProgress());

  const savedScrollPos = useRef(null);

  const completeMulti = useCallback((forceComplete = false) => {
    if (isComplete || forceComplete) {
      onComplete();
    } else {
      SetIncompleteDialogState(true);
    }

  }, [isComplete, onComplete])

  // Whenever a state is changed, check if the game is completed
  useEffect(() => {
    function isGameComplete() {
      return contentItemStates.every(c => c === true);
    }

    // triggered by returnToMenu callback
    if (!isComplete && isGameComplete()) {
      setCompleteState(true);

      if (completeAnyInSession) {
        completeMulti(true);
      }
      /*
      setTimeout(() => {
        if (!completeDialogState) {
          SetCompleteDialogState(true);
        }
      }, 1000);
      */

    }

  }, [completeAnyInSession, completeDialogState, completeMulti, contentItemStates, isComplete, onComplete]);


  const saveMultiProgress = useCallback((states) => {
    try {
      localStorage.setItem(`${route.id}-${content.id}`, JSON.stringify(states));
    } catch (e) {
      console.log("failed to set multi progress");
    }
  }, [content.id, route.id]);

  function loadMultiProgress() {
    let states = Array(contents.length).fill(false);
    try {
      const json = localStorage.getItem(`${route.id}-${content.id}`);
      if (json) {
        const savedStates = JSON.parse(json);
        if (savedStates.length === contents.length) {
          states = savedStates;
        }
      }
    } catch (e) {
      console.log("failed to load multi progress");
    }
    return states;
  }

  const completeSelectedContent = useCallback(() => {
    if (!contentItemStates[selectedContentIndex]) {
      const contentStates = contentItemStates.slice(); // Create a copy of the bool array
      contentStates[selectedContentIndex] = true; // Toggle the bool
      setContentItemStates(contentStates); // Update state
      saveMultiProgress(contentStates);
    }
  }, [contentItemStates, saveMultiProgress, selectedContentIndex]);

  useEffect(() => {
    if (selectedContent == null && savedScrollPos.current != null) {
      document.getElementById('scroll-root').scrollTop = savedScrollPos.current;
      savedScrollPos.current = null;
    }
  }, [selectedContent]);

  const returnToMenu = useCallback((completed) => {
    setSelectedContent(undefined);
    if (completed) {
      setCompleteAnyInSession(true);
      completeSelectedContent();
    }

  }, [completeSelectedContent])

  const returnWithCompletion = () => returnToMenu(true);

  useImperativeHandle(myRef, () => ({
    returnToMenu, selectedContent
  }), [returnToMenu, selectedContent])

  const items = getContents(contents.filter(c => c.type !== ContentType.Navigate && c.type !== ContentType.Multi && c.type !== ContentType.MultiGrid && c.type !== ContentType.RouteComplete && c.type !== ContentType.RouteCompleteVideo && c.type !== ContentType.ContentGuide));

  function getLocked(content) {

    if (content.require && Array.isArray(content.require)) {
      for (let i = 0; i < content.require.length; i += 1) {
        const index = contents.findIndex(c => c.id === content.require[i]);
        if ((!index && index !== 0) || !contentItemStates[index]) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  function getContents(contents) {
    const contentItems = [];
    for (let i = 0; i < contents.length; i += 1) {
      const locked = getLocked(contents[i]);
      contentItems.push(
        <Grid key={i} item xs={12} sm={6} sx={{ marginTop: '8px', height: GRID_ITEM_HEIGHT }} >
          <GridItem title={`Opdracht ${i + 1}`} completed={contentItemStates[i]} locked={locked} content={contents[i]} callback={() => { selectContent(i); }} />
        </Grid>
      );
    }
    return contentItems;
  }

  function selectContent(index) {
    savedScrollPos.current = document.getElementById('scroll-root').scrollTop;
    setSelectedContentIndex(index);
    setSelectedContent(contents[index]);
  }



  const multiTitle = `Opdracht ${(selectedContentIndex + 1)}`;
  return (
    <>
      {(selectedContent === undefined) ?

        <>
          <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
            <ContentStack spacing={2}>
              <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <Stack alignItems='center' spacing={2}>
                  <CombinedTitle title={title} subtitle={route.category} subtitleBackColor={route.color} />
                  <SubTitle variant="body1">
                    {text}
                  </SubTitle>
                </Stack>
              </Container>
              <Container sx={{ alignItems: 'center', display: 'flex', p: '0px !important', justifyContent: 'center', position: 'relative' }}>

                <Grid spacing={3} container sx={{ display: 'flex', justifyContent: 'center', padding: { xs: '5%', sm: '5%', lg: '5% 10%' } }} >
                  {items}
                </Grid>


              </Container>

              <ButtonsContainer>
                {
                  allowSkip ? <Button sx={{ mb: '0px' }} variant={isComplete ? "contained" : "outlined"} onClick={() => { completeMulti() }}>{isComplete ? "Verder" : "Overslaan"}</Button>
                    : <Button sx={{ mb: '0px' }} variant="contained" disabled={!isComplete} onClick={() => { onComplete() }}>{"Verder"}</Button>
                }
              </ButtonsContainer>
            </ContentStack>
          </Box>

          {/* complete dialog */}
          <AlertDialog open={completeDialogState} handleClose={() => SetCompleteDialogState(false)} title="Voltooid"
            content={completionText}
            primaryButtonText="Sluiten" secondaryButtonText=""
            primaryButtonFunc={() => { SetCompleteDialogState(false) }} secondaryButtonFunc={() => { }} />
          {/* incomplete dialog */}
          <AlertDialog open={incompleteDialogState} handleClose={() => SetIncompleteDialogState(false)} title="Overslaan"
            content={incompleteText}
            primaryButtonText="Terug" secondaryButtonText="Overslaan"
            primaryButtonFunc={() => { SetIncompleteDialogState(false) }} secondaryButtonFunc={() => { onComplete(); }} />

        </>

        :

        <>
          {process.env.REACT_APP_DEBUG === 'true' && (
            <Button sx={{ position: 'absolute', top: { lg: 86, xs: 142 }, left: 24, zIndex: 10 }} variant="contained" onClick={() => { returnWithCompletion() }}>
              [DEBUG] Return with completion
            </Button>
          )}

          {(() => {
            switch (selectedContent.type) {
              case ContentType.Image: return <ContentImage contentRef={childContentRef} multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion(); }} />;
              case ContentType.ImageQuiz: return <ContentImageQuiz contentRef={childContentRef} multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion(); }} />;
              case ContentType.Audio: return <ContentAudio multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              case ContentType.AudioQuiz: return <ContentAudioQuiz multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              case ContentType.Assignment: return <ContentAssignment {...selectedContent} route={route} multiTitle={multiTitle} fromMulti contentRef={childContentRef} onComplete={() => { returnWithCompletion(); }} />;
              case ContentType.Video: return <ContentVideo multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              case ContentType.VideoQuiz: return <ContentVideoQuiz multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              case ContentType.Text: return <ContentText multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              case ContentType.Panorama: return <ContentPanorama contentRef={childContentRef} multiTitle={multiTitle} fromMulti height={`calc(100vh - 64px)`} {...selectedContent} onComplete={() => { returnWithCompletion(); }} />;
              case ContentType.Puzzle: return <ContentPuzzle multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              case ContentType.WordPuzzle: return <ContentWordPuzzle multiTitle={multiTitle} {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              case ContentType.TapQuiz: return <ContentTapQuiz multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              case ContentType.DragPuzzle: return <ContentDragPuzzle multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              case ContentType.Tap2: return <ContentTapFullscreen multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              //    case ContentType.BeforeAfter: return <ContentBeforeAfter contentRef={childContentRef} multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              case ContentType.Quiz: return <ContentQuiz multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              //    case ContentType.Share: return <ContentShare contentRef={childContentRef} multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              case ContentType.PictureFinder: return <ContentPictureFinder contentRef={childContentRef} multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              //    case ContentType.Navigate: return <ContentNavigate multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              case ContentType.Model: return <ContentModel contentRef={childContentRef} multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              case ContentType.ModelQuiz: return <ContentModelQuiz contentRef={childContentRef} multiTitle={multiTitle} fromMulti {...selectedContent} onComplete={() => { returnWithCompletion() }} />;
              default: return <Content />
            }
          })()}
        </>
      }
    </>
  );
}