import { Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';


export const ButtonsContainerHeightBlocker = styled(Box)({
  height: '96px',
});


export const ButtonsContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  // maxWidth: '480px !important',
  alignSelf: 'center',
  alignItems:'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
  gap: '24px',
  paddingBottom: '24px',
  paddingTop: '24px',
  position: 'fixed',
  zIndex: '5',
  bottom: 0,
  [theme.breakpoints.down('sm')]: {
    gap: '16px',
    padding: '16px'
  },
}));

export default ButtonsContainer;