import PropTypes from 'prop-types';

import { ButtonBase } from '@mui/material';

const TapImageButton = ({ value, x, y, sizeX, sizeY, image, onClick }) => (

    <ButtonBase style={{
        position: 'absolute',
        left: `${x}%`,
        top: `${y}%`,
        width: `${sizeX}%`,
        height: `${sizeY}%`,
        borderRadius: '100%'
    }}
        onClick={() => onClick()}>
        {value ?
            <img
                src={image}
                alt="background"
                className={"unclickable unselectable"}
                loading="lazy"
                style={{ height: 'auto' }}
            />
            :
            null}
    </ButtonBase>
);

TapImageButton.propTypes = {
    value: PropTypes.bool.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    sizeX: PropTypes.number.isRequired,
    sizeY: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default TapImageButton;
