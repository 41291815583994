export function SupportsWebGL(log = false) {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext("experimental-webgl") || canvas.getContext('webgl2');
    let useWebgl = false;
    if (!gl) {
        if (typeof WebGL2RenderingContext !== 'undefined' || typeof WebGLRenderingContext !== 'undefined') {
            if (log) {
                console.log('your browser appears to support WebGL but it might be disabled.');
            }
        } else if (log) {
            console.log('your browser has no WebGL support at all');
        }
    } else {
        useWebgl = true;
    }
    return useWebgl;
}