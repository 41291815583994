
// @mui
import { Box, Container, Button } from '@mui/material';

import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack } from '../../../components/content';
import { formatUrl } from '../../../utils/formatUrl';

// ----------------------------------------------------------------------



export default function ContentRouteCompletePreview(content) {

  const { title, text, route, buttonText, onComplete, completeImage } = content;

  return (
    <>
      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
        <ContentStack spacing={0} sx={{position:'relative', paddingBottom: {xs:'72px', sm:'96px'}, overflow: 'hidden'}}>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', zIndex: 2 }}>
            <CombinedTitle title={title} subtitle={route.category} subtitleBackColor={route.color}/>
              <SubTitle variant="body1">
                {text}
              </SubTitle>
          </Container>
          <Container sx={{alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative', flexGrow: 2, height: '1vh' }}>
            <img style={{ filter: 'drop-shadow(-1px 2px 2px #00000066)', rotate: '-5deg', height: '87%', display: 'flex', justifyContent: 'center', zIndex: 1, marginRight: '56%', transition:'opacity 0.15s ease-in-out'}} src={formatUrl(route.cover)} alt="route" />
            <img style={{ position: 'absolute', zIndex: '1', marginLeft: '33%', width: '100%', height: 'auto', maxWidth: '960px', marginBottom: 'calc(33vh - 120px)' }} src={formatUrl(completeImage)} alt="completed" />
          </Container>
        <ButtonsContainer>
            <Button sx={{ mb: '0px' }} variant="contained" onClick={() => { onComplete() }}>{buttonText}</Button>
          </ButtonsContainer>
        </ContentStack>
      </Box>
    </>
  );
}
