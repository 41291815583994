import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';

import logosvg from './logo.svg';
// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        height: '42px',
        width: 'auto',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >


      <img src={logosvg} alt="Logo" style={{zIndex: 0}} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
      {logo}
    </Link>
  );
});

Logo.displayName = "Logo";

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
