// @mui
import { Box, Stack, Container, Button } from '@mui/material';
// import { OrientationOverlay } from '../../../components/orientation-overlay';

import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack, ButtonsContainerHeightBlocker } from '../../../components/content';

// ----------------------------------------------------------------------

export default function ContentText(content) {
  const { title, text, onComplete, fromMulti, multiTitle } = content;
  return (
    <>
      { /* <OrientationOverlay /> */ }

      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pt: '8px' }}>
        <ContentStack spacing={2}>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Stack alignItems='center' spacing={2}>
              <CombinedTitle title={title} subtitle={multiTitle}/>
              <SubTitle variant="body1">
                {text}
              </SubTitle>
            </Stack>
          </Container>

        <ButtonsContainerHeightBlocker />
        <ButtonsContainer>
            <Button sx={{ mb: '0px' }} variant="contained" onClick={() => { onComplete() }}>{fromMulti ? "Terug naar overzicht" : "Verder"}</Button>
          </ButtonsContainer>
        </ContentStack>
      </Box>
    </>
  );
}
