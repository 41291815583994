

import { useRef } from "react";
import PropTypes from 'prop-types';
import { Box } from "@mui/system";
import "./styles.css";
import arPrompt from "./ar_hand_prompt.png";
import { SupportsWebGL } from "../../utils/supportsWebgl";


// only import library using webgl, if webgl isn't disabled
const useWebgl = SupportsWebGL();
if(useWebgl) {
    import("@google/model-viewer");
}



const Modelviewer = ({ modelSrc, posterSrc, iosModelSrc = undefined, allowPan, orbitScale = 105, minOrbitOffset = 0, children }) => {
    const modelRef = useRef();

    if(useWebgl) {
    return (
        <model-viewer
            id="viewer"
            scale="1 1 1"
            bounds="tight"
            interaction-prompt-threshold="500"
            camera-orbit={`auto auto ${orbitScale}%`} // hack for large size. Set to larger than 105 to zoom out
            max-camera-orbit={`auto auto ${orbitScale}%`}
            min-camera-orbit={`auto auto ${orbitScale - minOrbitOffset}%`}
            src={modelSrc}
            ios-src={iosModelSrc}
            ar
            ar-modes="webxr scene-viewer quick-look"
            touch-action="none"
            camera-controls
            environment-image="neutral"
            disable-pan={allowPan}
            poster={posterSrc}
            shadow-intensity="1"
            exposure="1"
            ref={(ref) => {
                modelRef.current = ref;
            }}
        >
            <div className="progress-bar hide" slot="progress-bar">
                <div className="update-bar" />
            </div>

            {children}
            <div id="ar-prompt">
                <img src={arPrompt} alt="ar prompt" />
            </div>
        </model-viewer>
    );
        } else {
            return (
            <Box sx={{width:'100%', height:'100%', display: 'flex', justifyContent:'center', alignItems:'center'}}> 
                <h4>
                    Deze 3D-ervaring werkt niet in deze browser, of wordt geblokkeerd door je organisatie.
                </h4>
            </Box>
            );
        }
};

Modelviewer.propTypes = {
    modelSrc: PropTypes.string.isRequired,
    posterSrc: PropTypes.string,
    iosModelSrc: PropTypes.string,
    allowPan: PropTypes.bool,
    orbitScale: PropTypes.number,
    minOrbitOffset: PropTypes.number,
    children: PropTypes.element,
};

export default Modelviewer;
