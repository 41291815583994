import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import Tile from "./Tile";
import { TILE_COUNT, GRID_SIZE } from "./constants"
import { canSwap, shuffle, swap, isSolved } from "./helpers"

function Board({ imgUrl, onComplete, swapMode }) {
  const [tiles, setTiles] = useState([...Array(TILE_COUNT).keys()]);
  const [isStarted, setIsStarted] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [selectedTile, setSelectedTile] = useState(-1);


  const [boardSize, setBoardSize] = useState(0);

  const shuffleTiles = () => {
    const shuffledTiles = shuffle(tiles)
    setTiles(shuffledTiles);
  }

  const swapTiles = (tileIndex) => {
    let otherTile = -1;
    if (!swapMode) {
      otherTile = tiles.indexOf(tiles.length - 1);
    } else {
      otherTile = selectedTile;
    }

    if (canSwap(tileIndex, otherTile, swapMode)) {
      const swappedTiles = swap(tiles, tileIndex, otherTile)
      setTiles(swappedTiles)
    }
  }

  const handleTileClick = (index) => {
    if (!swapMode) {
      swapTiles(index);
      return;
    }
    // either select or swap a tile
    if (selectedTile === -1) {
      setSelectedTile(index);
      return;
    }
    swapTiles(index)
    setSelectedTile(-1);
  }

  /*
    const handleShuffleClick = () => {
      shuffleTiles()
      setCompleted(false);
    }
    */

  const handleStartClick = () => {
    shuffleTiles()
    setIsStarted(true)
  }

  const pieceWidth = Math.round(boardSize / GRID_SIZE);
  const pieceHeight = Math.round(boardSize / GRID_SIZE);
  const style = {
    width: boardSize,
    height: boardSize,
  };
  const hasWon = isSolved(tiles)

  useEffect(() => {
    let timeout;
    if (hasWon && !completed) {
      timeout = setTimeout(() => {
        setCompleted(true);
        onComplete();
      }, 750);
    }

    return () => timeout && clearTimeout(timeout);
  }, [hasWon, completed, onComplete]);

  const handleResize = useCallback(() => {
    setBoardSize(getSmallestDimension());
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, [handleResize]);

  useEffect(() => () => {
    console.log("clean puzzle");
    window.removeEventListener("resize", handleResize);
  }, [handleResize]);


  function getSmallestDimension() {
    const elem = document.getElementById("Puzzle");
    if (elem) {
      if (elem.clientHeight > elem.clientWidth) {
        return elem.clientWidth;
      }
      return elem.clientHeight;
    }
    if (window.innerHeight > window.innerWidth) {
      return window.innerWidth;
    }
    return window.innerHeight;

  }

  if (!isStarted) {
    handleStartClick();
  }

  return (
    <ul style={style} className="board">
      {tiles.map((tile, index) => (
        <Tile
          key={tile}
          index={index}
          imgUrl={imgUrl}
          tile={tile}
          boardSize={boardSize}
          width={pieceWidth}
          height={pieceHeight}
          selected={selectedTile === index}
          completed={completed || swapMode}
          handleTileClick={handleTileClick}
        />
      ))}
    </ul>
  );
}

Board.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
  swapMode: PropTypes.bool.isRequired
};

export default Board;
