
// @mui
import { Box, Stack, Container, Button } from '@mui/material';

import { useRef, useState } from "react";

// eslint-disable-next-line import/no-unresolved
import "yet-another-react-lightbox/styles.css";
// eslint-disable-next-line import/no-unresolved
import "yet-another-react-lightbox/plugins/captions.css";


import ReactCompareImage from 'react-compare-image';


import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack } from '../../../components/content';

import '../../../components/image/styles.css';

import { QuestionComponent } from '../../../components/quiz';
import HintOverlay from '../../../components/hint-overlay/hintOverlay';
import { formatUrl } from '../../../utils/formatUrl';
import { HintDialog } from '../../../components/hint-overlay';


// ----------------------------------------------------------------------

export default function ContentImageQuiz(content) {

  const { beforeImage, afterImage, title, text, onComplete, fromMulti, multiTitle, question, answers, onBack, hintImage, hintButtonText, hintIcon, hintContent = null, hintDelay = 25000 } = content;


  const questionRef = useRef();
  const [completed, setCompleted] = useState(false);

  
  return (
    <>
      {/* <OrientationOverlay/> */}
      {
        hintContent && hintDelay > 0 ?
        <HintDialog hintContent={hintContent} delay={hintDelay} /> : null
      }

      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
        <ContentStack spacing={2} sx={{ paddingBottom: {xs:'88px', sm:'112px'} }}> 
          <Container sx={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'center', flexGrow: '0' }}>
            <Stack alignItems='center' spacing={2}>
              <CombinedTitle title={title} subtitle={multiTitle}/>
              <SubTitle variant="body1">
                {text}
              </SubTitle>
            </Stack>
          </Container>
          
            
            <Container sx={{height: '100%', alignItems: 'center', display: 'flex', p: '0px !important', justifyContent: 'center', position: 'relative', flexGrow: '2', '& [data-testid="container"]':{height: '100% !important'} }}>
                {
                    <ReactCompareImage style={{ position: 'absolute', top: 0 }} leftImage={formatUrl(beforeImage)} rightImage={formatUrl(afterImage)} aspectRatio={'taller'} leftImageCss={{objectFit: 'contain'}} rightImageCss={{objectFit: 'contain'}} />
                }
                  
            </Container>
          

          <QuestionComponent question={question} answers={answers} myRef={questionRef} disabled={completed} ignoreCase hint={hintContent} />  
               
            { hintImage ? 
              <Container sx={{width:'100%', height:'auto', position:'relative', display:'flex', flexDirection:'column', flexGrow:'0', padding: '0px 16px !important'}}>
                <HintOverlay buttonText={hintButtonText} image={hintImage} iconifyIcon={hintIcon} /> 
              </Container> : null 
            }
        

        <ButtonsContainer>
            { completed ? 
                <Button sx={{ mb: '0px' }} variant="contained" onClick={() => { nextButton() }}>
                    {fromMulti ? "Terug naar overzicht" : "Verder"}
                </Button>
            :
                <Button sx={{ mb: '0px' }} variant="contained" onClick={() => { checkAnswer() }}>
                    {"Controleer antwoord"}
                </Button>
            }
            {
                onBack ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button>: null
            }
           </ButtonsContainer>
        </ContentStack>
      </Box>

     
    </>
  );

  function nextButton() {
    questionRef.current?.clearTextField();
    setCompleted(false);
    onComplete();
  }

  function checkAnswer() {
    if(questionRef.current?.checkAnswer()) {
      setCompleted(true);
    }

  }
}
