// @mui
import { Box, Container, Button, IconButton, Stack } from '@mui/material';

// components
import ReactPlayer from 'react-player/lazy';
import { isIOS, isMobileSafari, isSafari } from 'react-device-detect';
import { useState, useRef } from 'react';
import { OrientationOverlay } from '../../../components/orientation-overlay';

import { ButtonsContainer, CombinedTitle, ContentStack } from '../../../components/content';
import Iconify from '../../../components/iconify';
import { formatUrl } from '../../../utils/formatUrl';

// ----------------------------------------------------------------------

export const GuideState = {
    Before: 'before',
    Content: 'content',
    After: 'after',
};

// TODO set this to false in releases
const disableContinueDelay = false;

export default function ContentGuide2(content) {

    const { videoUrl, videoAppleUrl, title, fromMulti, multiTitle, onComplete, guideState, onBack, transcription, continueDelay = 10000, forceAllowBack = false } = content;

    const loopVideo = false;

    let controlTimeout = null;
    let nextTimeout = null;

    const videoRef = useRef(null);


    const autoplay = !(isIOS || isMobileSafari);

    const [controlsVisible, setControlsVisible] = useState(!autoplay);
    const [ended, setEnded] = useState(false);
    const [playing, setPlaying] = useState(autoplay);
    const [videoReady, setVideoReady] = useState(false);

    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
    const [transcriptionVisible, setTranscriptionVisible] = useState(false);

    // refs of setState because useState gives old values inside setTimeout
    const playingRef = useRef(playing);

    function onCompleteButtonClick() {
        clearTimeout(controlTimeout);
        clearTimeout(nextTimeout);
        onComplete();
    }

    function toggleMedia() {
        if (!controlsVisible) {
            return;
        }

        clearTimeout(controlTimeout);


        if (ended) {
            videoRef.current?.seekTo(0);
            setEnded(false);
            setPlaying(true);
            playingRef.current = true;
            setControlsVisible(false);
        } else {
            const newPlayState = !playing;
            if (!newPlayState) {
                clearTimeout(controlTimeout);
                setControlsVisible(true);
            } else {
                hideMediaControls();
            }


            setPlaying(newPlayState);
            playingRef.current = newPlayState;
        }
    }

    function setNextButtonTimeout() {
        if (!nextTimeout && nextButtonDisabled) {
            nextTimeout = setTimeout(() => {
                if (nextButtonDisabled) {
                    setNextButtonDisabled(false);
                }
            }, disableContinueDelay ? 1 : continueDelay);
        }
    }

    function showMediaControls() {
        if (!controlsVisible) {
            clearTimeout(controlTimeout);
            setControlsVisible(true);
            if (playing && !ended) {
                hideMediaControls();
            }
        }
    }

    function hideMediaControls() {
        clearTimeout(controlTimeout);
        controlTimeout = setTimeout(() => {
            if (playingRef.current) {
                setControlsVisible(false);
            }
        }, 2500);
    }

    function onMediaComplete() {
        if (nextButtonDisabled) {
            setNextButtonDisabled(false);
        }
        if (!loopVideo) {
            setPlaying(false);
            setEnded(true);
            setControlsVisible(true);
        }
    }

    return (
        <>
            <OrientationOverlay />
            <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
                <ContentStack spacing={0} sx={{ position: 'relative', paddingBottom: { xs: '72px', sm: '96px' }, overflow: 'hidden' }}>
                    <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', zIndex: 2 }}>
                        <CombinedTitle title={title} subtitle={multiTitle} />
                    </Container>
                    <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative', flexGrow: 2, height: '1vh' }} onClick={() => showMediaControls()}>
                        <ReactPlayer
                            ref={videoRef}
                            height='100%'
                            width='auto'
                            volume={1}
                            loop={loopVideo}
                            style={{ display: 'flex', justifyContent: 'center', zIndex: 2, marginRight: (guideState === GuideState.After) ? '56%' : '', transition: 'opacity 0.15s ease-in-out', opacity: videoReady ? '1' : '0' }}
                            onReady={() => {
                                setVideoReady(true);
                            }}
                            config={{ file: { attributes: { playsInline: true, }, } }}
                            // video with transparent background
                            // HEVC for safari and iOS, webm for others
                            // will not be transparent on iOS <= 12 (iphone 6, 5s, ipad mini 3)
                            url={formatUrl(((isIOS || isSafari) && videoAppleUrl) ? videoAppleUrl : videoUrl)}
                            playing={playing}
                            controls={false}
                            onPlay={() => {
                                setNextButtonTimeout();
                            }}
                            onEnded={() => onMediaComplete()}
                            onClick={() => showMediaControls()}
                            onError={() => setNextButtonDisabled(false)}
                        />
                        {guideState === GuideState.After ?
                            <img style={{ position: 'absolute', zIndex: '1', marginLeft: '33%', width: '100%', height: 'auto', maxWidth: '960px', marginBottom: 'calc(33vh - 120px)' }} src={formatUrl("assets/images/completed.png")} alt="completed" />
                            : null}


                        <IconButton
                            onClick={() => toggleMedia()}
                            sx={{
                                width: '56px',
                                height: '56px',
                                opacity: controlsVisible ? 1 : 0,
                                transition: 'all 150ms linear',
                                backgroundColor: '#AC303E',
                                position: 'absolute',
                                zIndex: '3',
                                top: '50%',
                                left: '50%',
                                transform: 'translateY(-50%) translateX(-50%)',
                                "&:hover": {
                                    backgroundColor: '#8B1628',
                                },
                                color: '#FBEEED',
                            }}
                        >
                            <Iconify icon={playing ? "material-symbols:pause" : (ended ? "material-symbols:replay" : "material-symbols:play-arrow")} />
                        </IconButton>

                    </Container>

                    <Stack sx={{ width: '100%', padding: '0px 16px 0px 16px', gap: '16px', height: 'auto', flexDirection: 'row', alignItems: 'flex-end', position: 'absolute', bottom: { xs: 'calc(72px + 16px)', sm: 'calc(96px + 16px)' } }}>
                        {transcription ?
                            <Container sx={{ transition: 'all 100ms', opacity: transcriptionVisible ? 1 : 0, pointerEvents: transcriptionVisible ? 'auto' : 'none', maxHeight: { xs: '120px', sm: '150px' }, position: 'relative', zIndex: '3', maxWidth: '740px', padding: { xs: '12px', sm: '20px 24px' }, width: 'calc(100% - 56px)', backgroundColor: 'background.paper', overflowY: 'auto', fontSize: 'clamp(0.75rem, 0.75rem + 0.5vw, 1rem)', boxShadow: '0 0 8px 0 rgb(0 0 0 / 8%), 0 12px 24px -4px rgb(0 0 0 / 4%)', whiteSpace: 'pre-line' }}> {transcription} </Container>
                            : null
                        }
                        {transcription ?
                            <IconButton
                                onClick={() => { setTranscriptionVisible(!transcriptionVisible); setNextButtonTimeout(); }}
                                sx={{
                                    position: 'relative',
                                    width: '40px',
                                    padding: '8px',
                                    transition: 'all 150ms linear',
                                    zIndex: '3',
                                    color: 'primary',
                                    marginBottom: '0',
                                    backgroundColor: '#755A2F',
                                    '&:hover': { backgroundColor: '#5B421A' },
                                    borderRadius: '50%'
                                }}
                            >
                                <Iconify sx={{ width: '24px', height: '24px', color: '#ffffff' }} icon={transcriptionVisible ? 'material-symbols:subtitles-outline' : 'material-symbols:subtitles-off-outline'} />
                            </IconButton>
                            : null}
                    </Stack>


                    <ButtonsContainer>
                        <Button sx={{ mb: '0px' }} disabled={nextButtonDisabled} variant="contained" onClick={() => { onCompleteButtonClick() }}>{guideState === GuideState.After ? "Volgende opdracht" : guideState === GuideState.Before ? "Begin opdracht" : "Verder"}</Button>
                        {
                            onBack && (guideState === GuideState.content || forceAllowBack) ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button> : null
                        }
                    </ButtonsContainer>
                </ContentStack>
            </Box>
        </>


    );

}