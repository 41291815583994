import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Overline = styled(Typography)(({ theme }) => ({
  fontSize: 'clamp(0.625rem, 0.625rem + 1vw, 0.9375rem)',
  textAlign: 'center',
  textTransform: 'uppercase',
  letterSpacing: '0.15em',
  color: theme.palette.primary.main,
  fontWeight: '500',
  lineHeight: '1.5',
}));

export default Overline;