import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// utils
// import { bgBlur } from '../../../utils/cssStyles';
// mock
import settings from '../../../_mock/settings';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
//


// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  
  const isDesktop = useResponsive('up', 'lg');
  
  const StyledRoot = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backgroundColor: isDesktop ? 'transparent' : theme.palette.background.appbar,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  }));
  
  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
      minHeight: HEADER_DESKTOP,
      padding: theme.spacing(0, 5),
    },
  }));
 
  return (
    <StyledRoot>
      <StyledToolbar sx={{padding: '0px 4px !important'}}>

        <IconButton
          onClick={onOpenNav}
          sx={{
            color: 'text.primary',
            display: { lg: 'none' },
            position: 'absolute',
            padding: '16px'
          }}
        >
          <Iconify icon="eva:menu-fill" />
        </IconButton>

        { isDesktop ? null

        :
          <Logo />             
        }

      </StyledToolbar>
    </StyledRoot>
  );
}
