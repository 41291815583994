import PropTypes from 'prop-types';
import Board from "./Board";
import './puzzle.css';

function Puzzle({ imageUrl, onComplete, swapMode = false }) {
  return (
    <div className="Puzzle" id="Puzzle">
      <Board imgUrl={imageUrl} onComplete={onComplete} swapMode={swapMode} />
    </div>
  );
}

Puzzle.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
  swapMode: PropTypes.bool.isRequired
};

export default Puzzle;
