import React from "react";
import PropTypes from 'prop-types';
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import { formatUrl } from '../../utils/formatUrl';


let ref;
let index = 0;
export default function ResponsiveCarousel({ images, onIndexChange, innerRef }) {
  ref = innerRef;
  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <ResponsiveContainer
        carouselRef={innerRef}
        render={(parentWidth, carouselRef) => {
          // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
          // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
          // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
          // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
          const currentVisibleSlide = Math.min(3, images.length);
          return (
            <StackedCarousel
              disableSwipe={images.length === 1}
              ref={carouselRef}
              slideComponent={Card}
              slideWidth={parentWidth * 0.5}
              carouselWidth={parentWidth}
              data={images}
              currentVisibleSlide={currentVisibleSlide % 2 ? currentVisibleSlide : currentVisibleSlide - 1}
              maxVisibleSlide={currentVisibleSlide % 2 ? currentVisibleSlide : currentVisibleSlide - 1} // fix because it has to be an odd number
              useGrabCursor={images.length > 1}
              fadeDistance={0}
              onActiveSlideChange={(i) => {index = i; onIndexChange(i)}}
            />
          );
        }}
      />


    </div>
  );
}

ResponsiveCarousel.propTypes = {
  images: PropTypes.array,
  onIndexChange: PropTypes.func,
  innerRef: PropTypes.any,
};

// Very import to memoize your Slide component otherwise there might be performance issue
// At minimum your should do a simple React.memo(SlideComponent)
// If you want the absolute best performance then pass in a custom comparator function like below 
// eslint-disable-next-line react/display-name
export const Card = React.memo((props) => {
  const { data, dataIndex } = props;
  const { url } = data[dataIndex];
  const { rot } = data[dataIndex];

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={() => {
      // go to next/previous on card click
      if(data.length > 1) {
        if (dataIndex === index +1 || (dataIndex === 0 && index === data.length - 1)) {
          ref?.current.goNext();
        }
        else if(dataIndex !== index) {
          ref?.current.goBack();
        }
      }
    }}
      style={{
        width: "100%",
        height: "40vh",
        userSelect: "none",
        transform: `rotate(${rot}deg)`,
      }}
      className="my-slide-component"
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          objectFit: "contain",
          borderRadius: 0,

        }}
        alt=""
        draggable={false}
        src={formatUrl(url)}
      />
    </div>
  );
});

Card.propTypes = {
  data: PropTypes.array,
  dataIndex: PropTypes.number,
}