import { useState } from "react";

// @mui
import { Box, Stack, Container, Button, IconButton } from '@mui/material';


import Iconify from '../../../components/iconify';
import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack, Title } from '../../../components/content';
import { formatUrl } from '../../../utils/formatUrl';
import { DragPuzzle } from "../../../components/DragPuzzle";
import { HintDialog } from '../../../components/hint-overlay';
import { AlertDialog } from '../../../components/dialog';

// ----------------------------------------------------------------------

export default function ContentTapPuzzle(content) {
    const { title, text, textFullscreen, background, backgroundCompleted, targets, onComplete, fromMulti, multiTitle, onBack, completionText, hintContent = null, hintDelay = 25000 } = content;


    const [fullscreen, setFullscreen] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [completeDialogState, SetCompleteDialogState] = useState(false);


    return (
        <>
            {fullscreen ?
                <Box sx={{
                    display: 'flex',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    p: '0px !important',
                    zIndex: '9',
                    alignItems: 'center',
                    inset: '0 0 0 0',
                    background: '#000000de',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    transform: 'translate3d(0,0,0)'
                }}>

                    <style>
                        {
                            `header {
                        display: none !important;
                    }
                    nav {
                      display: none !important;
                    }`
                        }
                    </style>

                    <Stack sx={{ width: '100%', height: '100%', position: 'fixed', top: 0, padding: 'clamp(1rem, 3vh, 64px)' }} textAlign="left">
                        <Container sx={{ alignItems: 'center', display: 'flex', p: '0px !important', justifyContent: 'center', position: 'relative', flexGrow: 2 }}>
                            <DragPuzzle background={background} destinations={targets} onComplete={() => { setCompleted(true); SetCompleteDialogState(true); }} />
                        </Container>
                        {
                            /* back button */
                            <Container sx={{ display: 'flex', justifyContent: 'space-between', margin: '0', padding: '0 !important', position: 'absolute', top: '16px', left: '16px', width: 'auto' }}>
                                <IconButton sx={{ width: '40px', p: '8px', textTransform: 'none', color: '#FBEEED', backgroundColor: 'rgb(54 47 47 / 60%)', "&:hover": { backgroundColor: "rgb(54 47 47 / 68%)" }, }} onClick={() => { setFullscreen(false); }} aria-label="back" component="label">
                                    <Iconify icon="ic:baseline-arrow-back" width='100%' height='100%' />
                                </IconButton>
                            </Container>
                        }
                        <Title variant="h4" sx={{ padding: '4px 8px', margin: 'auto', width: 'fit-content' }}>
                            {textFullscreen ?? text}
                        </Title>
                    </Stack>
                </Box>
                : null
            }


            <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
                {
                    hintContent && hintDelay > 0 ?
                        <HintDialog hintContent={hintContent} delay={hintDelay} /> : null
                }
                <ContentStack spacing={2}>
                    <Container sx={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'center', flexGrow: '0' }}>
                        <Stack alignItems='center' spacing={0}>
                            <CombinedTitle title={title} subtitle={multiTitle} />
                            <SubTitle variant="body1">
                                {text}
                            </SubTitle>
                        </Stack>
                    </Container>
                    <Container sx={{ alignItems: 'center', display: 'flex', p: '0px !important', justifyContent: 'center', position: 'relative', flexGrow: 2 }}>

                        <Box sx={{ height: '100%', position: 'relative', maxHeight: '40vh', maxWidth: 'min(720px, 87vw)', display: 'flex', justifyContent: 'center' }}> {
                            // eslint-disable-next-line react/jsx-no-comment-textnodes
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                            <img
                                height="100%"
                                src={formatUrl(completed ? (backgroundCompleted ?? background) : background)}
                                className={"unselectable"}
                                alt="background"
                                loading="lazy"
                                onClick={() => { if (!completed) { setFullscreen(true) } }}
                            />
                        }
                            {!completed ?
                                <IconButton sx={{ backgroundColor: 'primary.main', color: 'background.paper', "&:hover": { backgroundColor: "#8b2733", color: 'background.paper' }, width: '56px', height: '56px', position: 'absolute', left: '50%', top: '50%', zIndex: '3', transform: 'translateX(-50%) translateY(-50%)' }} variant="filled" onClick={() => { setFullscreen(true) }}>
                                    <Iconify icon="eva:search-fill" />
                                </IconButton> : null
                            }
                        </Box>
                    </Container>
                    <ButtonsContainer>
                        {completed ?
                            <Button sx={{ mb: '0px' }} variant="contained" onClick={() => { nextButton() }}> {fromMulti ? "Terug naar overzicht" : "Verder"}</Button>
                            :
                            <>
                                <Button sx={{ mb: '0px' }} disabled variant="contained"> {"Verder"}</Button>
                            </>
                        }
                        {
                            onBack ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button> : null
                        }
                    </ButtonsContainer>

                </ContentStack>
            </Box>

            {/* correct dialog */}
            <AlertDialog open={completeDialogState} handleClose={() => { SetCompleteDialogState(false); setFullscreen(false); }} title="Voltooid"
                content={completionText}
                primaryButtonText="Sluiten" secondaryButtonText=""
                primaryButtonFunc={() => { SetCompleteDialogState(false); setFullscreen(false); }} secondaryButtonFunc={() => { }} />
        </>
    );

    function nextButton() {
        setCompleted(false);
        onComplete();
    }

}
