// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'home',
    path: '/home',
    icon: <Iconify icon="ic:outline-place" width={22} height={22}/>,
    children: []
  },
  {
    title: 'instellingen',
    path: '/settings',
    icon: <Iconify icon="ic:baseline-settings" width={22} height={22}/>,
    children: [{
      title: 'toestemmingen',
      path: '/settings/permissions',
      icon: <Iconify icon="ic:baseline-settings" width={22} height={22}/>,
    }],
  }
];

export default navConfig;
