
// @mui
import { Box, Stack, Container, Button } from '@mui/material';
import { useState } from "react";

import { AlertDialog } from '../../../components/dialog';

import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack, } from '../../../components/content';
import { WordPuzzle } from '../../../components/WordPuzzle';
import HintOverlayDialog from '../../../components/hint-overlay/hintOverlayDialog';

// ----------------------------------------------------------------------

export default function ContentWordPuzzle(content) {

  const { title, text, words = "Dit is een puzzel ja", onComplete, fromMulti, multiTitle, allowSkip = false, hintImage, hintButtonText, hintIcon, onBack, prefilled = [], showIncorrect = true } = content;

  const [isComplete, setCompleteState] = useState(false);
  const [completeDialogState, SetCompleteDialogState] = useState(false); // complete Dialog

  const completionText = 'Je hebt de puzzel opgelost. Lekker bezig!';

  function onPuzzleComplete() {
    setCompleteState(true);
    SetCompleteDialogState(true);
  }

  return (
    <>
      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
        <ContentStack spacing={2}>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexGrow:'0' }}>
            <Stack alignItems='center' spacing={2}>
              <CombinedTitle title={title} subtitle={multiTitle}/>
              <SubTitle variant="body1">
                {text}
              </SubTitle>
            </Stack>
          </Container>

         

            <WordPuzzle words={words} onComplete={() => onPuzzleComplete()} prefilled={prefilled} showIncorrect={showIncorrect}>
              { hintImage ? 
                  <Box sx={{marginBottom:'16px', width: '100%'}}><HintOverlayDialog buttonText={hintButtonText} image={hintImage} iconifyIcon={hintIcon} /> </Box> : null 
              }
            </WordPuzzle>


          <ButtonsContainer>
            {
            allowSkip ? <Button sx={{ mb: '0px' }} variant={isComplete ? "contained" : "outlined"} onClick={() => { onComplete() }}>{fromMulti ? "Terug naar overzicht" : (isComplete ? "Verder" : "Overslaan")}</Button>
            : <Button sx={{ mb: '0px' }} variant="contained" disabled={!isComplete} onClick={() => { onComplete() }}>{fromMulti ? "Terug naar overzicht" :"Verder"}</Button>
            }
             {
                onBack ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button>: null
            }
          </ButtonsContainer>
        </ContentStack>
      </Box>

      {/* correct dialog */}
      <AlertDialog open={completeDialogState} handleClose={() => SetCompleteDialogState(false)} title="Voltooid"
        content={completionText}
        primaryButtonText="Sluiten" secondaryButtonText=""
        primaryButtonFunc={() => {SetCompleteDialogState(false) }} secondaryButtonFunc={() => { }} />
    </>
  );
}
