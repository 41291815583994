// @mui
import { Box, Stack, Container, Button } from '@mui/material';
import { useRef, useState } from 'react';
// components
import ReactPlayer from 'react-player/lazy';

import { AlertDialog } from '../../../components/dialog';

import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack } from '../../../components/content';
import { QuestionComponent } from '../../../components/quiz';
import { formatUrl } from '../../../utils/formatUrl';
import  '../../../components/video.css'
import { HintDialog } from '../../../components/hint-overlay';

// ----------------------------------------------------------------------

export default function ContentVideoQuiz(content) {

  const { url, title, text, onComplete, fromMulti, multiTitle, videoThumbnail, question, answers, onBack, hintContent = null, hintDelay = 25000, blockSkip = true } = content;
  
  const questionRef = useRef();
  const [completed, setCompleted] = useState(false);
  const [checkButtonDisabled, setCheckButtonDisabled] = useState(blockSkip);
  const [buttonDisabledDialogOpen, setButtonDisabledDialogOpen] = useState(false);


  // blockSKip: block checkanswer button until video reached end or waited for 0.75* vid duration
  const [duration, setDuration] = useState(0);
  let checkTimeout = null;

  function setCheckButtonTimeout() {
    if (blockSkip && !checkTimeout && checkButtonDisabled && duration > 0) {
      checkTimeout = setTimeout(() => {
        setCheckButtonDisabled(false);
      }, duration * 0.75 );
    }
  }


  return (
    <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
            {
        hintContent && hintDelay > 0 ?
        <HintDialog hintContent={hintContent} delay={hintDelay} /> : null
      }
      <ContentStack spacing={2}>
        <Container sx={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'center', flexGrow: '0' }}>
          <Stack alignItems='center' spacing={0}>
            <CombinedTitle title={title} subtitle={multiTitle}/>
            <SubTitle variant="body1">
              {text}
            </SubTitle>
          </Stack>
        </Container>
        <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative', flexGrow: '2', maxWidth: '720px !important' }}>
          {url?.includes('vimeo') ?
              // vimeo embed
              <ReactPlayer
                // use ONLY video.css for height, vimeo urls need those settings
                url={formatUrl(url)}
                config={{attributes: { poster:formatUrl(videoThumbnail) } }}
                playing={false}
                onPlay={() => { setCheckButtonTimeout();}}
                onDuration={(_duration) => {if(_duration) setDuration(_duration * 1000)}}
                onEnded={() => { setCheckButtonDisabled(false);}}
                onError={() => { setCheckButtonDisabled(false);}}
                controls
              />
            :
              // regular mp4 url
              <ReactPlayer
                  // use video.css for height, vimeo urls need those settings
                  height='auto'
                  width='100%'
                  url={formatUrl(url)}
                  config={{attributes: { poster:formatUrl(videoThumbnail) } }}
                  playing={false}
                  onPlay={() => { setCheckButtonTimeout();}}
                  onDuration={(_duration) => { if(_duration) setDuration(_duration * 1000)}}
                  onEnded={() => {setCheckButtonDisabled(false);}}
                  onError={() => { setCheckButtonDisabled(false);}}
                  playsinline
                  controls
              />
            }
        </Container>
       <QuestionComponent question={question} answers={answers} myRef={questionRef} disabled={completed} ignoreCase hint={hintContent} />


        <ButtonsContainer>
            { completed ? 
                <Button sx={{ mb: '0px' }} variant="contained" onClick={() => { nextButton() }}> {fromMulti ? "Terug naar overzicht" : "Verder"}</Button>
                :
                <>
                  <Button sx={ checkButtonDisabled ? { mb: '0px', color: 'rgba(171, 171, 171, 0.8)', backgroundColor: 'rgba(171, 171, 171, 0.24)'} : { mb: '0px' } } variant="contained" onClick={() => { checkAnswer() }}> {"Controleer antwoord"}</Button>
                </>
            }
            {
                onBack ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button>: null
            }
        </ButtonsContainer>
      </ContentStack>
      <AlertDialog open={buttonDisabledDialogOpen} handleClose={() => setButtonDisabledDialogOpen(false)} title="Nog niet voltooid"
        content={"Je moet eerst de video afkijken voordat je verder kunt."}
        primaryButtonText="Sluiten" secondaryButtonText=""
        primaryButtonFunc={() => { setButtonDisabledDialogOpen(false) }} secondaryButtonFunc={() => { }} />
    </Box>
  );

  function checkAnswer() {
    if(!checkButtonDisabled) {
      if(questionRef.current?.checkAnswer()) {
        setCompleted(true);
      }
    } else {
      setButtonDisabledDialogOpen(true);
    }

  }

  function nextButton() {
    questionRef.current?.clearTextField();
    setCompleted(false);
    onComplete();
  }
}