import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: 'clamp(0.75rem, 0.75rem + 0.75vw, 0.9375rem)',
  textAlign: 'center',
  maxWidth: '600px',
  [theme.breakpoints.up('xs')]: {
    paddingTop: 'clamp(16px, 2vh, 48px)',
    marginTop: '0 !important'
  },
  // [theme.breakpoints.up('md')]: {
  //   paddingTop: 'clamp(16px, 2vh, 40px)',
  // },


  whiteSpace: 'pre-line',
}));

export default SubTitle;