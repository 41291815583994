import { useState, useRef} from 'react';
import { Outlet, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Button } from '@mui/material';

import useResponsive from '../../hooks/useResponsive';
//
import Header from './header';
import Nav from './nav';

import { route } from '../../_mock/routes';
import { ContentType } from '../../_mock/contentTypes';

import { formatUrl } from '../../utils/formatUrl';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.neutral,
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
  position: 'relative',
}));

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  height: '100%',
  paddingTop: APP_BAR_MOBILE, // + 24,
  // paddingBottom: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    paddingTop: '0px', // APP_BAR_DESKTOP + 0,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  // backgroundImage: `url('${formatUrl('assets/images/background.jpg')}')`,
  backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
}));

// ----------------------------------------------------------------------

export default function ContentPageLayout() {
  const [open, setOpen] = useState(false);

  const isDesktop = useResponsive('up', 'lg');

  const contentPageRef = useRef();

  const params = useParams();
  const r = route(params.routeId);

  const [currentContent, setCurrentContent] = useState(LoadContent());

  return (
    <StyledRoot sx={{marginLeft: isDesktop ? '280px' : '0px'}}>  
      <StyledContainer sx={{position: 'relative', padding: '0px !important'}}>
        <img style={{position: 'fixed', height: '100vh', width: '1200px', objectFit: 'cover', objectPosition: '50% 12%'}} alt="background" src={`${formatUrl('assets/images/background.jpg')}`} />
        <Header onOpenNav={() => setOpen(true)} />
        <Nav openNav={open} currentRoute={r} setSelectedContent={setSelectedContent} onCloseNav={() => setOpen(false)} />

        { process.env.REACT_APP_DEBUG === 'true' && (
          <Button sx={{position:'absolute', top: { lg: 24, xs:86 }, left:24, zIndex: 3}} variant="contained" onClick={() => { contentPageRef.current.onComplete() }}>
            [DEBUG] Skip content
          </Button>
        )}

        <Main sx={{ boxShadow: '0 0 8px 0px #00000014', zIndex: '1', position: 'relative'}}>
          <Outlet context={{current:[currentContent, setCurrentContent], myRef: contentPageRef}}/>
        </Main>
      </StyledContainer>
    </StyledRoot>
  );

  function setSelectedContent(content) {
    if(content !== currentContent) {
      setCurrentContent(content);
    } else if(content.type === ContentType.MultiGrid) {
      contentPageRef.current?.returnMultiToMenu();
    }
  }


  function LoadContent() {
    const progress = getRouteProgress();
    if (progress !== (undefined || null)) {
      return r.content[progress];
    }
    else {
      return r.content[0];
    } 
  };

  function getRouteProgress() {
    const progress = localStorage.getItem(r.id);

    if (progress !== (undefined || null) && progress > -1) {
      return progress;
    }
    else {
      return 0;
    }
  }

}
