import { Helmet } from 'react-helmet-async';

// @mui
import { Container, Stack, Typography, Card, Box, Paper, Grid, Divider, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils

// components
import { useParams, Link as RouterLink } from "react-router-dom";

import * as React from 'react';
import { isIOS } from 'react-device-detect';
import Iconify from '../components/iconify';
import { route } from '../_mock/routes';
import { formatUrl } from '../utils/formatUrl';

// ----------------------------------------------------------------------

const StyledRouteImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

RoutePage.propTypes = {
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  textAlign: 'center'
}));

export default function RoutePage() {
  const params = useParams();
  const r = route(params.routeId);

  return (
    <>
      <Helmet>
        <title> Expeditie Liemers </title>
      </Helmet>

      <Container>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card>
              <Box sx={{ pt: '100%', position: 'relative' }}>
                <StyledRouteImg src={formatUrl(r.cover)} alt={r.name} className={"unselectable unclickable"} />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={8} >
            <Item sx={{ boxShadow: 'none', p: 0, backgroundColor: 'unset' }}>
              <Stack textAlign="left" padding={2} spacing={2} >
                <Typography variant="h3" noWrap>
                  {r.name}
                </Typography>

                <Stack sx={{ flexDirection: { xs: "column", sm: "row" }, mb: '12px !important' }}>
                  <Button to="../qr" component={RouterLink} fullWidth size="small" variant="contained" startIcon={<Iconify icon="material-symbols:qr-code" width={20} height={20} />}>
                    Scan QR
                  </Button>
                  <Button sx={{ mt: { xs: 2, sm: 0 }, ml: { xs: 0, sm: 2 } }} onClick={() => openMaps()} fullWidth size="small" variant="outlined" startIcon={<Iconify icon="material-symbols:navigation-outline" width={20} height={20} />}>
                    Navigeer
                  </Button>
                </Stack>

                <Divider sx={{ width: '100%' }} />

                <Stack direction="row">
                  <Box>
                    <Iconify icon="ic:outline-place" sx={{ color: 'primary.main' }} width={22} height={22} />
                  </Box>
                  <Stack sx={{ pl: 2, mt: 0.25 }}>
                    <Typography variant="body2">{r.street}</Typography>
                    <Typography variant="body2">{r.zip} {r.city}</Typography>
                  </Stack>
                </Stack>

                <Divider sx={{ width: '100%' }} />

                <Stack direction="row">
                  <Box>
                    <Iconify icon="ic:outline-description" sx={{ color: 'primary.main' }} width={22} height={22} />
                  </Box>
                  <Stack sx={{ pl: 2, mt: 0.25 }}>
                    <Typography variant="body2">{r.description}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Item>
          </Grid>
        </Grid>

      </Container>
    </>
  );

  function openMaps() {
    if (r && r.position) {
      let url;
      if (isIOS) {
        url = `http://maps.apple.com/?daddr=${r.position.lat},${r.position.lng}`;
      } else {
        url = `https://maps.google.com/?q=${r.position.lat},${r.position.lng}`;
      }

      window.open(url, '_blank').opener = null;
    }
  }
}
