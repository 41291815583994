import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// @mui
import { Box, Drawer, List, Divider, Stack } from '@mui/material';
import { StyledNavItem } from '../../../components/nav-section/styles';
import { formatUrl } from '../../../utils/formatUrl';

// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';

import { Title, NavListItemText } from '../../../components/content';

//
import navConfig from './config';
import { ContentType } from '../../../_mock/contentTypes';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
  currentRoute: PropTypes.object,
  setSelectedContent: PropTypes.func
};

export default function Nav({ openNav, setSelectedContent, currentRoute, onCloseNav }) {
  const { pathname } = useLocation();

  // console.log('Nav', count);


  const currentRouteName = currentRoute.name;

  const isDesktop = useResponsive('up', 'lg');

  const firstMultiGridContent = currentRoute.content.find(c => c.type === ContentType.MultiGrid);

  const listItemSx = {
    '&.active': {
      color: 'text.primary',
      bgcolor: 'action.selected',
    },
    my: 0,
    pl: '0.75rem',
  }

  const contentNavConfig = [
    {
      title: 'Introductie',
      path: `/route/${currentRoute.id}/content/1`,
      children: []
    }
  ];


  if (firstMultiGridContent) {
    contentNavConfig.push({
      title: 'Opdrachten',
      path: `/route/${currentRoute.id}/content/${firstMultiGridContent.id}`,
      children: []
    });
  }

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  function closeNavDelayed(delay = 50) {
    if (!openNav) {
      return;
    }
    setTimeout(() => {
      onCloseNav();
    }, delay);
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column', backgroundColor: 'primary.lighter' },
        '&::before': {
          content: '""',
          position: 'absolute',
          width: 'calc(100% + 8px)',
          height: 'calc(100% + 16px)',
          top: '-8px',
          left: '-4px',
          filter: 'url(#combined-noise-displacement)',
          zIndex: 0,
          backgroundColor: 'primary.lighter',
          boxShadow: '0 0 2px 0 rgb(0 0 0 / 20%), 0 12px 24px -4px rgb(0 0 0 / 12%)',
        }
      }}
    >

      <Box sx={{ pt: 3 }}>
        <Logo />
      </Box>

      <Divider sx={{ my: 3 }} />

      <Box sx={{ mx: 2.5 }}>
        <Title variant="caption" primary="true" sx={{ marginRight: 'auto', padding: '2px 8px' }}>
          Algemeen
        </Title>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ mx: 2.5 }}>
        <Title variant="caption" primary='true' sx={{ marginRight: 'auto', padding: '2px 8px' }}>
          {currentRouteName}
        </Title>
      </Box>

      <List disablePadding sx={{ p: 2 }}>
        <StyledNavItem
          onClick={() => { setSelectedContent(currentRoute.content[0]); closeNavDelayed(); }}
          sx={listItemSx}>

          <NavListItemText disableTypography primary="Introductie" />
        </StyledNavItem>

        {firstMultiGridContent ? (
          <StyledNavItem
            onClick={() => { setSelectedContent(currentRoute.content.find(e => e.id === firstMultiGridContent.id)); closeNavDelayed(); }}
            sx={listItemSx}>

            <NavListItemText disableTypography primary="Opdrachten" />
          </StyledNavItem>
        ) :
          null}

      </List>

      <Box sx={{ flexGrow: 1 }} />
      <Divider sx={{}} />
      <Stack sx={{ p: 3, flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
        <img style={{ height: '32px' }} alt="Kunstwerk logo" src={formatUrl('assets/logos/kunstwerk.svg')} />
        <img style={{ height: '48px' }} alt="Leven in de Liemers logo" src={formatUrl('assets/logos/lidl-logo.png')} />
      </Stack>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              '& > div': { overflow: 'visible' },
              overflowY: 'visible',
              width: NAV_WIDTH,
              borderRightStyle: 'solid',
              borderColor: 'rgba(255, 255, 255, 0.08)',
              zIndex: '8',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          anchor="left"
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH, overflowY: 'unset', zIndex: '8', },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
