import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { isIOS, isSafari } from 'react-device-detect';
import { Title} from './Title'

export default function CombinedTitle({title, subtitle, subtitleBackColor}) {
 return (
    <Stack alignItems='center' spacing={2} sx={{position:'relative'}}>
            <Stack sx={{}}>
                {
                    subtitle ?
                <Title variant="h3" backgroundcolor={subtitleBackColor} primary={subtitleBackColor ? undefined : "true" } sx={{padding: isIOS || isSafari ? '0.33em' : '0.33em 0.33em 0.125em 0.33em', lineHeight: '1', rotate: '-1deg', whiteSpace:'nowrap', top: '0.25em', zIndex: '1'}}>
                {subtitle}
                </Title>
                :
                <Title variant="h3" backgroundcolor='transparent' sx={{padding: isIOS || isSafari ? '0.33em' : '0.33em 0.33em 0.125em 0.33em', lineHeight: '1', rotate: '-1deg', whiteSpace:'nowrap', top: '0.25em', zIndex: '1'}}>
                {' '}
                </Title>
                }
            </Stack>
        <Title variant="h1" sx={{padding: isIOS || isSafari ? '0.33em' : '0.33em 0.33em 0.125em 0.33em', marginTop: '0rem !important', rotate:'1deg', fontSize: 'clamp(2rem, 2rem + 3vw, 2.8125rem)'}}>
            {title}
        </Title>
    </Stack>
    );
};

CombinedTitle.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    subtitleBackColor: PropTypes.string,
  }
  