import { Helmet } from 'react-helmet-async';
import { React, useState, useRef, useMemo } from "react";
// @mui
import { Box, Stack, Container, Button } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import { ButtonsContainer, Title, SubTitle, CombinedTitle, ContentStack } from '../components/content';
import { CarouselNavigationButton } from '../components/carousel';
import Iconify from "../components/iconify";

import { RoutesCarousel } from '../components/routes-carousel';

// mock
import { routes } from '../_mock/routes';

import './style.css';

let images = [];

// ----------------------------------------------------------------------

export default function HomePage() {

  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef();

  useMemo(() => {
    images = routes.map((r) => ({ url: r.cover, rot: (Math.floor(Math.random() * 10) - 5) }));
  }, [])





  function nextRoute() {
    carouselRef?.current.goNext();
  }

  function prevRoute() {
    carouselRef?.current.goBack();
  }

  function setIndex(index) {
    setCurrentIndex(index);
  }

  return (
    <>
      <Helmet>
        <title> Expeditie Liemers </title>
      </Helmet>

      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pb: '0', pt: '0' }}>
        <ContentStack spacing={2} textAlign="left" alignItems='center'>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Stack alignItems='center' spacing={2}>
              <CombinedTitle title="Kies een mysterie"/>
            </Stack>
          </Container>
          <Container sx={{ alignItems: 'center', paddingTop:'0', display: 'flex', justifyContent: 'center', position: 'relative', marginTop: 'clamp(1rem, 1rem + 3vh, 8rem) !important',  marginBottom: 'clamp(1rem, 1rem + 3vh, 8rem) !important', flexGrow: '3' }}>

            {images.length > 1 ?
              <>
                <CarouselNavigationButton onClick={() => prevRoute()} sx={{ left: '16px', zIndex: '4' }} aria-label="previous content">
                  <Iconify sx={{ width: '24px', height: '24px', color: '#FFF' }} icon='ic:baseline-navigate-before' />
                </CarouselNavigationButton>



                <CarouselNavigationButton onClick={() => nextRoute()} sx={{ right: '16px', zIndex: '4' }} aria-label="next content">
                  <Iconify sx={{ width: '24px', height: '24px', color: '#FFF' }} icon='ic:baseline-navigate-next' />
                </CarouselNavigationButton>
              </>
              : null
            }

            <RoutesCarousel innerRef={carouselRef} onIndexChange={(num) => setIndex(num)} images={images} />

          </Container>
          <Box sx={{width: '100%', height:"100%", display:'flex', flexDirection:'column', justifyContent:'left', alignItems:'left', padding:'0px 24px', textAlign: 'left', maxWidth: '600px', marginTop: '0 !important'}}>

            <Title variant="caption" backgroundcolor={routes[currentIndex]?.color}  sx={{marginRight:'auto', padding: '4px 8px'}}>
              {routes[currentIndex]?.category}
            </Title>
            <Title variant="h4"  sx={{ padding: '4px 8px', margin:'8px auto 0px 0px'}}>
              {routes[currentIndex]?.name}
            </Title>
            <SubTitle variant="body1" sx={{textAlign:'left'}}>
              {routes[currentIndex]?.description}
            </SubTitle>
          </Box>

        <ButtonsContainer>
            <Button to={`../route/${routes[currentIndex]?.id}/content`} component={RouterLink} sx={{ mb: '0px' }} variant="contained">{"Start verhaal"}</Button>
          </ButtonsContainer>
        </ContentStack>
      </Box>
    </>
  );
}
