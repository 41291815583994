
// @mui
import { Box, Stack, Container, Button } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

// utils
import { styled } from '@mui/material/styles';

import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack, ButtonsContainerHeightBlocker } from '../../../components/content';
import { formatUrl } from '../../../utils/formatUrl';

// ----------------------------------------------------------------------

const StyledContentImg = styled('img')({
  padding: '24px',
  borderRadius: '32px',
  width: 'auto',
  maxHeight: '500px',
  maxWidth: '80%'
});

export default function ContentRouteComplete(content) {

  const { url, title, text, route } = content;

  return (
    <>
      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', pt: '8px' }}>
        <ContentStack spacing={2}>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Stack alignItems='center' spacing={2}>
            <CombinedTitle title={title} subtitle={route.category} subtitleBackColor={route.color}/>
              <SubTitle variant="body1">
                {text}
              </SubTitle>
            </Stack>
          </Container>
          <Container sx={{ alignItems: 'center', display: 'flex', p: '0px !important', justifyContent: 'center', position: 'relative' }}>
            <StyledContentImg className={"unclickable unselectable"} src={formatUrl(url)} alt={text} />
          </Container>

        <ButtonsContainerHeightBlocker />
        <ButtonsContainer>
            <Button sx={{ mb: '0px' }} to="/" variant="contained" component={RouterLink}>Volgende mysterie</Button>
          </ButtonsContainer>
        </ContentStack>
      </Box>
    </>
  );
}
