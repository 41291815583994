// @mui
import { Box, Stack, Container, Button } from '@mui/material';
import {useState } from 'react';

// components
import ReactPlayer from 'react-player/lazy';

import { AlertDialog } from '../../../components/dialog';
import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack,  } from '../../../components/content';
import  '../../../components/video.css'
import { formatUrl } from '../../../utils/formatUrl';

// ----------------------------------------------------------------------

export default function ContentVideo(content) {

  const { url, title, text, onComplete, fromMulti, multiTitle, videoThumbnail, onBack, blockSkip = false } = content;

  const [nextButtonDisabled, setNextButtonDisabled] = useState(blockSkip);
  const [buttonDisabledDialogOpen, setButtonDisabledDialogOpen] = useState(false);

  // blockSkip: block next button until video reached end or waited for 0.75* vid duration
  const [duration, setDuration] = useState(0);
  let checkTimeout = null;

  function setNextButtonTimeout() {
    if (blockSkip && !checkTimeout && nextButtonDisabled && duration > 0) {
      checkTimeout = setTimeout(() => {
        setNextButtonDisabled(false);
      }, duration * 0.75 );
    }
  }

  function next() {
    if(nextButtonDisabled) {
      setButtonDisabledDialogOpen(true);
    } else {
      onComplete();
    }
  }

  return (
    <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
      <ContentStack spacing={2}>
        <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          <Stack alignItems='center' spacing={2}>
            <CombinedTitle title={title} subtitle={multiTitle}/>
            <SubTitle variant="body1">
              {text}
            </SubTitle>
          </Stack>
        </Container>
        <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative', flexGrow: '2', maxWidth: '720px !important'}}>

          {url?.includes('vimeo') ?
            // vimeo embed
            <ReactPlayer
              // use ONLY video.css for height, vimeo urls need those settings
              url={formatUrl(url)}
              config={{attributes: { poster:formatUrl(videoThumbnail) } }}
              playing={false}
              controls
              onPlay={() => { setNextButtonTimeout();}}
              onDuration={(_duration) => {if(_duration) setDuration(_duration * 1000)}}
              onEnded={() => { setNextButtonDisabled(false);}}
              onError={() => { setNextButtonDisabled(false);}}
            />
           :
            // regular mp4 url
            <ReactPlayer
                // use video.css for height, vimeo urls need those settings
                height='auto'
                width='100%'
                url={formatUrl(url)}
                config={{attributes: { poster:formatUrl(videoThumbnail) } }}
                playing={false}
                playsinline
                controls
                onPlay={() => { setNextButtonTimeout();}}
                onDuration={(_duration) => {if(_duration) setDuration(_duration * 1000)}}
                onEnded={() => { setNextButtonDisabled(false);}}
                onError={() => { setNextButtonDisabled(false);}}
            />
          }
        </Container>

        
        <ButtonsContainer>
          <Button sx={ nextButtonDisabled ? { mb: '0px', color: 'rgba(171, 171, 171, 0.8)', backgroundColor: 'rgba(171, 171, 171, 0.24)'} : { mb: '0px' } }  variant="contained" onClick={() => { next() }}> {fromMulti ? "Terug naar overzicht" : "Verder"}</Button>
          {
            onBack ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button>: null
          }
        </ButtonsContainer>
      </ContentStack>
      <AlertDialog open={buttonDisabledDialogOpen} handleClose={() => setButtonDisabledDialogOpen(false)} title="Nog niet voltooid"
        content={"Je moet eerst de video afkijken voordat je verder kunt."}
        primaryButtonText="Sluiten" secondaryButtonText=""
        primaryButtonFunc={() => { setButtonDisabledDialogOpen(false) }} secondaryButtonFunc={() => { }} />
    </Box>
  );
}