import { forwardRef } from "react";
import PropTypes from 'prop-types';
import Iconify from "../iconify";

import { ContentType } from '../../_mock/contentTypes';

const ContentIcon = forwardRef((props, ref) => (
  <>
    <Iconify ref={ref} icon={GetTypeIcon(props.type)} sx={{ width: '100%', height: '100%', top: '0', left: '0', p: '25%', color: '#FFF', position: 'absolute' }} />
  </>
));

function GetTypeIcon(type) {
  switch (type) {
    case ContentType.Image: return 'ic:baseline-photo-library';
    case ContentType.Audio: return 'ic:baseline-audiotrack';
    case ContentType.Video: return 'ic:baseline-play-circle';
    case ContentType.Text: return 'ic:baseline-article';
    case ContentType.Panorama: return 'ic:baseline-360';
    case ContentType.Puzzle: return 'ic:baseline-extension';
    case ContentType.Tap: return 'ic:baseline-extension';
    case ContentType.BeforeAfter: return 'ic:baseline-extension';
    case ContentType.Quiz: return 'ic:baseline-quiz';
    case ContentType.Share: return 'ic:baseline-camera-alt';
    case ContentType.PictureFinder: return 'ic:baseline-image-search';
    case ContentType.Multi: return 'ic:baseline-view-week';
    case ContentType.Navigate: return 'ic:baseline-navigation';
    case ContentType.Model: return 'ic:baseline-3d-rotation';
    case ContentType.RouteComplete: return 'ic:baseline-flag';
    default: return 'ic:baseline-sentiment-slightly-dissatisfied';
  }
}

ContentIcon.propTypes = {
  type: PropTypes.string,
}

ContentIcon.displayName = "carouselImage";

export default ContentIcon;