// @mui
import { Box, Stack, Container, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// components
import ReactPlayer from 'react-player/lazy';

import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack } from '../../../components/content';
import { formatUrl } from '../../../utils/formatUrl';

// ----------------------------------------------------------------------

export default function ContentRouteCompleteVideo(content) {

  const { url, title, text, videoThumbnail, route } = content;

  return (
    <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
      <ContentStack spacing={2}>
        <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          <Stack alignItems='center' spacing={2}>
            <CombinedTitle title={title} subtitle={route.category} subtitleBackColor={route.color} />
            <SubTitle variant="body1">
              {text}
            </SubTitle>
          </Stack>
        </Container>
        <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative', flexGrow: '2', maxWidth: '900px !important' }}>

          {url?.includes('vimeo') ?
            // vimeo embed
            <ReactPlayer
              // use ONLY video.css for height, vimeo urls need those settings
              url={formatUrl(url)}
              config={{ attributes: { poster: formatUrl(videoThumbnail) } }}
              playing={false}
              controls
            />
            :
            // regular mp4 url
            <ReactPlayer
              // use video.css for height, vimeo urls need those settings
              height='auto'
              width='100%'
              url={formatUrl(url)}
              config={{ attributes: { poster: formatUrl(videoThumbnail) } }}
              playing={false}
              playsinline
              controls
            />
          }
        </Container>

        <ButtonsContainer>
          <Button sx={{ mb: '0px' }} to="/" variant="contained" component={RouterLink}>Volgende mysterie</Button>
        </ButtonsContainer>
      </ContentStack>
    </Box>
  );
}