import { ContentType } from "./contentTypes";


export const routes = [
  {
    id: 1,
    name: 'Lodewijk in de Liemers',
    cover: 'assets/lodewijk/images/routecard.png',
    category: 'Regenten en Vorsten',
    color: '#40476D',
    description: 'In het depot van het Liemers Museum kwam uit een stoffig hoekje een mysterieuze brief tevoorschijn die erg waardevol lijkt te zijn!\n\n Jullie hulp is nodig om een mysterie op te lossen. Er moet een aantal puzzels worden gekraakt voordat we een antwoord hebben. Met jullie hulp gaat dat vast en zeker lukken! Bereid je voor om in de geschiedenis van de Liemers te duiken.',
    content: [
      {
        id: 1,
        type: ContentType.Video,
        thumbnail: '',
        title: 'In het liemers museum',
        url: 'assets/lodewijk/video/Intro.mp4',
        videoThumbnail: 'assets/lodewijk/video/Intro.jpg',
        text: 'Het Liemers Museum heeft jullie hulp ingeschakeld om een mysterieuze brief te ontcijferen. Kijk je mee?',
      },
      {
        id: 2,
        type: ContentType.MultiGrid,
        thumbnail: '',
        url: [],
        contentId: [5, 6, 7, 8, 9, 10, 11, 12],
        title: 'Lodewijk in de Liemers',
        text: 'Rond alle opdrachten af om het mysterie van Lodewijk in de Liemers op te lossen.',
      },
      {
        id: 3,
        type: ContentType.RouteCompletePreview,
        thumbnail: '',
        title: 'Mysterie opgelost',
        text: '',
        buttonText: 'Bekijk videoboodschap',
        completeImage: 'assets/lodewijk/images/completed-regenten-en-vorsten.png',
      },
      {
        id: 4,
        type: ContentType.RouteCompleteVideo,
        thumbnail: '',
        title: 'Mysterie opgelost',
        text: '',
        url: 'assets/lodewijk/video/Outro.mp4',
        videoThumbnail: 'assets/lodewijk/video/Outro.jpg',
      },
      {
        id: 5,
        type: ContentType.Assignment,
        thumbnail: 'assets/lodewijk/images/thumbnails/Wie is de afzender.jpg',
        title: 'Wie is de afzender van deze brief?',
        contentId: [13, 14, 15],
        require: [],
      },
      {
        id: 6,
        type: ContentType.Assignment,
        thumbnail: 'assets/lodewijk/images/thumbnails/Schilderij.jpg',
        title: 'Schilderij',
        contentId: [16, 17, 18],
        require: [5],
      },
      {
        id: 7,
        type: ContentType.Assignment,
        thumbnail: 'assets/lodewijk/images/thumbnails/Wanneer.jpg',
        title: 'Wanneer',
        contentId: [19, 20, 21],
        require: [6],
      },
      {
        id: 8,
        type: ContentType.Assignment,
        thumbnail: 'assets/lodewijk/images/thumbnails/Havezate.jpg',
        title: 'Havezate',
        contentId: [22, 23, 24, 25, 26],
        require: [6],
      },
      {
        id: 9,
        type: ContentType.Assignment,
        thumbnail: 'assets/lodewijk/images/thumbnails/Dansende rivieren.jpg',
        title: 'Dansende rivieren',
        contentId: [27, 28, 29],
        require: [6],
      },
      {
        id: 10,
        type: ContentType.Assignment,
        thumbnail: 'assets/lodewijk/images/thumbnails/Dagoekfragment.jpg',
        title: 'Dagboekfragment',
        contentId: [30, 31, 32],
        require: [7, 8, 9],
      },
      {
        id: 11,
        type: ContentType.Assignment,
        thumbnail: 'assets/lodewijk/images/thumbnails/Wie is het.jpg',
        title: 'Wie is het',
        contentId: [33, 34, 35, 36, 37, 38, 39],
        require: [10]
      },
      {
        id: 12,
        type: ContentType.Assignment,
        thumbnail: 'assets/lodewijk/images/thumbnails/Woordzoeker.jpg',
        title: 'Woordzoeker',
        contentId: [40, 41],
        require: [11]
      },
      {
        id: 13,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Wie is de afzender van deze brief?',
        videoUrl: 'assets/lodewijk/video/Opdracht 1.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 1.mov',
        transcription: "Hmm. Die brief. Er valt me iets op! Sommige letters zien er anders uit. Misschien komen we er zo wel achter wie ons deze belangrijke opdracht heeft gegeven? Is het wel echt zo'n belangrijk persoon als hij of zij doet voorkomen?"
      },
      {
        id: 14,
        type: ContentType.ImageQuiz,
        thumbnail: '',
        images: [
          {
            url: 'assets/lodewijk/images/Brief_v1.png',
            title: 'De brief',
            text: "Let goed op de 'andere' letters",
          },
        ],
        title: 'Wie is de afzender van deze brief?',
        question: 'Wie heeft de brief geschreven?',
        answers: ['testhnk123', 'Frederik van Brandenburg'],
        text: "De schrijver van deze brief heeft zijn naam verstopt in de tekst. Kunnen jullie ontcijferen wie de brief geschreven heeft? Zoek de 'andere' letters. Het zijn er 22.",
        hintContent: "De naam bestaat uit drie woorden"
      },
      {
        id: 15,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Wie is de afzender van deze brief?',
        videoUrl: 'assets/lodewijk/video/Opdracht 1 - Opgelost.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 1 - Opgelost.mov',
        transcription: "Aha! Jullie hebben de afzender achterhaald! Goed werk! \n\nIk heb mijn boeken opengeslagen en wat blijkt? Frederik was een keurvorst uit Pruisen, maar dat noemen we tegenwoordig natuurlijk Duitsland. Dat hij zich zorgen maakte over een bloedbad in de Liemers is niet zo vreemd. De Liemers wisselde nogal eens van land: dan hoorde het bij het Duitse hertogdom Kleef en dan weer bij het hertogdom Gelre in de Republiek der Nederlanden. \n\nMaar! Dat hij vermoedt dat de oversteek voorkomen had kunnen worden is wél vreemd! Daar moeten we meer van weten."
      },
      {
        id: 16,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Schilderij',
        videoUrl: 'assets/lodewijk/video/Opdracht 2.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 2.mov',
        transcription: 'Ik weet toevallig dat er een schilderij is gemaakt van de oversteek. Misschien dat we daar een hint kunnen vinden? Het origineel hangt in het Rijksmuseum in Amsterdam maar wij hebben ergens in het depot een kopie. Hmmmm. Ah. Hier! Oh. Eh, die moeten we eerst weer even in elkaar zien te puzzelen zie ik. Kunnen jullie helpen?'
      },
      {
        id: 17,
        type: ContentType.Puzzle,
        thumbnail: '',
        url: 'assets/lodewijk/images/schilderij.jpg',
        title: 'Schilderij',
        text: 'Help ons het schilderij weer in elkaar te puzzelen!',
      },
      {
        id: 18,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Schilderij',
        videoUrl: 'assets/lodewijk/video/Opdracht 2 - Opgelost.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 2 - Opgelost.mov',
        transcription: 'Ja, fantastisch! Dit schilderij geeft ons weer een paar nieuwe hints om te onderzoeken!'
      },
      {
        id: 19,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Wanneer?',
        videoUrl: 'assets/lodewijk/video/Opdracht 3.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 3.mov',
        transcription: 'Jullie hebben het schilderij weer in elkaar gepuzzeld. Super gedaan! \n\nWat een enorm leger zeg! Hmm. Ik ben benieuwd op welke datum Lodewijk XIV exact is overgestoken. Met die datum kan ik op zoek naar hints in ons archief! \n\nOp welke datum stak Lodewijk de 14e de Rijn over?'
      },
      {
        id: 20,
        type: ContentType.ImageQuiz,
        thumbnail: '',
        images: [
          {
            url: 'assets/lodewijk/images/Museum plaatje.jpg',
            title: '',
            text: "Zoek de datum",
          },
        ],
        title: 'Wanneer?',
        question: 'Op welke datum in 1672 stak lodewijk de 14e de rijn over?',
        answers: ['testhnk123', '12 juni 1672', '12-06-1672', '12-6-1672', '12/06/1672', '12/6/1672', '12 juni', '12-06', '12 06', '12 06 1672'],
        text: "",
      },
      {
        id: 21,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Wanneer?',
        videoUrl: 'assets/lodewijk/video/Opdracht 3 - Opgelost.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 3 - Opgelost.mov',
        transcription: 'Super gedaan! We weten nu wanneer Lodewijk de 14e de Rijn over stak. Laten we doorpakken. Op naar de volgende!'
      },
      {
        id: 22,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Havezate',
        videoUrl: 'assets/lodewijk/video/Opdracht 4A.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 4A.mov',
        transcription: 'Ok, stukje bij beetje valt alles op zijn plaats! Laten we onderzoeken -waar- Lodewijk exact de Rijn over is gestoken. \n\nDit gebouw op het schilderij, zien jullie het ook? Dat moet een hint zijn! Het lijkt mij een havezate. \n\nEen havezate was een burcht of een versterkte boerenhoeve, en als je in de 17e eeuw ridder wilde zijn MOEST  je er eentje hebben. De meeste havezaten hebben dan ook een slotgracht. Daar waren ridders dol op! Zo kon je je eigendom veel beter verdedigen. \n\nMisschien vertelt de havezate op het schilderij ons meer over de plek waar Lodewijk XIV de Rijn is overgestoken. In de Liemers waren namelijk verschillende havezaten. Als we kunnen ontdekken welke havezate op het schilderij staat, komen we misschien weer een stapje verder. \n\nWelke burcht zien we op het schilderij?'
      },
      {
        id: 23,
        type: ContentType.PictureFinder,
        thumbnail: '',
        title: 'Havezate',
        text: 'Welke burcht zien we op het schilderij? Selecteer de juiste en controleer het antwoord.',
        correctItem: 5,
        items: [
          { name: 'Huis Hamerden', image: 'assets/lodewijk/images/havezate/Hamerden.jpg' },
          { name: 'Het Loowaard', image: 'assets/lodewijk/images/havezate/Loowaard.jpg' },
          { name: 'Magerhorst', image: 'assets/lodewijk/images/havezate/Magerhorst.jpg' },
          { name: 'Slot Ulft', image: 'assets/lodewijk/images/havezate/Slot Ulft.jpg' },
          { name: 'Huis Babberig', image: 'assets/lodewijk/images/havezate/Huis Babberig.jpg' },
          { name: 'Het Tolhuis', image: 'assets/lodewijk/images/havezate/Tolhuis.jpg' },
          { name: 'Huis Oploo', image: 'assets/lodewijk/images/havezate/Huis Oploo.jpg' },
          { name: 'Huis te Seventer', image: 'assets/lodewijk/images/havezate/Huis te Seventer.jpg' }
        ],
        hintImage: 'assets/lodewijk/images/havezate-hint.jpg',
        hintButtonText: 'Schilderij',
        hintIcon: 'mdi:eye',
        hintContent: 'Kijk goed naar de vorm van de burcht op het schilderij.',
        hintDelay: -1,
      },
      {
        id: 24,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Het Tolhuis',
        videoUrl: 'assets/lodewijk/video/Opdracht 4B.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 4B.mov',
        transcription: 'Goed gedaan! De havezate op het schilderij is inderdaad Het Tolhuis. Het Tolhuis stond vroeger aan de Rijn bij Lobith, maar na het rampjaar 1672 zal er weinig van over zijn. \n\nIk heb de plek gevonden waar Het Tolhuis gestaan moet hebben. Zou er nog iets te zien zijn van het Tolhuis? Dan weten we zeker dat dit de goede plek is.'
      },
      {
        id: 25,
        type: ContentType.Panorama,
        thumbnail: '',
        url: 'assets/lodewijk/images/Schutterspoortje_4K.jpg',
        title: 'Het Tolhuis',
        text: 'Kijk rond en zoek sporen van het Tolhuis.',
        infoSpotIcon: 'assets/images/infospot.png',
        infoSpots: [
          { x: -2000, y: 0, z: -1266, message: "Hmm. Dit gebouw is recenter gebouwd dan 1672. Dat zal niet het Tolhuis zijn!" },
          { x: 450, y: -50, z: -2500, message: "Een meterkast. Ik denk niet dat dit is wat we zoeken. Ze hadden toen nog geen elektriciteit." },
          { x: 1350, y: 0, z: -1250, message: "Nee, dit is niet wat we zoeken. Het zou er oud uit moeten zien!" },
          { x: 1200, y: 500, z: 2000, message: "Het Schutterspoortje? Natuurlijk, dat was vroeger deel van het Tolhuis! Goede vondst!" },
        ],
        correctInfoSpot: 3
      },
      {
        id: 26,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Havezate/Het Tolhuis',
        videoUrl: 'assets/lodewijk/video/Opdracht 4 - Opgelost.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 4 - Opgelost.mov',
        transcription: 'Super gedaan! Het Schipperspoortje ligt helemaal niet meer aan de Rijn. Zou de rivier in die 350 jaar sinds 1672 zijn verplaatst?.'
      },
      {
        id: 27,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Dansende Rivieren',
        videoUrl: 'assets/lodewijk/video/Opdracht 5.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 5.mov',
        transcription: "Dat Lodewijk met zijn legers de Rijn heeft kunnen oversteken was erg zorgelijk. Zoiets mocht natuurlijk nooit meer gebeuren! \n\nOm de Republiek der Nederlanden te beschermen is het Pannerdens Kanaal gegraven. Allereerst was het kanaal een verdedigingslinie, maar later is voor een aansluiting met de Rijn en de IJssel gezorgd. Zo werd het ook een belangrijke handelsroute. Ook nu nog heeft het Pannerdens Kanaal een belangrijke rol in het waterbeheer! \n\nDe Rijn, en ook de IJssel, veranderden nogal eens van plaats. Dat opschuiven van rivieren heet 'meanderen'.  Zo lag Loo ooit aan de andere kant van de rivier! \n\nIk heb een video gevonden waarin goed te zien is hoe de rivieren in deze regio zijn veranderd. Kunnen jullie voor me uitzoeken in welk jaar het Pannerdens Kanaal is gegraven? Dat zal nooit lang na 1672 zijn geweest!"
      },
      {
        id: 28,
        type: ContentType.VideoQuiz,
        thumbnail: '',
        title: 'Dansende rivieren',
        url: 'assets/lodewijk/video/Dansende Rivieren Ingekort.mp4',
        videoThumbnail: 'assets/lodewijk/video/Dansende Rivieren Ingekort.jpg',
        text: 'Bekijk en luister de video om er achter te komen in welk jaar het Pannerdens Kanaal is gegraven.',
        question: 'Welk jaar was het?',
        hintContent: "Bekijk en luister goed naar het filmpje!",
        hintDelay: -1,
        answers: ['testhnk123', '1705'],
      },
      {
        id: 29,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Dansende Rivieren',
        videoUrl: 'assets/lodewijk/video/Opdracht 5 - Opgelost.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 5 - Opgelost.mov',
        transcription: 'Wauw. Tof dat de gevolgen van iets dat zó lang geleden is gebeurd nog steeds te merken zijn. Vind je niet? \n\nBedankt voor je hulp. Op naar de volgende hint!'
      },
      {
        id: 30,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Dagboekfragment',
        videoUrl: 'assets/lodewijk/video/Opdracht 6.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 6.mov',
        transcription: 'We zijn weer een stap verder met ons onderzoek. Super fijn dat jullie zo goed helpen hoor! \n\nOndertussen hebben we in het museum ook niet stilgezeten. We zijn verder gaan zoeken in ons depot en vonden hier een dagboek van Cornelis Jan Hendriksen. Op 11 juni 1672 schreef Cornelis: \n\n“Ik heb vandaag toch zoiets raars meegemaakt. Ik heb aan de overkant een schutter zien zwaaien met vlaggen. Hij herhaalde een serie bewegingen met zijn vaandel en het zag er niet erg feestelijk uit.” \n\nSchutters waren de leden van een schutterij. Die zijn voor het eerst opgericht in de middeleeuwen om een dorp te verdedigen tegen landlopers en rovers. De bekendste schutters staan op het schilderij De Nachtwacht van Rembrandt van Rijn, maar ook in de Liemers waren schutters. Dit waren boeren die hun huis, haard en have wilden beschermen en elkaar daarmee hielpen. Samen sta je sterker, toch? \n\nZe waren een stuk armer dan die mannen uit Amsterdam op het schilderij, maar ze konden wel seinen met vaandels! Even snel een appje sturen zat er nog niet in toentertijd! \n\nCornelis heeft in zijn dagboek tekeningen gemaakt van de serie bewegingen die hij gezien heeft. Kunnen jullie ontdekken welke boodschap de schutter probeerde te seinen? \n\nHINT: GEBRUIK DE LEGENDA MET SEIN-SYMBOLEN OM DE CODE TE KRAKEN'
      },
      {
        id: 31,
        type: ContentType.ImageQuiz,
        thumbnail: '',
        images: [
          {
            url: 'assets/lodewijk/images/dagboekfragment.png',
            title: 'Dagboek',
            text: "",
          },
        ],
        title: 'Dagboekfragment',
        question: 'Vul hier het antwoord in',
        answers: ['testhnk123', 'Wij zijn verraden', 'wijzijnverraden'],
        hintImage: 'assets/lodewijk/images/dagboekfragment-hint.png',
        hintButtonText: 'Legenda',
        hintIcon: 'mdi:eye',
        text: "Gebruik de legenda met sein-symbolen om te achterhalen wat de schutter probeerde duidelijk te maken.",
        hintContent: "We zijn op zoek naar drie woorden."
      },
      {
        id: 32,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Dagboekfragment',
        videoUrl: 'assets/lodewijk/video/Opdracht 6 - Opgelost.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 6 - Opgelost.mov',
        transcription: 'Oh nee! Verraden? Maar door wie?! Laten we proberen het antwoord op deze vraag te vinden.'
      },
      {
        id: 33,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Wie is het?',
        videoUrl: 'assets/lodewijk/video/Opdracht 7A.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 7A.mov',
        transcription: 'We zijn er bijna! We weten al zoveel... dankzij jullie speurwerk! \n\nDus: Lodewijk XIV is op 12 juni 1672 de Rijn overgestoken bij Lobith en iemand heeft hem daarbij geholpen. Maar wie? Het moet iemand zijn geweest die de rivier heel goed kende. Iemand die wist dat je de Rijn kon oversteken op die plek bij Het Tolhuis in Lobith. \n\nWe hebben in de archieven gezocht en een lijst met verdachten opgesteld. We hebben deze kunnen terugbrengen naar 9 personen. De verrader moet hoogmoedig zijn geworden, want hij of zij kraste overal hints in! De booswicht dacht zeker dat we er niet achter zouden komen, maar met jullie talent gaat dat zeker wel gebeuren. \n\nKun jij met alle hints ontdekken wie de verrader is?'
      },
      {
        id: 34,
        type: ContentType.ModelQuiz,
        thumbnail: '',
        title: 'Wie is het',
        question: 'Wat is de hint op het zwaard?',
        answers: ['testhnk123', 'Ik ben geen vrouw'],
        text: 'Zoek de hint op dit zwaard.',
        model: 'assets/lodewijk/model/zwaard2.glb',
        poster: 'assets/lodewijk/model/zwaard.png',
      },
      {
        id: 35,
        type: ContentType.ModelQuiz,
        thumbnail: '',
        title: 'Wie is het',
        question: 'Welke hint hebben jullie kunnen vinden op dit voorwerp?',
        answers: ['testhnk123', 'Ik draag een hoed'],
        text: 'Zoek de hint op deze kanonskogel.',
        model: 'assets/lodewijk/model/mortierbom2.glb',
        orbitScale: 180,
        poster: 'assets/lodewijk/model/mortierbom.png',
      },
      {
        id: 36,
        type: ContentType.ModelQuiz,
        thumbnail: '',
        title: 'Wie is het',
        question: 'Welke hint hebben jullie op dit artefact kunnen vinden?',
        answers: ['testhnk123', 'Ik heb een baard'],
        text: 'Zoek de hint op deze helm.',
        orbitScale: 120,
        model: 'assets/lodewijk/model/helm2.glb',
        poster: 'assets/lodewijk/model/helm.png',
      },
      {
        id: 37,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Wie is het?',
        videoUrl: 'assets/lodewijk/video/Opdracht 7B.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 7B.mov',
        transcription: 'Zo! Met die hints moeten we toch kunnen achterhalen wie de verrader is!'
      },
      {
        id: 38,
        type: ContentType.PictureFinder,
        thumbnail: '',
        title: 'Wie is het?',
        text: 'Bepaal aan de hand van de hints wie de verrader is.',
        correctItem: 7,
        items: [
          { name: 'Wilhelm Eggebertus', image: 'assets/lodewijk/images/wie/1 - Wilhelm Eggebertus.jpg' },
          { name: 'Johannes Willemsen', image: 'assets/lodewijk/images/wie/2 - Johannes Willemsen.jpg' },
          { name: 'Maria Reijnen', image: 'assets/lodewijk/images/wie/3 - Maria Reijnen.jpg' },
          { name: 'Arnout Jansen', image: 'assets/lodewijk/images/wie/4 - Arnout Jansen.jpg' },
          { name: 'Hendrik De Lange', image: 'assets/lodewijk/images/wie/5 - Hendrik De Lange.jpg' },
          { name: 'Margareta Dirkske', image: 'assets/lodewijk/images/wie/6 - Margareta Dirkske.jpg' },
          { name: 'Geertruida Gerrits', image: 'assets/lodewijk/images/wie/7 - Geertruida Gerrits.jpg' },
          { name: 'Jan Petersz', image: 'assets/lodewijk/images/wie/8 - Jan Petersz.jpg' },
          { name: 'Magdalena Peternellen', image: 'assets/lodewijk/images/wie/9 - Magdalena Peternellen.jpg' },
        ],
        hintImage: 'assets/lodewijk/images/wie/hint.png',
        hintButtonText: 'Hints',
        hintIcon: 'mdi:eye',
      },
      {
        id: 39,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Wie is het?',
        videoUrl: 'assets/lodewijk/video/Opdracht 7 - Opgelost.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 7 - Opgelost.mov',
        transcription: 'De veerman?! Natuurlijk! Hij weet alles over de Rijn en dus ook waar je het beste kunt oversteken.'
      },
      {
        id: 40,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Woordzoeker',
        videoUrl: 'assets/lodewijk/video/Opdracht 8.webm',
        videoAppleUrl: 'assets/lodewijk/video/Opdracht 8.mov',
        transcription: 'Ha, hebbes! \n\nDoor de hulp van veerman Jan Petersz kon het Franse leger van Lodewijk XIV de aanval op de Nederlanden inzetten. Het Franse leger wist helemaal tot aan Utrecht door te dringen. Daar liepen ze uiteindelijk vast. \n\nDaarom wilde Frederik van Brandenburg natuurlijk uitzoeken wie de verrader was. \n\nIn het veld bij Lobith, waar Lodewijk en zijn kompanen de rivier over staken, werd flink gevochten. Niet alleen door Liemerse schutters, maar ook door Friese soldaten die te hulp kwamen. Dat veld wordt nog steeds de “bloedakker” genoemd, omdat er meer dan 1500 mannen zijn gesneuveld. Dit is een schril contrast met het westen van Nederland. Daar werd volop genoten van de rijkdom die de Gouden Eeuw met zich meebracht. \n\nWeet je wat ik denk? Misschien was het Franse leger verzwakt door al dat vechten in de Liemers en konden ze daarom Amsterdam niet innemen. Al was die waterlinie die de Hollanders aanlegden ook een slim idee.'
      },
      {
        id: 41,
        type: ContentType.WordPuzzle,
        thumbnail: '',
        title: 'Woordzoeker',
        text: 'Maak de zin compleet door de letters in de juiste vakjes te slepen',
        words: 'De Liemers redt de   Republiek',
        prefilled: [0, 4, 6, 8, 12, 13, 16, 20, 22, 24, 25, 28],
      },
    ]
  },

  // ROMEINEN IN DE LIEMERS ----------------------------------------------------------------------

  {
    id: 2,
    name: 'Romeinen in de Liemers',
    cover: 'assets/romeinen-in-de-liemers/Hoofdstukkaart_Romeinen in de Liemers_03.png',
    category: 'Grieken en Romeinen',
    color: '#932828',
    description: 'Er is een grafsteen gevonden van een Romeinse soldaat genaamd Marcus Mallius. Hij leefde hier in de Liemers, vlak na het begin van onze jaartelling. De grafsteen is dus ongeveer 2000 jaar oud! Een unieke Romeinse vondst! We weten niet hoe Marcus Mallius aan zijn einde is gekomen, maar we willen daar graag meer over te weten komen.',
    content: [
      {
        id: 1,
        type: ContentType.Video,
        thumbnail: '',
        title: 'In het liemers museum',
        url: 'assets/romeinen-in-de-liemers/Intro_HB.mp4',
        videoThumbnail: 'assets/romeinen-in-de-liemers/Intro_HB.jpg',
        text: 'Het Liemers Museum heeft jullie hulp ingeschakeld om de doodsoorzaak van Marcus Mallius te bepalen. Kijk je mee?',
      },
      {
        id: 2,
        type: ContentType.MultiGrid,
        thumbnail: '',
        url: [],
        contentId: [5, 6, 7, 8, 9, 10, 11, 12, 13],
        title: 'Romeinen in de Liemers',
        text: 'Rond alle opdrachten af om het mysterie van de Romeinen in de Liemers op te lossen.',
      },
      {
        id: 3,
        type: ContentType.RouteCompletePreview,
        thumbnail: '',
        title: 'Mysterie opgelost',
        text: '',
        buttonText: 'Bekijk videoboodschap',
        completeImage: 'assets/romeinen-in-de-liemers/completed-grieken-en-romeinen.png'
      },
      {
        id: 4,
        type: ContentType.RouteCompleteVideo,
        thumbnail: '',
        title: 'Mysterie opgelost',
        text: '',
        url: 'assets/romeinen-in-de-liemers/Outro_HB.mp4',
        videoThumbnail: 'assets/romeinen-in-de-liemers/Outro_HB.jpg',
      },
      {
        id: 5,
        type: ContentType.Assignment,
        thumbnail: 'assets/romeinen-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 1.jpg',
        title: 'De vondst',
        contentId: [198, 199, 200, 202, 203, 205, 206],
        require: [],
      },
      {
        id: 6,
        type: ContentType.Assignment,
        thumbnail: 'assets/romeinen-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 2.jpg',
        title: 'Leeftijd en diensttijd',
        contentId: [207, 208, 209, 211, 212],
        require: [5],
      },
      {
        id: 7,
        type: ContentType.Assignment,
        thumbnail: 'assets/romeinen-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 3.jpg',
        title: 'Dam van Drusus',
        contentId: [2122, 2123, 213, 214, 216, 217],
        require: [6],
      },
      {
        id: 8,
        type: ContentType.Assignment,
        thumbnail: 'assets/romeinen-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 4.jpg',
        title: 'Brief van Livilla',
        contentId: [218, 219, 220],
        require: [7],
      },
      {
        id: 9,
        type: ContentType.Assignment,
        thumbnail: 'assets/romeinen-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 5.jpg',
        title: 'Schat van Livilla',
        contentId: [221, 222, 223],
        require: [8],
      },
      {
        id: 10,
        type: ContentType.Assignment,
        thumbnail: 'assets/romeinen-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 6.jpg',
        title: 'Tentenkamp',
        contentId: [224, 225, 226, 228, 229],
        require: [9],
      },
      {
        id: 11,
        type: ContentType.Assignment,
        thumbnail: 'assets/romeinen-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 7.jpg',
        title: 'Romeinse spullen',
        contentId: [230, 231, 232],
        require: [10]
      },
      {
        id: 12,
        type: ContentType.Assignment,
        thumbnail: 'assets/romeinen-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 8.jpg',
        title: 'Gevechten',
        contentId: [233, 234, 235, 237, 238],
        require: [11]
      },
      {
        id: 13,
        type: ContentType.Assignment,
        thumbnail: 'assets/romeinen-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 9.jpg',
        title: ' De dood van Marcus Mallius',
        contentId: [239, 240],
        require: [12]
      },
      {
        id: 198,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'De vondst',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 1/A/Opdracht 1A - Intro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 1/A/Opdracht 1A - Intro.mov',
        transcription: 'De grafsteen is een van de bekendste Romeinse vondsten in Nederland en wordt gezien als bewijs dat hier, bij ons in de Liemers, de Dam van Drusus en ook het Romeinse fort Carvium hebben gelegen. Over deze dam bij het fort vertel ik jullie straks meer, maar voor nu zou het helpen als we erachter kunnen komen wanneer de grafsteen werd gevonden. We hebben een deel van een oude krant gevonden in ons archief. Hierin wordt gesproken over de grafsteen. Wellicht vinden we hierin de informatie die we zoeken.'
      },
      {
        id: 199,
        type: ContentType.ImageQuiz,
        thumbnail: '',
        images: [
          {
            url: 'assets/romeinen-in-de-liemers/Opdracht 1/A/Krant_03_Fractie_compressed.png',
            title: 'In welk jaar is de grafsteen gevonden?',
            text: "",
          },
        ],
        title: 'De vondst',
        question: 'In welk jaar is de grafsteen gevonden?',
        answers: ['testhnk123', '1938'],
        text: "Kunnen jullie aan de hand dit stukje krant achterhalen in welk jaar de grafsteen gevonden is?",
        hintContent: "Het was voor het begin van de Tweede Wereldoorlog."
      },
      {
        id: 200,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'De vondst',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 1/A/Opdracht 1A - Outro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 1/A/Opdracht 1A - Outro.mov',
        transcription: 'Aha! Jullie hebben de datum van de vondst achterhaald! Goed werk! \n\nNu we weten in welk jaar de grafsteen is gevonden, moeten we te weten zien te komen WAAR hij precies gevonden is. Het krantenknipsel is namelijk onderdeel van een volledig artikel. De stukken hiervan heb ik gelukkig ook nog kunnen vinden. We moeten deze alleen weer in elkaar zien te puzzelen… Kunnen jullie hierbij helpen?'
      },
      {
        id: 202,
        type: ContentType.Puzzle,
        thumbnail: '',
        url: 'assets/romeinen-in-de-liemers/Opdracht 1/B/Krant_03_1024x1024_compressed.png',
        title: 'De vondst',
        text: 'Help ons het artikel weer in elkaar te puzzelen!',
      },
      {
        id: 203,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'De vondst',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 1/B/Opdracht 1B - Outro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 1/B/Opdracht 1B - Outro.mov',
        transcription: 'Ja, fantastisch! Nu zouden we moeten kunnen achterhalen waar de grafsteen is gevonden.'
      },
      {
        id: 205,
        type: ContentType.ImageQuiz,
        thumbnail: '',
        images: [
          {
            url: 'assets/romeinen-in-de-liemers/Opdracht 1/B/Krant_03_1024x1024_compressed.png',
            title: 'Waar is de grafsteen gevonden?',
            text: "",
          },
        ],
        title: 'De vondst',
        question: 'Waar is de grafsteen gevonden?',
        answers: ['testhnk123', 'De Bijland', 'Bijland', 'De Bijland bij Herwen', 'De Bijland bij Herwen (Overbetuwe)', 'De Bijland bij Herwen'],
        text: "Bekijk het artikel om er achter te komen waar de grafsteen is gevonden.",
        hintContent: "\nHet antwoord bestaat uit twee woorden, bevindt zich in de eerste kolom en opgebaggerd betekent iets uit de modder op de bodem van een waterplas naar boven halen."
      },
      {
        id: 206,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'De vondst',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 1/C/Opdracht 1C - Outro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 1/C/Opdracht 1C - Outro.mov',
        transcription: 'Hmm... De Bijland bij Herwen. Dat kan kloppen aangezien De Liemers meer dan vier eeuwen aan de rand van het Romeinse Rijk lag. Laten we doorpakken. Op naar de volgende!'
      },
      {
        id: 207,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Leeftijd en diensttijd',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 2/Opdracht 2A - Intro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 2/Opdracht 2A - Intro.mov',
        transcription: 'De grafsteen is waarschijnlijk in de eerste eeuw na Christus gemaakt. Dit was aan het begin van de Romeinse heerschappij in de Liemers. Zoals al eerder genoemd is de grafsteen gemaakt voor de Romeinse soldaat Marcus Mallius. De naam staat op de steen.\n\nOok staan er Romeinse cijfers in de grafsteen gekerfd. Het eerste Romeinse cijfer staat voor de leeftijd van Marcus. Kunnen jullie ontcijferen hoe oud hij is geworden?'
      },
      {
        id: 208,
        type: ContentType.ModelQuiz,
        thumbnail: '',
        title: 'Leeftijd en diensttijd',
        question: 'Hoe oud is Marcus Mallius geworden?',
        answers: ['testhnk123', '35', 'vijfendertig'],
        text: 'Bekijk de grafsteen, zoek de Romeinse cijfers en ontcijfer hoe oud Marcus Mallius is geworden.',
        model: 'assets/romeinen-in-de-liemers/Opdracht 2/grafsteen_v2.glb',
        poster: 'assets/romeinen-in-de-liemers/Opdracht 2/grafsteen.png',
        hintContent: "'ANNO' betekent leeftijd",
        hintDelay: 20000,
        hintImage: 'assets/romeinen-in-de-liemers/Opdracht 2/Romeinse cijfers_01.png',
        hintButtonText: 'Romeinse cijfers',
        hintIcon: 'mdi:eye',
      },
      {
        id: 209,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Leeftijd en diensttijd',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 2/Opdracht 2A - Outro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 2/Opdracht 2A - Outro.mov',
        transcription: 'Goed werk! \n\nMarcus Mallius werd maar 35 jaar oud. Vroeger werden de mensen minder oud dan tegenwoordig, toch was 35 jaar niet zo heel erg oud. \n\nHet andere Romeinse cijfer op de grafsteen staat voor de tijd die Marcus Mallius in het leger heeft gewerkt. Kunnen jullie dat ook ontcijferen?'
      },
      {
        id: 211,
        type: ContentType.ModelQuiz,
        thumbnail: '',
        title: 'Leeftijd en diensttijd',
        question: 'Hoe lang heeft Marcus Mallius in het leger heeft gewerkt?',
        answers: ['testhnk123', '16', 'zestien'],
        text: 'Bekijk de grafsteen en ontcijfer hoe lang Marcus Mallius in het leger heeft gewerkt.',
        model: 'assets/romeinen-in-de-liemers/Opdracht 2/grafsteen_v2.glb',
        poster: 'assets/romeinen-in-de-liemers/Opdracht 2/grafsteen.png',
        hintContent: "'STIP' betekent dienstjaren",
        hintDelay: 20000,
        hintImage: 'assets/romeinen-in-de-liemers/Opdracht 2/Romeinse cijfers_01.png',
        hintButtonText: 'Romeinse cijfers',
        hintIcon: 'mdi:eye',
      },
      {
        id: 212,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Leeftijd en diensttijd',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 2/Opdracht 2B - Outro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 2/Opdracht 2B - Outro.mov',
        transcription: 'Hij was inderdaad 16 jaar in dienst van het Romeinse leger. Op de grafsteen vind je ook de tekst "Carvium ad molem", dit betekent "Herwen aan de dam".  Ga snel verder om meer te weten te komen over de dam van Drusus.'
      },
      {
        id: 2122,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Dam van Drusus',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 3/A/Opdracht 3A - Intro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 3/A/Opdracht 3A - Intro.mov',
        transcription: 'De Rijn vormde lange tijd de grens van het Romeinse Rijk: de Limes. In het Latijn wordt grens \'limes\' (spreek uit lie-mes) genoemd.\n\nDe Romeinen begonnen hier 10 voor Christus met het bouwen van een dam. Generaal Drusus gaf opdracht hiervoor, daarom spreken we over de Dam van Drusus.\n\nNou ben ik wel benieuwd waarom de dam werd gebouwd. Ik heb een video gevonden die hier wellicht duidelijkheid over geeft. Laten we eens kijken.'
      },
      {
        id: 2123,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Dam van Drusus',
        url: 'assets/romeinen-in-de-liemers/Opdracht 3/A/Romeinen in de Liemers - Water.mp4',
        videoThumbnail: 'assets/romeinen-in-de-liemers/Opdracht 3/B/Screenshot Dam.jpg',
        text: 'Bekijk de video en vindt de juiste reden waarom de Dam van Drusus werd gebouwd.',
      },
      {
        id: 213,
        type: ContentType.Quiz,
        thumbnail: '',
        title: 'Dam van Drusus',
        question: 'Waarom werd de Dam van Drusus gebouwd? Het antwoord heb je gezien in de video.',
        correctIndex: 2,
        options: [
          'Voor de handel',
          'Als verdedigbare grens',
          'Om meer water door de Rijn te laten stromen',
          'Voor de militaire aanwezigheid',
        ],
      },
      {
        id: 214,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Dam van Drusus',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 3/A/Opdracht 3A - Outro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 3/A/Opdracht 3A - Outro.mov',
        transcription: 'Ja, dat is goed!\n\nDe dam die Drusus wilde bouwen moest er inderdaad voor zorgen dat er genoeg water naar het vervolg van de Rijn ging, zodat de Romeinse oorlogsvloot er goed doorheen kon varen.\n\nWe weten niet precies hoe de Dam van Drusus eruit heeft gezien. Er zijn wel heel veel stenen gevonden, dus naar alle waarschijnlijkheid was de dam van steen. Ook zullen er houten planken zijn gebruikt maar deze zijn in de loop de jaren vergaan.\n\nDe dam moet een behoorlijke afmeting gehad hebben om het water de juiste kant op te krijgen. Waarschijnlijk was hij wel 500 meter lang en 9 meter hoog! \n\nJe ziet in het filmpje dat de dam tussen twee muren van houten planken wordt gemaakt. Daar waren heel veel planken voor nodig, wel 45.000. \n\nBekijk de afbeelding. Hoeveel planken moet elke legionair leggen? Kunnen jullie dit voor mij uitrekenen?'
      },
      {
        id: 216,
        type: ContentType.ImageQuiz,
        thumbnail: '',
        images: [
          {
            url: 'assets/romeinen-in-de-liemers/Opdracht 3/B/Screenshot Dam.jpg',
            title: 'Dam van Drusus',
            text: "Voor de dam zijn dus 45.000 planken nodig. Hoeveel planken moet elke legionair leggen?",
          },
        ],
        title: 'Dam van Drusus',
        question: 'Hoeveel planken moet elke legionair leggen?',
        answers: ['testhnk123', '5000', '5.000'],
        text: "Bekijk de afbeelding. Voor de dam waren dus 45.000 planken nodig. Hoeveel planken moet elke legionair leggen?",
        hintContent: "Een legionair is een Romeinse soldaat. Tel het aantal Romeinen op de dam."
      },
      {
        id: 217,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Dam van Drusus',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 3/B/Opdracht 3B - Outro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 3/B/Opdracht 3B - Outro.mov',
        transcription: 'Wauw, goed gedaan! Hier was ik zelf niet uitgekomen.\n\nWat een zwaar werk moet het zijn geweest zeg! Alles moest gebeuren met mankracht of paardenkracht. Machines waren er in die tijd nog niet. De stenen moesten handmatig worden verplaatst, soms wel van vele kilometers ver weg. Mocht men tijdens het werk een ongeluk krijgen dan was de kans om te herstellen van bijvoorbeeld een gebroken been, klein.\n\nDe doodsoorzaak van Marcus Mallius zou dus "simpelweg" een ongelukje tijdens het werken aan de dam zijn geweest. Of zit er toch meer achter?'
      },
      {
        id: 218,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Brief van Livilla',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 4/Opdracht 4 - Intro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 4/Opdracht 4 - Intro.mov',
        transcription: 'Vanuit verschillende bronnen is duidelijk geworden dat macht, rijkdom en aanzien in het Romeinse rijk heel belangrijk was. Het leek af en toe wel een soap. Men was zo nu en dan bereid om over lijken te gaan. De meeste keizers kwamen aan de macht door de concurrent uit te schakelen, in een kwaad daglicht te zetten, of zelfs te vermoorden.\n\nIk heb een brief gevonden die aan Marcus Mallius gericht is. Waaruit blijkt dat er mensen zijn die bereid zijn alles te doen voor macht en roem.'
      },
      {
        id: 219,
        type: ContentType.ModelQuiz,
        thumbnail: '',
        title: 'Brief van Livilla',
        question: 'Lees en bekijk de brief goed. Kun je hier in vinden waar de munten verstopt zijn?',
        answers: ['testhnk123', 'Carvium ad molem'],
        text: 'Zoek de hint op de brief.',
        model: 'assets/romeinen-in-de-liemers/Opdracht 4/brief_v3_2.glb',
        poster: 'assets/romeinen-in-de-liemers/Opdracht 4/brief_2.png',
      },
      {
        id: 220,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Brief van Livilla',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 4/Opdracht 4 - Outro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 4/Opdracht 4 - Outro.mov',
        transcription: "Carvium ad molem?! Dat stond ook op de grafsteen! Het is de Romeinse naam voor 'Herwen aan de dam', dat we nu kennen als De Bijland. De schat met munten lag dus op dezelfde plek als dat op de grafsteen van Marcus staat! Zou hier een connectie zijn?\n\nHmm… Wellicht vinden we weer een hint door de munten eens goed te bekijken."
      },
      {
        id: 221,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Schat van Livilla',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 5/Opdracht 5 - Intro_v2.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 5/Opdracht 5 - Intro_v2.mov',
        transcription: 'De Romeinen introduceerden het muntgeld in onze streek. Daarvoor was er sprake van ruilhandel.\n\nMarcus Mallius kreeg een hele kist vol. Hij kan een deel van dit muntgeld hebben gebruikt om zijn grafsteen te betalen. Maar wie wordt er eigenlijk afgebeeld op deze munten?'
      },
      {
        id: 222,
        type: ContentType.PictureFinder,
        thumbnail: '',
        title: 'Schat van Livilla',
        text: "Gebruik de 'Munt'-knop. Wie wordt er afgebeeld op de munt?",
        correctItem: 2,
        items: [
          { name: 'Medusa', image: 'assets/romeinen-in-de-liemers/Opdracht 5/Medusa.jpg' },
          { name: 'Julius Ceasar', image: 'assets/romeinen-in-de-liemers/Opdracht 5/Caesar.jpg' },
          { name: 'Nero Claudius Drusus', image: 'assets/romeinen-in-de-liemers/Opdracht 5/Drusus.jpg' },
          { name: 'Beatrix', image: 'assets/romeinen-in-de-liemers/Opdracht 5/Beatrix.jpg' },
        ],
        hintImage: 'assets/romeinen-in-de-liemers/Opdracht 5/Nero Claudius Drusus_munt_01.png',
        hintButtonText: 'Munt',
        hintIcon: 'mdi:eye',
        hintContent: 'Wie wordt er afgebeeld op de munt?',
        hintDelay: -1,
      },
      {
        id: 223,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Schat van Livilla',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 5/Opdracht 5 - Outro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 5/Opdracht 5 - Outro.mov',
        transcription: 'Heel goed! Het lijkt inderdaad Drusus te zijn op de munten.\n\nHet is heel bijzonder dat Marcus Mallius als gewone legersoldaat een grafsteen heeft. Dit was normaal gesproken alleen voor de rijke Romeinen weggelegd. Kwam dit door zijn connecties met de familie van Drusus of was er iets anders aan de hand?\n\nUit de brief die Marcus heeft ontvangen, wordt wel duidelijk dat macht, jaloezie en wraak vaker voorkwamen. Was iemand jaloers op het geld dat Marcus Mallius had gekregen? En is hij daarom uit de weg geruimd?\n\nDe doodsoorzaak van Marcus Mallius kan dus ook een wraakactie zijn geweest.\n\nLaten we verder zoeken!'
      },
      {
        id: 224,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Tentenkamp',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 6/A/Opdracht 6A - Intro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 6/A/Opdracht 6A - Intro.mov',
        transcription: 'Romeinse soldaten bouwden een tijdelijk tentenkamp als ze op expeditie gingen. Ik heb een video gevonden over de tentenkampen van de Romeinse soldaten. De omvang van de kampen en wat er allemaal komt kijken bij het opbouwen van zo’n tijdelijk kamp zijn op z’n minst bijzonder te noemen. Laten de video eens bekijken.'
      },
      {
        id: 225,
        type: ContentType.VideoQuiz,
        thumbnail: '',
        title: 'Tentenkamp',
        url: 'assets/romeinen-in-de-liemers/Opdracht 6/A/Romeinen in de Liemers - Het Leger.mp4',
        videoThumbnail: 'assets/romeinen-in-de-liemers/Opdracht 6/A/tentenkamp-video-thumb.jpg',
        text: 'Bekijk en luister de video om er achter te komen hoeveel dagen de Romeinen deden over het opbouwen van het kamp in Ermelo?',
        question: 'Hoeveel dagen deden de Romeinen over het opbouwen van het kamp in Ermelo?',
        hintContent: "Bekijk en luister goed naar het filmpje!",
        hintDelay: -1,
        answers: ['testhnk123', '1', 'een', 'één', '1 dag', 'een dag', 'één dag'],
      },
      {
        id: 226,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Tentenkamp',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 6/A/Opdracht 6A - Outro_v2.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 6/A/Opdracht 6A - Outro_v2.mov',
        transcription: "Zo hé… Eén dag?! Dat is super snel! \n\nMoet je nagaan dat ze dus praktisch een groot dorp opbouwden in die tijd. Wanneer alle tenten op de juiste plek stonden konden de Romeinen gelukkig ook even ontspannen: even lekker slapen of een spelletje spelen met de buurman in de andere tent.\n\nHet spel dat de soldaten speelden heet ‘Het molenspel’. Een eeuwenoud bordspel. Het zou wel tof zijn om dit een keer te proberen, niet waar? Helaas heb ik geen molenspel in ons depot kunnen vinden. Wél heb ik kunnen vinden wat de spelregels waren.\n\nPer beurt mag je één steen verplaatsen naar een vrije plek. Je kunt stenen van je tegenstander verwijderen als je 3 op een rij maakt (horizontaal of verticaal), dit wordt een 'molen' genoemd. Elke keer als je een molen vormt, mag je één steen van je tegenstander verwijderen.\n\nJe wint het spel als je tegenstander minder dan 3 stukken over heeft of geen geldige zet meer over heeft.\n\nEens kijken of we de spelregels van het molenspel goed begrijpen."
      },
      {
        id: 228,
        type: ContentType.PictureFinder,
        thumbnail: '',
        title: 'Tentenkamp',
        text: 'Speler wit is aan zet. Kies de situatie waarin de speler met de witte speelstenen binnen één zet het spel kan winnen.',
        correctItem: 3,
        items: [
          { name: 'Situatie 1', image: 'assets/romeinen-in-de-liemers/Opdracht 6/B/Situatie 1.jpg' },
          { name: 'Situatie 2', image: 'assets/romeinen-in-de-liemers/Opdracht 6/B/Situatie 2.jpg' },
          { name: 'Situatie 3', image: 'assets/romeinen-in-de-liemers/Opdracht 6/B/Situatie 3.jpg' },
          { name: 'Situatie 4', image: 'assets/romeinen-in-de-liemers/Opdracht 6/B/Situatie 4.jpg' },
        ],
        hintImage: 'assets/romeinen-in-de-liemers/Opdracht 6/B/Molenspel_spelregels_03.png',
        hintButtonText: 'Spelregels',
        hintIcon: 'mdi:eye',
        hintContent: '',
        hintDelay: -1,
      },
      {
        id: 229,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Tentenkamp',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 6/B/Opdracht 6B - Outro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 6/B/Opdracht 6B - Outro.mov',
        transcription: 'Ja, goed gedaan! \n\nToen jullie bezig waren heb ik nog wat extra onderzoek gedaan naar \'Het molenspel\'. Het blijkt dat het er soms erg fanatiek aan toe ging tijdens zo\'n spel. \n\nZou Marcus Mallius misschien hebben gewonnen van iemand die HEEL erg slecht tegen zijn verlies kon en hem daarom dus heeft vermoord?'
      },
      {
        id: 230,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Romeinse spullen',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 7/Opdracht 7 - Intro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 7/Opdracht 7 - Intro.mov',
        transcription: 'De Romeinen waren een slim volk, ze hadden al veel ontdekkingen gedaan die we hier nog niet kenden. De Romeinen namen ook spullen mee uit het Romeinse Rijk die hier nog niet bekend waren zoals stenen, sleutels, dakpannen, wijn, fruit maar ook katten.'
      },
      {
        id: 231,
        type: ContentType.VideoQuiz,
        thumbnail: '',
        title: 'Romeinse spullen',
        url: 'assets/romeinen-in-de-liemers/Opdracht 7/Romeinen in de Liemers - Wat brachten de Romeinen mee.mp4',
        videoThumbnail: 'assets/romeinen-in-de-liemers/Opdracht 7/romeinse-spullen-thumb.jpg',
        text: 'Welke dieren namen de Romeinen mee volgens dit filmpje?',
        question: 'Welke dieren namen de Romeinen mee?',
        hintContent: "Bekijk en luister goed naar het filmpje!",
        hintDelay: -1,
        answers: ['testhnk123', 'Kippen', 'Kip'],
      },
      {
        id: 232,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Romeinse spullen',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 7/Opdracht 7 - Outro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 7/Opdracht 7 - Outro.mov',
        transcription: 'Goed gedaan! \n\nKippen zijn inmiddels zo gewoon hier dat het moeilijk voor te stellen is dat ze er niet altijd al zijn geweest. Maar… met de kippen kwam ook de bacterie salmonella. Nog steeds kunnen mensen flink ziek worden van salmonella. Dit kan gebeuren als je kip of eieren niet goed bereidt. Nu krijg je hiervoor een antibioticum, maar dat was er in de tijd van de Romeinen nog niet.\n\nDe kans dat Marcus Mallius aan een salmonella besmetting is bezweken, is dus óók een optie.'
      },
      {
        id: 233,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Gevechten',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 8/A/Opdracht 8A - Intro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 8/A/Opdracht 8A - Intro.mov',
        transcription: 'We weten dus dat Marcus Mallius soldaat was in het leger van Drusus. In het Rijksmuseum hangt een schilderij waarop een historisch tafereel afgebeeld is, dit noemen ze ook wel een \'historiestuk\'. Generaal Drusus en zijn leger staan hier samen met een Germaanse wichelaar op, dat is iemand die voorspellingen doet.\n\nIk kan het origineel zo 1-2-3 niet vinden, maar wat ik wel heb gevonden is een app waarin iemand zijn persoonlijke collectie digitaal tentoonstelt. In die collectie zou een kopie van het schilderij moeten zitten. \n\nZouden jullie kunnen kijken of jullie hem kunnen vinden?'
      },
      {
        id: 234,
        type: ContentType.Panorama,
        thumbnail: '',
        url: 'assets/romeinen-in-de-liemers/Opdracht 8/A/ArtGallery_360_Panorama_4K.jpg',
        title: 'Gevechten',
        text: 'Zoek het schilderij met Drusus en de Germaanse wichelaar er op.',
        infoSpotIcon: 'assets/images/infospot.png',
        infoSpots: [
          { x: -2500, y: 200, z: 750, message: "Dit is een zelfportret van Vincent van Gogh uit 1887. Dus dit is hem niet." },
          { x: -1070, y: 200, z: -2500, message: "Dit is 'De ontkenning van Sint-Pieter' van Rembrandt van Rijn uit 1660. Een prachtig schilderij, maar niet degene die we zoeken." },
          { x: 2500, y: 200, z: -925, message: "Dit schilderij van Ferdinand Bol is gemaakt omstreeks 1658 - 1662 en heet: De vredesonderhandelingen tussen Claudius Civilis en Quintus Petillius Cerealis op de afgebroken brug. De persoon in het harnas is ook een Romein, maar dit is helaas niet het schilderij dat we zoeken." },
          { x: 950, y: 200, z: 2500, message: "De Romeinse veldheer Drusus en de Germaanse wichelaar, gemaakt door Charles Rochussen in 1890. Ja, dat is hem!" },
        ],
        correctInfoSpot: 3
      },
      {
        id: 235,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Gevechten',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 8/A/Opdracht 8A - Outro_v2.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 8/A/Opdracht 8A - Outro_v2.mov',
        transcription: 'Ik wist wel dat jullie hem zouden vinden! \n\nZowel de Romeinen als Germanen geloofden in verschillende goden. De goden waren vaak verbonden met natuurverschijnselen. Bij Herwen zijn de restanten van een groot tempelcomplex gevonden. Bekende godennamen die men daar is tegengekomen zijn; Hercules Magusanus, Jupiter en Mercurius. \n\nSoms namen Romeinse soldaten een klein beeldje van een god mee. Het gaf de Romeinse soldaten moed en kracht om door te gaan met gevechten ver van huis.\n\nIk heb zo’n beeldje kunnen vinden. Het is van één van de Romeinse godinnen. Kan jij ontdekken van welke godin?'
      },
      {
        id: 237,
        type: ContentType.ModelQuiz,
        thumbnail: '',
        title: 'Gevechten',
        question: 'Welke godin is dit?',
        answers: ['testhnk123', 'Minerva'],
        text: 'Onderzoek het beeldje en kijk of jullie erachter kunnen komen welke godin dit is.',
        model: 'assets/romeinen-in-de-liemers/Opdracht 8/B/minerva_v3_2.glb',
        poster: 'assets/romeinen-in-de-liemers/Opdracht 8/B/minerva.png',
      },
      {
        id: 238,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Gevechten',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 8/B/Opdracht 8B - Outro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 8/B/Opdracht 8B - Outro.mov',
        transcription: 'Lekker bezig! Het is inderdaad Minerva, de godin van kunst, cultuur maar ook van oorlog.\n\nRomeinse soldaten moesten continu nieuwe gebieden veroveren en verdedigen, daarbij werd flink gevochten. In de Liemers is ook zo\'n soort beeldje gevonden, die is te zien in het Liemers Museum.\n\nDe kans dat Marcus Mallius is omgekomen door gevechten is dus een optie.'
      },
      {
        id: 239,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'De dood van Marcus Mallius',
        videoUrl: 'assets/romeinen-in-de-liemers/Opdracht 9/Opdracht 9 - Intro.webm',
        videoAppleUrl: 'assets/romeinen-in-de-liemers/Opdracht 9/Opdracht 9 - Intro.mov',
        transcription: 'Dat is alle informatie die we hebben.\n\nHoe denken jullie dat Marcus Mallius is gestorven? Kwam het door gevechten voor het Romeinse rijk of doordat hij bij de bouw van de dam gewond raakte? Door wraak van een jaloerse vrouw of man? Door de slechte verliezer bij het molenspel? Of toch door het eten van rauwe kip?'
      },
      {
        id: 240,
        type: ContentType.PictureFinder,
        thumbnail: '',
        title: 'De dood van Marcus Mallius',
        text: 'Wat is volgens jullie de doodsoorzaak van Marcus Mallius?',
        correctItem: -1,
        items: [
          { name: 'Omgekomen tijdens de werkzaamheden aan de dam', image: 'assets/romeinen-in-de-liemers/Opdracht 9/Dam.jpg' },
          { name: 'Om het leven gebracht omdat iemand uit was op zijn geld', image: 'assets/romeinen-in-de-liemers/Opdracht 9/Geld.jpg' },
          { name: 'Vermoord door de slechte verliezer bij het molenspel', image: 'assets/romeinen-in-de-liemers/Opdracht 9/Molenspel.jpg' },
          { name: 'Overleden aan salmonellavergiftiging', image: 'assets/romeinen-in-de-liemers/Opdracht 9/Salmonella.jpg' },
          { name: 'Gestorven tijdens één van de gevechten hier in de buurt', image: 'assets/romeinen-in-de-liemers/Opdracht 9/Gevecht.jpg' },
        ],
      },

    ]
  },

  // HEILIGE IN DE LIEMERS ----------------------------------------------------------------------

  {
    id: 3,
    name: 'Heilige in de Liemers',
    cover: 'assets/heilige-in-de-liemers/Hoofdstukkaart_Heilige in de Liemers_01.png',
    category: 'Monniken en Ridders',
    color: '#F9A03F',
    description: 'Er is een mysterieus pakketje zonder afzender afgeleverd bij het Liemers Museum. Wie heeft ons dit pakketje gestuurd en waarom? Maar wat we vooral te weten willen komen is wat er in het pakketje zit en of het wel thuishoort in het Liemers Museum. Help ons het mysterie op te lossen!',
    content: [
      {
        id: 1,
        type: ContentType.Video,
        thumbnail: '',
        title: 'In het liemers museum',
        url: 'assets/heilige-in-de-liemers/Intro_HB.mp4',
        videoThumbnail: 'assets/heilige-in-de-liemers/Intro_HB.jpg',
        text: 'Het Liemers Museum heeft jullie hulp nodig bij het onderzoeken van een mysterieus pakketje. Kijk je mee?',
      },
      {
        id: 2,
        type: ContentType.MultiGrid,
        thumbnail: '',
        url: [],
        contentId: [5, 6, 7, 8, 9, 10, 11],
        title: 'Heilige in de Liemers',
        text: 'Rond alle opdrachten af om het mysterie van de Heilige in de Liemers op te lossen.',
      },
      {
        id: 3,
        type: ContentType.RouteCompletePreview,
        thumbnail: '',
        title: 'Mysterie opgelost',
        text: '',
        buttonText: 'Bekijk videoboodschap',
        completeImage: 'assets/heilige-in-de-liemers/completed-monikken-en-ridders.png'
      },
      {
        id: 4,
        type: ContentType.RouteCompleteVideo,
        thumbnail: '',
        title: 'Mysterie opgelost',
        text: '',
        url: 'assets/heilige-in-de-liemers/Outro_HB.mp4',
        videoThumbnail: 'assets/heilige-in-de-liemers/Outro_HB.jpg',
      },
      {
        id: 5,
        type: ContentType.Assignment,
        thumbnail: 'assets/heilige-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 1.jpg',
        title: 'Mysterieus pakketje',
        contentId: [301, 302, 303, 304, 305],
        require: [],
      },
      {
        id: 6,
        type: ContentType.Assignment,
        thumbnail: 'assets/heilige-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 2.jpg',
        title: 'Heilige in de Liemers?',
        contentId: [306, 307, 308, 309, 310],
        require: [5],
      },
      {
        id: 7,
        type: ContentType.Assignment,
        thumbnail: 'assets/heilige-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 3.jpg',
        title: 'Willibrordus',
        contentId: [311, 312, 313, 3132, 3133],
        require: [6],
      },
      {
        id: 8,
        type: ContentType.Assignment,
        thumbnail: 'assets/heilige-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 4.jpg',
        title: 'In hetzelfde schuitje',
        contentId: [314, 315, 316],
        require: [7],
      },
      {
        id: 9,
        type: ContentType.Assignment,
        thumbnail: 'assets/heilige-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 5.jpg',
        title: 'Dagboekfragment',
        contentId: [317, 318, 319, 320, 321],
        require: [8],
      },
      {
        id: 10,
        type: ContentType.Assignment,
        thumbnail: 'assets/heilige-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 6.jpg',
        title: 'De legende',
        contentId: [322, 323, 324, 325, 326],
        require: [9],
      },
      {
        id: 11,
        type: ContentType.Assignment,
        thumbnail: 'assets/heilige-in-de-liemers/Overview thumbnails/Thumbnail - Opdracht 7.jpg',
        title: 'Wapen van Overbetuwe',
        contentId: [327, 328, 329],
        require: [10],
      },
      {
        id: 301,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Mysterieus pakketje',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 1/A/Opdracht 1A - Intro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 1/A/Opdracht 1A - Intro.mov',
        transcription: 'Goed, genoeg gepraat! Ik kan de spanning niet meer aan! Laten we hem openen!',
      },
      {
        id: 302,
        type: ContentType.VideoTap,
        thumbnail: '',
        title: 'Mysterieus pakketje',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 1/A/Doos_Anim_1.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 1/A/Doos_Anim_1_iosHEVC.mov',
        text: 'Tik op het pakketje om hem te openen',
      },
      {
        id: 303,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Mysterieus pakketje',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 1/A/Opdracht 1A - Outro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 1/A/Opdracht 1A - Outro.mov',
        transcription: 'Wauw, wat is het? Het lijkt op een stukje bot. Maar van wie of wat is het?! Hmm… vreemd. Er zit ook een brief bij. Laten we die eens bekijken, wellicht staat daar in wat het precies is.\n\nOw… Ik zie dat de brief niet in het Nederlands is geschreven. Volgens mij is het Latijn, maar het handschrift is niet echt duidelijk, op een paar woorden na. Hoe is jullie Latijn? Haha, grapje! Daar hebben we tegenwoordig natuurlijk hulpmiddelen voor. Kijk maar of het daarmee lukt. Misschien geeft één van deze woorden ons een aanknopingspunt.',
      },
      {
        id: 304,
        type: ContentType.TapQuiz,
        thumbnail: '',
        title: 'Mysterieus pakketje',
        text: 'Tik op de brief om hem te bekijken.',
        textFullscreen: 'Tik op de donkerder gekleurde woorden om ze te vertalen.',
        question: 'Welk woord vertelt ons wat er in het pakketje zit?',
        answers: ['testhnk123', 'reliquias', 'relikwie', 'reliqui', 'reliquie'],
        background: 'assets/heilige-in-de-liemers/Opdracht 1/B/brief-before.png',
        targets: [
          { id: 0, x: 26, y: 25.25, sizeX: 20, sizeY: 5, image: 'assets/heilige-in-de-liemers/Opdracht 1/B/woord1.png' },
          { id: 1, x: 24, y: 39.2, sizeX: 25, sizeY: 5, image: 'assets/heilige-in-de-liemers/Opdracht 1/B/woord2.png' },
          { id: 2, x: 38, y: 48, sizeX: 18, sizeY: 5, image: 'assets/heilige-in-de-liemers/Opdracht 1/B/woord3.png' },
          { id: 2, x: 57, y: 48, sizeX: 20, sizeY: 5, image: 'assets/heilige-in-de-liemers/Opdracht 1/B/woord4.png' },
        ],
      },
      {
        id: 305,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Mysterieus pakketje',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 1/B/Opdracht 1B - Outro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 1/B/Opdracht 1B - Outro.mov',
        transcription: 'Ja, dat moet het zijn! Een relikwie is namelijk een stukje overblijfsel van een heilige of een voorwerp dat met een heilige in aanraking is geweest. Het is dus waarschijnlijk een stukje bot van een persoon die als heilig werd beschouwd.\n\nTegenwoordig worden er op veilingsites regelmatig eigendommen van popsterren en sporthelden aangeboden, waarvoor veel geld betaald wordt. In de middeleeuwen was het niet anders, al waren toen vooral religieuze idolen populair. Relieken waren heel belangrijk voor de middeleeuwse gelovigen. Relieken konden volgens hen zorgen voor wonderen.\n\nMaar goed, volgens mij dwaal ik een beetje af… Kunnen we er achter komen van welke heilige deze relikwie is?',
      },
      {
        id: 306,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Heilige in de Liemers?',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 2/A/Opdracht 2A - Intro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 2/A/Opdracht 2A - Intro.mov',
        transcription: 'De naam van een heilige begint met Sint. De bekendste heilige is misschien wel Sint Nicolaas. Maar waarom is dit relikwie naar ONS gestuurd? Zouden we een heilige hebben gehad in de Liemers? Waar beginnen met zoeken? Hmm… Ik denk dat een kerk een logische plek zal zijn om onze zoektocht te starten. Niet?\n\nLaten we eens een kijkje nemen bij de kerk die hier vlak naast het Liemers Museum staat. Wellicht vinden we daar een aanwijzing.',
      },
      {
        id: 307,
        type: ContentType.Panorama,
        thumbnail: '',
        url: 'assets/heilige-in-de-liemers/Opdracht 2/A/Panorama_St Andreaskerk_01_4K.jpg',
        title: 'Heilige in de Liemers?',
        text: 'Kijk rond en zoek een aanwijzing.',
        infoSpotIcon: 'assets/images/infospot.png',
        infoSpots: [
          { x: -2500, y: 160, z: 900, message: "Het mysterieuze pakketje is dan wel via de post bij ons gekomen, maar ik denk niet dat we iets te weten zullen komen over de heilige door onze aandacht te richten op een brievenbus." },
          { x: 300, y: -150, z: -3000, message: "Het muurtje lijkt onderdeel te zijn van de kerk, maar hier zullen we niets aan hebben." },
          { x: 2500, y: -1200, z: -50, message: "Ja! De kans dat we hier een aanwijzing op vinden is groot! Laten we het eens aandachtiger bekijken." },
          { x: 1200, y: 200, z: 3000, message: "Dit lijkt me een vrij normale boom. Dit zal vast niets te maken hebben met de heilige die we zoeken" },
        ],
        correctInfoSpot: 2
      },
      {
        id: 308,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Heilige in de Liemers?',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 2/A/Opdracht 2A - Outro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 2/A/Opdracht 2A - Outro.mov',
        transcription: 'Goed bezig! Op het informatiebord dat jullie vonden bij de Sint Andreaskerk in Zevenaar staat misschien wel een aanwijzing. Kunnen jullie het bordje eens bestuderen om te kijken of er een naam van een heilige op staat die ons verder kan helpen?',
      },
      {
        id: 309,
        type: ContentType.ImageQuiz,
        thumbnail: '',
        images: [
          {
            url: 'assets/heilige-in-de-liemers/Opdracht 2/B/Informatiebordje kerk_03.jpg',
            title: 'Zoek de naam van een heilige op het informatiebord.',
            text: "",
          },
        ],
        title: 'Heilige in de Liemers?',
        question: 'Wat is de naam van de heilige?',
        answers: ['testhnk123', 'Sint Willibrordus', 'Willibrordus', 'Sint Willibrord', 'Willibrord', 'Willibrodus', 'Willibordus', 'Sint Willibrodus', 'Sint Willibordus', 'St. Willibrordus', 'St Willibrordus', 'St. Willibrord', 'St. Willibrodus', 'St. Willibordus'],
        text: "Zoek op het informatiebord de naam van de heilige die ons verder kan helpen.",
        hintContent: "Misschien is de kerk onderdeel van een kerkgemeenschap."

      },
      {
        id: 310,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Heilige in de Liemers?',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 2/B/Opdracht 2B - Outro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 2/B/Opdracht 2B - Outro.mov',
        transcription: 'Ah, dankje! Wat ik weet van Sint Willibrordus is dat hij de beschermheilige van Nederland is. Hij kwam als monnik in 690 vanuit Ierland naar Nederland. Om de hier mensen tot het Christendom te bekeren. Kunnen we nog meer te weten komen over Willibrordus?',
      },
      {
        id: 311,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Willibrordus',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 3/Opdracht 3A - Intro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 3/Opdracht 3A - Intro.mov',
        transcription: 'Ik heb in de tussentijd nog even in het depot gezocht naar aanwijzingen over Willibrordus en kwam een portret van hem tegen. Het lag alleen wel jaren lang opgevouwen tussen een stapel oude boeken en viel helaas uit elkaar toen ik hem open wilde vouwen. Kunnen jullie hem weer in elkaar zetten?',
      },
      {
        id: 312,
        type: ContentType.Puzzle,
        thumbnail: '',
        url: 'assets/heilige-in-de-liemers/Opdracht 3/willibrordus-portret-1024x1024px.jpg',
        title: 'Willibrordus',
        text: 'Help ons het portret weer in elkaar te puzzelen!',
      },
      {
        id: 313,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Willibrordus',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 3/Opdracht 3A - Outro_v2.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 3/Opdracht 3A - Outro_v2.mov',
        transcription: 'Top! Goed gedaan! Hmm… Hij heeft een kerk in zijn hand. Maar welke kerk is dat precies? Laten we eens kijken of we dat kunnen achterhalen. Ik heb een reeks met afbeeldingen van kerken voor jullie. Kunnen jullie aan de hand van deze afbeeldingen achterhalen welke kerk het is die hij in z\'n hand heeft?',
      },
      {
        id: 3132,
        type: ContentType.PictureFinder,
        thumbnail: '',
        title: 'Willibrordus',
        text: "Gebruik de 'Portret'-knop. Welke kerktoren heeft hij in zijn hand? Selecteer de juiste en controleer het antwoord.",
        correctItem: 8,
        items: [
          { name: 'Grote kerk Dordrecht', image: 'assets/heilige-in-de-liemers/Opdracht 3/kerken/Grote kerk Dordrecht.jpg' },
          { name: 'Kerk Aalst', image: 'assets/heilige-in-de-liemers/Opdracht 3/kerken/Kerk Aalst.jpg' },
          { name: 'Kerk Arnhem', image: 'assets/heilige-in-de-liemers/Opdracht 3/kerken/Kerk Arnhem.jpg' },
          { name: 'Kerk Deventer', image: 'assets/heilige-in-de-liemers/Opdracht 3/kerken/Kerk Deventer.jpg' },
          { name: 'Kerk Leende', image: 'assets/heilige-in-de-liemers/Opdracht 3/kerken/Kerk Leende.jpg' },
          { name: 'Kerk Maarssen', image: 'assets/heilige-in-de-liemers/Opdracht 3/kerken/Kerk Maarssen.jpg' },
          { name: 'Kerk Tongeren', image: 'assets/heilige-in-de-liemers/Opdracht 3/kerken/Kerk Tongeren.jpg' },
          { name: 'Nieuwe kerk Groningen', image: 'assets/heilige-in-de-liemers/Opdracht 3/kerken/Nieuwe kerk Groningen.jpg' },
          { name: 'Dom kerk Utrecht', image: 'assets/heilige-in-de-liemers/Opdracht 3/kerken/Dom kerk Utrecht.jpg' },
          { name: 'Oude kerk Amsterdam', image: 'assets/heilige-in-de-liemers/Opdracht 3/kerken/Oude kerk Amsterdam.jpg' },
        ],
        hintImage: 'assets/heilige-in-de-liemers/Opdracht 3/Willibrordus portret_kerk_close_01.jpg',
        hintButtonText: 'Portret',
        hintIcon: 'mdi:eye',
        hintContent: 'Welke kerk heeft hij in z\'n hand?',
        hintDelay: -1,
      },
      {
        id: 3133,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Willibrordus',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 3/Opdracht 3B - Outro_v2.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 3/Opdracht 3B - Outro_v2.mov',
        transcription: 'Ja, dat is de juiste! De kerk die hij in z’n hand heeft is de Dom in Utrecht. Als Willibrordus belangrijk voor de Liemers zou zijn geweest, dan had hij vast een kerk vastgehouden die in de Liemers staat, toch? Ik denk dus niet dat dit is wie we zoeken… *zucht* Nou ja, laten we niet bij de pakken neer gaan zitten en onze zoektocht voortzetten.',
      },
      {
        id: 314,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'In hetzelfde schuitje',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 4/Opdracht 4 - Intro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 4/Opdracht 4 - Intro.mov',
        transcription: 'Willibrordus heeft dus vooral veel betekent in Utrecht en niet voor de Liemers. Maar… hij kwam met 11 of 12 andere monniken in een bootje vanuit Ierland naar ons land toe. Al deze monniken waren onderdeel van een klooster in Ierland. \n\nIk heb een eeuwenoude brief gevonden die verstuurd is aan het klooster. Hierin wordt onder andere ook de naam Willibrordus genoemd. Maar welke van deze namen zouden we kunnen linken aan de Liemers?',
      },
      {
        id: 315,
        type: ContentType.ImageQuiz,
        thumbnail: '',
        images: [
          {
            url: 'assets/heilige-in-de-liemers/Opdracht 4/Brief_Werenfridus_05.png',
            title: 'Zoek naar namen',
            text: "Welk van de personen is te linken aan de Liemers?",
          },
        ],
        title: 'In hetzelfde schuitje',
        question: 'Welk van de personen is te linken aan de Liemers?',
        answers: ['testhnk123', 'Werenfridus'],
        text: "Zoek naar namen in de brief.",
      },
      {
        id: 316,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'In hetzelfde schuitje',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 4/Opdracht 4 - Outro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 4/Opdracht 4 - Outro.mov',
        transcription: 'Hmm… Werenfridus? Weet je het informatiebord nog dat we bij de kerk in Zevenaar vonden? Daar hadden ze het ook over een kerk in Westervoort die naar hem vernoemd is. Zou Werenfridus dan de heilige zijn die we zoeken en hoor het relikwie dan thuis in Westervoort? Dat is een optie. Laten we eens kijken wat we nog meer te weten kunnen komen.',
      },
      {
        id: 317,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Dagboekfragment',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 5/A/Opdracht 5A - Intro_v3_kort.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 5/A/Opdracht 5A - Intro_v3_kort.mov',
        transcription: 'Ah, daar zijn jullie weer! Ik heb in de tussentijd niet stilgezeten en heb nog eens goed gezocht in ons depot. Ik vond hier een dagboekfragment uit 1572, geschreven door een inwoner van de Veluwe. Het gaat over een visioen waarin Werenfridus tot hem sprak. Maar... de belangrijkste woorden ontbreken! Deze moeten we dus nog zien te achterhalen.',
      },
      {
        id: 318,
        type: ContentType.WordPuzzle,
        thumbnail: '',
        title: 'Dagboekfragment',
        text: "Gebruik de 'Dagboek'-knop. Maak het eerste ontbrekende woord uit het dagboek door de letters in de juiste vakjes te slepen.",
        words: 'genezen',
        hintImage: 'assets/heilige-in-de-liemers/Opdracht 5/A/Dagboek_04_toggle button.jpg',
        hintButtonText: 'Dagboek',
        hintIcon: 'mdi:eye',
        prefilled: [],
      },
      {
        id: 319,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Dagboekfragment',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 5/A/Opdracht 5A - Outro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 5/A/Opdracht 5A - Outro.mov',
        transcription: '‘Van melaatsheid genezen’ Ja, dat klinkt logisch! Melaatsheid is namelijk een ouderwets woord voor de ziekte lepra. Werenfridus vertelde hem dus in zijn visioen dat hij genezen kon worden van lepra als hij naar een plek toe zou gaan terwijl de bisschop de plechtige mis opdraagt.\n\nWerenfridus verrichtte dus wonderen en werd dus ongetwijfeld gezien als heilige! Hij moet het zijn! Maar over welke plaats hadden ze het?\n\nHmm… In het dagboekfragment hebben ze het over een plek ‘waar de bisschop de plechtige mis opdraagt.’ Een bisschop is een belangrijke priester. Het zal dus een kerk zijn, maar in welke plaats? Westervoort zal het niet zijn, want het woord dat nog ontbreekt in het dagboek, lijkt korter.\n\nKunnen jullie voor me achterhalen welke plaats we zoeken?',
      },
      {
        id: 320,
        type: ContentType.WordPuzzle,
        thumbnail: '',
        title: 'Dagboekfragment',
        text: "Gebruik de 'Dagboek'-knop. Maak het tweede ontbrekende woord uit het dagboek door de letters in de juiste vakjes te slepen.",
        words: 'elst',
        hintImage: 'assets/heilige-in-de-liemers/Opdracht 5/A/Dagboek_04_toggle button.jpg',
        hintButtonText: 'Dagboek',
        hintIcon: 'mdi:eye',
        prefilled: [0],
      },
      {
        id: 321,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Dagboekfragment',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 5/B/Opdracht 5B - Outro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 5/B/Opdracht 5B - Outro.mov',
        transcription: 'Elst, dat is het! Goed gedaan! \n\nHet blijkt dat de zieke op de dag van het feest naar Elst toe ging en met hem ook vele anderen, die van de verschijning gehoord hadden, en wat voorspeld was, gebeurde inderdaad, hij was na de mis genezen van zijn ziekte. \n\nWat een bijzonder verhaal zeg!',
      },
      {
        id: 322,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'De legende',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 6/A/Opdracht 6A - Intro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 6/A/Opdracht 6A - Intro.mov',
        transcription: 'Werenfridus was belangrijk voor Westervoort, maar dus ook voor Elst. In Elst bouwde Werenfridus een kerk (22 bij 12 meter, heel groot voor die tijd) en maakte Elst tot het centrum van het Christendom. Daarna kwam hij naar Westervoort. Hij was een bekendheid! Voor beide plaatsen was hij zo belangrijk dat de mensen na zijn dood ruzie om hem maakten.\n\nIn augustus 760 overleed Werenfridus in Westervoort aan een felle koorts. Toen begon het gedonder. Volgens de inwoners van Elst had Werenfridus aangegeven dat hij in Elst begraven wilde worden, maar daar waren de mensen in Westervoort het niet mee eens. Volgens de legende besloten ze het lichaam van Werenfridus op een onbemand bootje op de Rijn te leggen en het lot te laten bepalen waar zijn laatste rustplaats moest komen. Het bootje voer, tegen de stroom in, naar één van deze twee plaatsen toe...',
      },
      {
        id: 323,
        type: ContentType.AudioQuiz,
        thumbnail: '',
        url: 'assets/heilige-in-de-liemers/Opdracht 6/A/Hoorspel_01_mixdown.mp3',
        title: 'De legende',
        text: 'Luister het hoorspel en probeer te achterhalen waar het bootje is gestrand.',
        question: 'Waar is het bootje gestrand?',
        answers: ['testhnk123', 'Elst'],
      },
      {
        id: 324,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'De legende',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 6/A/Opdracht 6A - Outro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 6/A/Opdracht 6A - Outro.mov',
        transcription: 'Heel bijzonder, tegen de stroom in! Maar… De legende gaat nog verder, want de inwoners van Westervoort waren het er nog steeds niet mee eens. \n\nDaarom werd er een tweede oordeel gevraagd. Het lichaam werd op een ossenkar gelegd, die getrokken werd door jonge ossen die nog nooit eerder een kar getrokken hadden. Om te kijken of de ossen naar Westervoort of Elst zouden lopen. Ik ben benieuwd! \n\nGelukkig kwam ik via-via een 3D-model van het graf van Werenfridus tegen. Laten we het eens bestuderen en hopelijk vertelt het ons waar de ossen Werenfridus naar toe hebben getrokken.',
      },
      {
        id: 325,
        type: ContentType.ModelQuiz,
        thumbnail: '',
        title: 'De legende',
        question: 'Waar trokken de ossen de kar heen?',
        answers: ['testhnk123', 'Elst'],
        text: 'Bekijk het 3D-model. Hebben de ossen de kar naar Westervoort of Elst getrokken?',
        model: 'assets/heilige-in-de-liemers/Opdracht 6/B/sarcofaag_web3.glb',
        poster: 'assets/heilige-in-de-liemers/Opdracht 6/B/sarcofaag.png',
        minOrbitOffset: 50,
      },
      {
        id: 326,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'De legende',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 6/B/Opdracht 6B - Outro_v2.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 6/B/Opdracht 6B - Outro_v2.mov',
        transcription: 'De Elstenaren wonnen de strijd en Werenfridus werd begraven in de kerk in Elst. De sarcofaag waar hij in lag, staat daar nog steeds onder de kerk. In 925 werd Werenfridus officieel heilig verklaard en de kerk in Elst bleef nog eeuwenlang een bedevaartsoord, waar mensen naartoe kwamen in de hoop genezen te worden (van reuma en jicht). In de loop der tijd zijn de relikwieën kwijtgeraakt. Een tijd lang was er alleen nog een klein stukje bot van Werenfridus bewaard gebleven, maar ook dat was ook nergens meer vindbaar.\n\nTot op heden, want dat is dus wat er in het mysterieuze pakketje zit! Het stukje bot is een relikwie van de heilige Werenfridus! Goed gedaan jongens! Ontzettend bedankt!\n\nMaar… voordat we het relikwie doorsturen naar Elst, waar het thuishoort, heb ik nog een leuk weetje voor jullie.',
      },
      {
        id: 327,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Wapen van Overbetuwe',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 7/Opdracht 7 - Intro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 7/Opdracht 7 - Intro.mov',
        transcription: 'Dit kwam ik tijdens ons onderzoek naar Werenfridus tegen. Het wapen van gemeente Overbetuwe, waar Elst tegenwoordig onderdeel van is. Puzzel hem maar eens in elkaar.',
      },
      {
        id: 328,
        type: ContentType.Puzzle,
        thumbnail: '',
        url: 'assets/heilige-in-de-liemers/Opdracht 7/Overbetuwe_02.jpg',
        title: 'Wapen van Overbetuwe',
        text: 'Los de puzzel van het wapen op.',
      },
      {
        id: 329,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Wapen van Overbetuwe',
        videoUrl: 'assets/heilige-in-de-liemers/Opdracht 7/Opdracht 7 - Outro.webm',
        videoAppleUrl: 'assets/heilige-in-de-liemers/Opdracht 7/Opdracht 7 - Outro.mov',
        transcription: 'Haha, ja dat zie je goed! Dat is het bootje met de doodskist van Werenfridus waar ze het in de legende over hebben. Dit geeft wel aan hoe belangrijk Werenfridus was voor Elst.',
      },
    ]
  },

  // ADELA VAN HAMALAND ----------------------------------------------------------------------

  {
    id: 4,
    name: 'Macht in de Liemers',
    cover: 'assets/hamaland//Hoofdstukkaart_Adela van Hamaland_01.png',
    category: 'Monniken en Ridders',
    color: '#F9A03F',
    description: 'In de middeleeuwen heette onze streek Hamaland. Het was het oudste en rijkste graafschap van de lage landen. Er stond een machtige burcht op de Eltenberg, die toen bij de Liemers hoorde. Deze burcht was van de steenrijke familie Wichman. Zij waren de baas over het gebied dat toen Hamaland heette. De oudste dochter heette Adela. Ze was een spraakmakend figuur. Zo spraakmakend dat op een dag de keizer zich tegen haar keerde en ze heeft moeten vluchten. Maar waarom?',
    content: [
      {
        id: 1,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Het liemers museum',
        url: 'assets/hamaland/Adela Van Hamaland_Intro_01_HB.mp4',
        videoThumbnail: 'assets/hamaland/Adela Van Hamaland_Intro_01_HB.jpg',
        text: 'Het Liemers Museum heeft jullie hulp nodig bij het oplossen van een mysterie rondom een vrouw genaamd Adela van Hamaland. Kijk je mee?',
      },
      {
        id: 2,
        type: ContentType.MultiGrid,
        thumbnail: '',
        url: [],
        contentId: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
        title: 'Macht in de Liemers',
        text: 'Rond alle opdrachten af om het mysterie van Adela van Hameland op te lossen.',
      },
      {
        id: 3,
        type: ContentType.RouteCompletePreview,
        thumbnail: '',
        title: 'Mysterie opgelost',
        text: '',
        buttonText: 'Bekijk videoboodschap',
        completeImage: 'assets/heilige-in-de-liemers/completed-monikken-en-ridders.png'
      },
      {
        id: 4,
        type: ContentType.RouteCompleteVideo,
        thumbnail: '',
        title: 'Mysterie opgelost',
        text: '',
        url: 'assets/hamaland/Adela Van Hamaland_Outro_02_HB.mp4',
        videoThumbnail: 'assets/hamaland/Adela Van Hamaland_Outro_01_HB.jpg',
      },
      {
        id: 5,
        type: ContentType.Assignment,
        thumbnail: 'assets/hamaland/Overview thumbnails/Thumbnail - Opdracht 1.jpg',
        title: 'Hamaland',
        contentId: [401, 402, 403, 404, 405],
        require: [],
      },
      {
        id: 6,
        type: ContentType.Assignment,
        thumbnail: 'assets/hamaland/Overview thumbnails/Thumbnail - Opdracht 2.jpg',
        title: 'Rijkdom',
        contentId: [406, 407, 408, 409, 410],
        require: [5],
      },
      {
        id: 7,
        type: ContentType.Assignment,
        thumbnail: 'assets/hamaland/Overview thumbnails/Thumbnail - Opdracht 3.jpg',
        title: 'Stamboom',
        contentId: [411, 412, 413],
        require: [6],
      },
      {
        id: 8,
        type: ContentType.Assignment,
        thumbnail: 'assets/hamaland/Overview thumbnails/Thumbnail - Opdracht 4.jpg',
        title: 'Erfenis',
        contentId: [414, 415, 416, 417],
        require: [7],
      },
      {
        id: 9,
        type: ContentType.Assignment,
        thumbnail: 'assets/hamaland/Overview thumbnails/Thumbnail - Opdracht 5.jpg',
        title: 'Strijd',
        contentId: [418, 419, 420, 421, 422],
        require: [8],
      },
      {
        id: 10,
        type: ContentType.Assignment,
        thumbnail: 'assets/hamaland/Overview thumbnails/Thumbnail - Opdracht 6.jpg',
        title: 'Kronieken',
        contentId: [423, 424, 425],
        require: [9],
      },
      {
        id: 11,
        type: ContentType.Assignment,
        thumbnail: 'assets/hamaland/Overview thumbnails/Thumbnail - Opdracht 7.jpg',
        title: 'Munt',
        contentId: [426, 427, 428, 429, 430],
        require: [10],
      },
      {
        id: 12,
        type: ContentType.Assignment,
        thumbnail: 'assets/hamaland/Overview thumbnails/Thumbnail - Opdracht 8.jpg',
        title: 'Invloedrijke functie',
        contentId: [431, 432, 433, 434, 435],
        require: [11],
      },
      {
        id: 13,
        type: ContentType.Assignment,
        thumbnail: 'assets/hamaland/Overview thumbnails/Thumbnail - Opdracht 9.jpg',
        title: 'Brief keizer',
        contentId: [436, 437, 438, 439, 440],
        require: [12],
      },
      {
        id: 14,
        type: ContentType.Assignment,
        thumbnail: 'assets/hamaland/Overview thumbnails/Thumbnail - Opdracht 10.jpg',
        title: 'Conclusie',
        contentId: [441, 442, 443],
        require: [13],
      },
      {
        id: 401,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Hamaland',
        videoUrl: 'assets/hamaland/Opdracht 1/A/Opdracht 1A - Intro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 1/A/Opdracht 1A - Intro.mov',
        transcription: 'In de middeleeuwen heette onze streek dus Hamaland. Dus laten we daar eens beginnen. Nu heb ik een kaart van Hamaland kunnen vinden, alleen bestaat deze uit delen. Kunnen jullie helpen hem in elkaar te puzzelen?',
      },
      {
        id: 402,
        type: ContentType.Puzzle,
        thumbnail: '',
        url: 'assets/hamaland/Opdracht 1/A/opdracht 1_map_1024_torn-edges.png',
        title: 'Hamaland',
        text: 'Help ons de kaart weer in elkaar te puzzelen.',
      },
      {
        id: 403,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Hamaland',
        videoUrl: 'assets/hamaland/Opdracht 1/A/Opdracht 1A - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 1/A/Opdracht 1A - Outro.mov',
        transcription: 'Top! Goed gedaan! Adela was de oudste dochter van de steenrijke familie Wichman. Zij waren de baas over Hamaland. Aan het hoofd van de familie stond graaf Wichman IV. Vanuit zijn burcht op de ‘Eltenberg’ bestuurde hij zijn graafschap. Die burcht was enorm indrukwekkend en kon je al vanuit de verte zien liggen. Laten we eens kijken of we op de kaart kunnen zien in welke plaats de burcht stond.',
      },
      {
        id: 404,
        type: ContentType.ImageQuiz,
        thumbnail: '',
        images: [
          {
            url: 'assets/hamaland/Opdracht 1/A/opdracht 1_map_4096_torn-edges_02.png',
            title: 'Hoe heette de plaats waar de burcht stond?',
            text: "Zoom in om de kaart beter te bekijken.",
          },
        ],
        title: 'Hamaland',
        question: 'Hoe heette de plaats waar de burcht stond?',
        answers: ['testhnk123', 'Elten', 'Hoog-Elten', 'Hoog Elten'],
        text: "De burcht van de familie Wichman stond op de top van de ‘Eltenberg’. Bekijk de kaart goed. In welke plaats zou dat kunnen liggen?",
        hintContent: "Zoek naar een heuvelachtig gebied op de kaart. Je herkent het aan de donkergroene kleur.\nDe burcht stond op de ‘Eltenberg’ die is vernoemd naar de plaats waar hij staat.",
        hintDelay: 60000,
      },
      {
        id: 405,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Hamaland',
        videoUrl: 'assets/hamaland/Opdracht 1/B/Opdracht 1B - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 1/B/Opdracht 1B - Outro.mov',
        transcription: 'Ja! De burcht op de ‘Eltenberg’ stond helemaal onder in het puntje van Hamaland in Elten. Vlak naast de Rijn. Laten we nu eens kijken hoe het kon dat de familie Wichman zo rijk was.',
      },
      {
        id: 406,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Rijkdom',
        videoUrl: 'assets/hamaland/Opdracht 2/A/Opdracht 2A - Intro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 2/A/Opdracht 2A - Intro.mov',
        transcription: 'Hamaland was het oudste en rijkste graafschap van de lage landen. Waarom het zo rijk was? Omdat het gebied heel veel grondstoffen had. Met name ijzer. Ik heb een video gevonden waarin hierover meer wordt verteld. Laten we eens kijken.',
      },
      {
        id: 407,
        type: ContentType.VideoQuiz,
        thumbnail: '',
        title: 'Rijkdom',
        url: 'assets/hamaland/Opdracht 2/A/Opdracht 2A_RF23.mp4',
        videoThumbnail: 'assets/hamaland/Opdracht 2/A/Opdracht 2A - Video Thumbnail.jpg',
        text: 'Bekijk de video om erachter te komen hoeveel ijzer er in Hamaland werd gemaakt in de vroege middeleeuwen.',
        question: 'Hoeveel kilo ijzer werd er naar schatting gemaakt in Hamaland?',
        hintContent: "Bekijk en luister goed naar het filmpje!",
        hintDelay: -1,
        answers: ['testhnk123', '55000000', '55.000.000', '55 miljoen', '55miljoen'],
      },
      {
        id: 408,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Rijkdom',
        videoUrl: 'assets/hamaland/Opdracht 2/A/Opdracht 2A - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 2/A/Opdracht 2A - Outro.mov',
        transcription: 'Zo hé! 55 miljoen kilo?! En zonder machines te gebruiken, hè? Want die hadden ze toen nog niet. Ik ben wel benieuwd hoeveel al dat ijzer waard is. Misschien dat we zo een indruk kunnen krijgen van hoe rijk de familie Wichman nou écht was. Kunnen jullie dit voor me uitrekenen?',
      },
      {
        id: 409,
        type: ContentType.ImageQuiz,
        thumbnail: '',
        images: [
          {
            url: 'assets/hamaland/Opdracht 2/B/Weegschaal.png',
            title: '',
            text: "",
          },
        ],
        title: 'Rijkdom',
        question: 'Hoeveel euro is het ijzer waard?',
        answers: ['testhnk123', '11.000.000', '11000000', '11 miljoen', '11miljoen'],
        text: "Er werd 55.000.000 kilo ijzer gemaakt. Één kilo ijzer kost tegenwoordig zo'n €0,20.",
        hintContent: "1 euro is 5 kilo ijzer. Dus hoeveel is 55.000.000 gedeeld door 5?",
        hintDelay: 30000,
      },
      {
        id: 410,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Rijkdom',
        videoUrl: 'assets/hamaland/Opdracht 2/B/Opdracht 2B - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 2/B/Opdracht 2B - Outro.mov',
        transcription: 'Ja, 11 miljoen euro! Dat is veel geld! En vroeger was dat zelfs nog veel meer waard. Want hoe verder je teruggaat in de tijd des te meer het geld waard was. Om je een idee te geven, in de middeleeuwen was hetzelfde bedrag omgerekend maar liefst ruim 2,35 miljard euro waard. Je kan dus wel zeggen dat de familie Wichman echt steenrijk was!',
      },
      {
        id: 411,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Stamboom',
        videoUrl: 'assets/hamaland/Opdracht 3/Opdracht 3 - Intro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 3/Opdracht 3 - Intro.mov',
        transcription: 'Om beter te begrijpen wie wie is binnen de familie Wichman, ben ik op zoek gegaan naar een stamboom. En gelukkig. Ik heb hem kunnen vinden! Alleen is hij nog niet helemaal compleet. Zouden jullie kunnen helpen de stamboom compleet te maken?',
      },
      {
        id: 412,
        type: ContentType.DragPuzzle,
        thumbnail: '',
        title: 'Stamboom',
        text: 'Sleep de namen op de juiste plek in de stamboom.',
        textFullscreen: 'Sleep de namen op de juiste plek in de stamboom.',
        background: 'assets/hamaland/Opdracht 3/Stamboom_BG.png',
        backgroundCompleted: 'assets/hamaland/Opdracht 3/Stamboom.png',
        completionText: 'Je hebt de stamboom voltooid! Goed bezig!',
        hintContent: "Immed was de man van Adela. \nWichman Jr. was de jongste van 3 kinderen.",
        hintDelay: 30000,
        targets: [
          { id: 0, x: 75.3, y: 31.8, sizeX: 18.8, sizeY: 8.1, image: 'assets/hamaland/Opdracht 3/Wichman Jr.png' },
          { id: 1, x: 26.4, y: 31.8, sizeX: 18.8, sizeY: 8.1, image: 'assets/hamaland/Opdracht 3/Immed.png' },
          { id: 2, x: 6.1, y: 48, sizeX: 18.8, sizeY: 8.1, image: 'assets/hamaland/Opdracht 3/Diederik.png' },
          { id: 3, x: 50.8, y: 31.8, sizeX: 18.8, sizeY: 8.1, image: 'assets/hamaland/Opdracht 3/Liutgardis.png' },
        ],
      },
      {
        id: 413,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Stamboom',
        videoUrl: 'assets/hamaland/Opdracht 3/Opdracht 3 - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 3/Opdracht 3 - Outro.mov',
        transcription: 'Ah, dat maakt alles duidelijker! Graaf Wichman was de vader en Luitgard de moeder. Van hun 3 kinderen was Adela dus de oudste, haar zusje Liutgardis de middelste en haar broertje Wichman jr. de jongste. Adela was getrouwd met Immed, met wie ze meerdere kinderen kreeg. De naam van het oudste kind, die moet je even onthouden. Dat was Diederik.',
      },
      {
        id: 414,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Erfenis',
        videoUrl: 'assets/hamaland/Opdracht 4/Opdracht 4 - Intro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 4/Opdracht 4 - Intro.mov',
        transcription: 'Toen kort na elkaar graaf Wichman zijn vrouw én zijn jonge zoontje plotseling stierven, was hij radeloos. Hij had alleen nog maar dochters over. En geen zoon meer! En weet je wat het stomme is? In die tijd mocht een opvolger geen vrouw zijn. Gek, he? Dus hij moest iets slims verzinnen. Zijn trotse burcht op de Eltenberg veranderde hij toen maar in een klooster voor vrouwen. Hij noemde dat het St. Vitusklooster. Maar waarom bouwde hij de burcht om tot klooster? Ik heb een video gevonden waarin dat wordt uitgelegd.',
      },
      {
        id: 415,
        type: ContentType.Video,
        thumbnail: '',
        title: 'Erfenis',
        url: 'assets/hamaland/Opdracht 4/Opdracht 4_RF23.mp4',
        videoThumbnail: 'assets/hamaland/Opdracht 4/Opdracht 4 - Video Thumbnail.jpg',
        text: 'Waarom bouwde Graaf Wichman zijn burcht om tot klooster?',
        blockSkip: true,
      },
      {
        id: 416,
        type: ContentType.Quiz,
        thumbnail: '',
        title: 'Erfenis',
        question: 'Waarom bouwde Graaf Wichman zijn burcht om tot klooster? Het antwoord heb je gezien in de video.',
        correctIndex: 0,
        options: [
          'Om zijn bezit veilig te stellen',
          'Om troost te zoeken in God',
          'Om zijn verdriet te vergeten',
          'Omdat zijn dochter Liutgardis dat wilde',
        ],
      },
      {
        id: 417,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Erfenis',
        videoUrl: 'assets/hamaland/Opdracht 4/Opdracht 4 - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 4/Opdracht 4 - Outro.mov',
        transcription: 'Ja, inderdaad! Om zijn bezit veilig te stellen. Zodat als hij zou komen te overlijden de rijkdom van Hamaland toch in bezit van de familie zou blijven. Slim bedacht, toch?',
      },
      {
        id: 418,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Strijd',
        videoUrl: 'assets/hamaland/Opdracht 5/A/Opdracht 5A - Intro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 5/A/Opdracht 5A - Intro.mov',
        transcription: 'Toen vader Wichman overleed ging een derde van zijn bezittingen naar het klooster, een derde naar Liutgardis en het resterende deel naar Adela. Adela was het hier niet mee eens. Haar zusje had als baas van het klooster nu eigenlijk meer gekregen dan zij. Dit vond ze echt oneerlijk en was er woest over. Het gerucht gaat dat Adela het er niet bij liet zitten. Ze zou iemand hebben ingeschakeld om het voor haar “op te lossen…”, Maar wie was die persoon? Ik heb in ons depot delen van een tekening gevonden waarop Adela samen met deze persoon zou staan. Kunnen jullie hem in elkaar puzzelen?',
      },
      {
        id: 419,
        type: ContentType.Puzzle,
        thumbnail: '',
        url: 'assets/hamaland/Opdracht 5/A/Adela en Balderik.png',
        title: 'Strijd',
        text: 'Help ons de tekening weer in elkaar te puzzelen.',
      },
      {
        id: 420,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Strijd',
        videoUrl: 'assets/hamaland/Opdracht 5/A/Opdracht 5A - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 5/A/Opdracht 5A - Outro.mov',
        transcription: 'Ah, er zijn een paar letters weggevallen. Maar op basis van de namen uit die tijd, weten we welke letters er waarschijnlijk ontbreken. De volgorde weten we alleen niet. Kunnen jullie ontbrekende letters in de juiste vakjes slepen om zo zijn naam te achterhalen?',
      },
      {
        id: 421,
        type: ContentType.WordPuzzle,
        thumbnail: '',
        title: 'Strijd',
        text: "Sleep de letters naar de juiste plek om de naam compleet te maken.",
        words: 'Balderik',
        hintImage: '',
        prefilled: [1, 2, 3, 7],
      },
      {
        id: 422,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Strijd',
        videoUrl: 'assets/hamaland/Opdracht 5/B/Opdracht 5B - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 5/B/Opdracht 5B - Outro.mov',
        transcription: 'Balderik? Hmm… hij was degene die in 995 het klooster van Liutgardis, (het zusje van Adela) heeft bezet. Liutgardis kwam hierbij om het leven en Adela was degene die Balderik had ingeschakeld. Zou Adela hem dan opdracht hebben gegeven om haar bloedeigen zus te vermoorden?! Heeft Balderik dit op eigen houtje gedaan? En hoe is Liutgardis vermoord?! Aaahh!! Wat een juicy gossip!! Dat moeten we te weten zien te komen!',
      },
      {
        id: 423,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Kronieken',
        videoUrl: 'assets/hamaland/Opdracht 6/Opdracht 6 - Intro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 6/Opdracht 6 - Intro.mov',
        transcription: 'Ik realiseer me net dat ik de naam Balderik herkende uit één van de belangrijkste bronnen over de gebeurtenissen in Hamaland, een boek geschreven door een monnik die in die tijd leefde. Ik heb een stukje uit dit boek gevonden. Misschien kunnen we daarmee achterhalen hoe Luitgardis is overleden. Laten we kijken.',
      },
      {
        id: 424,
        type: ContentType.ImageQuiz,
        thumbnail: '',
        images: [
          {
            url: 'assets/hamaland/Opdracht 6/Diversitate temporum_01.png',
            title: 'Hoe is Liutgardis volgens de monnik om het leven gebracht?',
            text: "",
          },
        ],
        title: 'Kronieken',
        question: 'Hoe is Liutgardis volgens de monnik vermoord?',
        answers: ['testhnk123', 'Vergiftigd', 'vergiftigt', 'vergif', 'gif', 'vergiftigen'],
        text: "Bekijk de tekst goed. Hoe is Liutgardis volgens de monnik om het leven gebracht?",
        hintContent: ""
      },
      {
        id: 425,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Kronieken',
        videoUrl: 'assets/hamaland/Opdracht 6/Opdracht 6 - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 6/Opdracht 6 - Outro.mov',
        transcription: 'Wow, dat meen je niet?! Heeft Adela echt haar eigen zusje laten vergiftigen?! Ik kan het haast niet geloven. Of heeft de monnik Adela hiervan beschuldigd zonder er bewijs voor te hebben? Zijn verhalen waren namelijk niet altijd op keiharde feiten gebaseerd. Naja, echt zeker kunnen we het dus niet weten. Hoewel het aannemelijk is dat de mensen in die tijd het verhaal van de monnik wél geloofden. Laten we snel verder zoeken.',
      },
      {
        id: 426,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Munt',
        videoUrl: 'assets/hamaland/Opdracht 7/A/Opdracht 7A - Intro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 7/A/Opdracht 7A - Intro.mov',
        transcription: 'Toen Adela’s man Immed overleed, werd hun zoon Diederik automatisch de baas van Hamaland. Weet je nog uit de stamboom? Hij was alleen veel te jong, dus waarschijnlijk regeerde Adela namens hem. Inmiddels had de Keizer ook bepaald dat Adela niet een derde maar de helft van het familiekapitaal moest krijgen. En toen voelde zij zich oppermachtig. In het verhaal van de monnik staat dat ze zelfs een eigen munt liet maken. Iets wat in die tijd ongehoord was. Een vrouw met een eigen munt? Dat was nog nooit voorgekomen. Ik heb een 3D replica van een Hamalandse munt uit het jaar 1000 gevonden. Kunnen jullie achterhalen of deze munt van Adela is?'
      },
      {
        id: 427,
        type: ContentType.ModelQuiz,
        thumbnail: '',
        title: 'Munt',
        question: 'Welke tekst staat er op de munt afgebeeld?',
        answers: ['testhnk123', 'ADELA COMETISSA', 'ADELACOMETISSA', 'ADELA+COMETISSA+', 'ADELA+COMETISSA', 'ADELA + COMETISSA', 'COMETISSA ADELA', 'COMETISSAADELA', 'COMETISSA+ADELA+'],
        text: 'Bekijk de munt en om er achter te komen of dit de munt van Adela is.',
        model: 'assets/hamaland/Opdracht 7/A/Adela_Munt_01.glb',
        poster: 'assets/hamaland/Opdracht 7/A/adela_munt.png',
      },
      {
        id: 428,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Munt',
        videoUrl: 'assets/hamaland/Opdracht 7/A/Opdracht 7A - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 7/A/Opdracht 7A - Outro.mov',
        transcription: "Ja! Goed gevonden! ‘Adela Cometissa’ is Latijn voor ‘Gravin Adela’! Het klopte dus wel wat de monnik schreef. Ze had een eigen munt. Maar hoe heeft ze dat voor elkaar gekregen? Alleen de keizer en de zogenaamde Graven van Hamaland hadden het recht op een eigen munt. Wie zou Adela het recht hebben gegeven om haar eigen munt te laten maken? Ik heb een lijst met namen van de graven van Hamaland van 936 tot 1018 gevonden. Kijk je mee?"
      },
      {
        id: 429,
        type: ContentType.PictureFinder,
        thumbnail: '',
        title: 'Munt',
        text: 'Wie heeft Adela het recht gegeven om haar eigen munt te maken? Kies de juiste persoon en controleer je antwoord.',
        correctItem: 2,
        items: [
          { name: 'Wichman IV (graaf van 936 tot 966)', image: 'assets/hamaland/Opdracht 7/B/Wichman.jpg' },
          { name: 'Immed IV (graaf tot 995)', image: 'assets/hamaland/Opdracht 7/B/Immed.jpg' },
          { name: 'Diederik (graaf van 995 tot 1017)', image: 'assets/hamaland/Opdracht 7/B/Diederik.jpg' },
          { name: 'Balderik (graaf van 1017 tot 1018)', image: 'assets/hamaland/Opdracht 7/B/Balderik.jpg' },
        ],
        hintIcon: 'mdi:eye',
        hintContent: 'De munt werd geslagen rond het jaar 1000.',
      },
      {
        id: 430,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Munt',
        videoUrl: 'assets/hamaland/Opdracht 7/B/Opdracht 7B - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 7/B/Opdracht 7B - Outro.mov',
        transcription: 'Ja, dat lijkt me aannemelijk. Adela zal als moeder van de jonge graaf ongetwijfeld invloed gehad hebben op zijn besluiten.'
      },
      {
        id: 431,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Invloedrijke functie',
        videoUrl: 'assets/hamaland/Opdracht 8/A/Opdracht 8A - Intro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 8/A/Opdracht 8A - Intro.mov',
        transcription: "Nu Adela de helft van het gigantische familiekapitaal had gekregen, zou je verwachten dat ze daar wel tevreden mee zou zijn. Nou, vergeet het maar. De Keizer had een belangrijke vacature open staan aan zijn hof. Adela, die ondertussen was getrouwd met Balderik zag dat baantje wel zitten voor haar man. Maar… er was nog een kaper op de kust. Namelijk Wichman van Vreden. Toevallig ook een Wichman, maar geen familie van Adela. Balderik wist echter de baan binnen te slepen. Zo ontstond een strijd tussen beide vechtjassen. Over en weer vielen ze elkaars burchten aan, waarbij veel doden vielen. Als er zo hevig om werd gevochten, moet het een invloedrijke functie zijn geweest. Ik heb een lijst opgesteld met belangrijke functies. Welke functie kan het volgens jullie zijn geweest?"
      },
      {
        id: 432,
        type: ContentType.PictureFinder,
        thumbnail: '',
        title: 'Invloedrijke functie',
        text: 'Gebruik de ‘Weetjes’-knop. Om welke functie streden Balderik en Wichman van Vreden?',
        correctItem: 3,
        items: [
          { name: 'Graaf', image: 'assets/hamaland/Opdracht 8/A/Graaf.jpg' },
          { name: 'Ridder', image: 'assets/hamaland/Opdracht 8/A/Ridder.jpg' },
          { name: 'Bisschop (geestelijk leider)', image: 'assets/hamaland/Opdracht 8/A/Bisschop.jpg' },
          { name: 'Prefect (leider van de krijgsmacht)', image: 'assets/hamaland/Opdracht 8/A/Prefect.jpg' },
          { name: 'Premier', image: 'assets/hamaland/Opdracht 8/A/Premier.jpg' },
        ],
        hintImage: 'assets/hamaland/Opdracht 8/A/Invloedrijke dunctie_Toggle hint.png',
        hintButtonText: 'Weetjes',
        hintIcon: 'mdi:eye',
        hintContent: 'Balderik was al een graaf en was geen geestelijke. Een ridder is van mindere stand dan een graaf.',
        hintDelay: -1,
      },
      {
        id: 433,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Invloedrijke functie',
        videoUrl: 'assets/hamaland/Opdracht 8/A/Opdracht 8A - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 8/A/Opdracht 8A - Outro.mov',
        transcription: 'Ja, perfect! Een perf… pre… *zucht*... Pre-fect. Dat wilde ik zeggen. Een prefect is iemand die de leiding heeft over een krijgsmacht, in dit geval om de gehele kust van de Noordzee te verdedigen tegen aanvallen van de Noormannen. Als prefect had je meer macht dan een graaf. Dus dat de mannen hier met elkaar om streden, zou goed kunnen kloppen. Toen uiteindelijk de keizer eiste dat de twee het bij zouden leggen, werd de strijdbijl begraven. Balderik en Adela nodigden Wichman van Vreden uit voor een ‘vriendschappelijk’ diner om alles weer goed te maken. Het diner liep alleen anders af dan verwacht. Op de terugweg naar huis werd hij namelijk vermoord! Maar wie zat er achter die verraderlijke moord? Dat wordt hopelijk duidelijk in de video die ik voor jullie klaar heb staan. Kijk je mee?'
      },
      {
        id: 434,
        type: ContentType.VideoQuiz,
        thumbnail: '',
        title: 'Invloedrijke functie',
        url: 'assets/hamaland/Opdracht 8/B/Opdracht 8B_RF23.mp4',
        videoThumbnail: 'assets/hamaland/Opdracht 8/B/Opdracht 8B - Video Thumbnail.jpg',
        text: 'Bekijk de video om te achterhalen wie er achter de moord op Wichman van Vreden zat.',
        question: 'Wie zat er achter de moord op Wichman van Vreden?',
        hintContent: "Bekijk en luister goed naar het filmpje!",
        hintDelay: -1,
        answers: ['testhnk123', 'Adela', 'Adela van Hamaland'],
      },
      {
        id: 435,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Invloedrijke functie',
        videoUrl: 'assets/hamaland/Opdracht 8/B/Opdracht 8B - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 8/B/Opdracht 8B - Outro.mov',
        transcription: 'Tja, iedereen dacht dus dat Balderik achter de moord zat. Maar volgens deze video was het toch echt Adela die de opdracht had gegeven aan de ridder en zijn knecht. Wie het ook was, de keizer zal vast niet blij zijn geweest als hij het te horen zou hebben gekregen. Hmm… '
      },
      {
        id: 436,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Brief keizer',
        videoUrl: 'assets/hamaland/Opdracht 9/A/Opdracht 9A - Intro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 9/A/Opdracht 9A - Intro.mov',
        transcription: 'Ik heb in de tussentijd nog wat research gedaan en las over een brief gericht aan de keizer, geschreven door de bisschop van Utrecht. De keizer ontving in die tijd regelmatig brieven van bisschoppen en graven. Zo bleef hij op de hoogte van de situatie in zijn grote rijk. En nou moet je weten dat de bisschop een goede vriend was van Wichman van Vreden. Ik heb een kopie van de brief kunnen bemachtigen, maar ik denk niet dat alles goed te vertalen is. Laten we eens kijken of we er achter kunnen  komen waar de brief over gaat.'
      },
      {
        id: 437,
        type: ContentType.Tap2,
        thumbnail: '',
        title: 'Brief keizer',
        text: 'Vertaal de brief en probeer te achterhalen waar hij over gaat.',
        textFullscreen: 'Tik op de rode delen om ze te vertalen.',
        completionText: "Top! Kunnen we nu achterhalen waar de brief over gaat?",
        background: 'assets/hamaland/Opdracht 9/A/Brief aan de keizer_Onvertaald_02.png',
        targets: [
          { id: 0, x: 42.6, y: 19.85, sizeX: 14.7, sizeY: 5, image: 'assets/hamaland/Opdracht 9/A/vertaling 1.jpg' },
          { id: 1, x: 19.3, y: 29.1, sizeX: 36.2, sizeY: 5, image: 'assets/hamaland/Opdracht 9/A/vertaling 2.jpg' },
          { id: 2, x: 27.2, y: 33.7, sizeX: 30.2, sizeY: 5, image: 'assets/hamaland/Opdracht 9/A/vertaling 3.jpg' },
          { id: 3, x: 24.3, y: 38.4, sizeX: 16.7, sizeY: 5, image: 'assets/hamaland/Opdracht 9/A/vertaling 4.jpg' },
          { id: 4, x: 17.7, y: 42.8, sizeX: 13.1, sizeY: 5, image: 'assets/hamaland/Opdracht 9/A/vertaling 5.jpg' },
          { id: 5, x: 33.2, y: 43, sizeX: 18.8, sizeY: 5, image: 'assets/hamaland/Opdracht 9/A/vertaling 6.jpg' },
          { id: 6, x: 13.1, y: 52, sizeX: 34.5, sizeY: 5, image: 'assets/hamaland/Opdracht 9/A/vertaling 7.jpg' },
          { id: 7, x: 55.4, y: 60.8, sizeX: 32, sizeY: 5, image: 'assets/hamaland/Opdracht 9/A/vertaling 8.jpg' },
        ],
      },
      {
        id: 438,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Brief keizer',
        videoUrl: 'assets/hamaland/Opdracht 9/A/Opdracht 9A - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 9/A/Opdracht 9A - Outro.mov',
        transcription: 'Top! Goed gedaan, laten we nu eens kijken of we hiermee kunnen achterhalen waar de brief over gaat.',
        forceAllowBack: true,
      },
      {
        id: 439,
        type: ContentType.Quiz,
        thumbnail: '',
        title: 'Brief keizer',
        question: 'Waar gaat de brief over?',
        hintImage: 'assets/hamaland/Opdracht 9/B/Brief aan de keizer_Vertaald.png',
        hintButtonText: 'Brief',
        hintIcon: 'mdi:eye',
        correctIndex: 1,
        options: [
          'De bouw van een kerk',
          'De moord op Wichman van Vreden',
          'De munt van Adela',
          'Het indrukwekkende uitzicht van Upladen',
        ],
      },
      {
        id: 440,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Brief keizer',
        videoUrl: 'assets/hamaland/Opdracht 9/B/Opdracht 9B - Outro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 9/B/Opdracht 9B - Outro.mov',
        transcription: 'Ja, dat klinkt logisch! Toen de keizer deze brief onder ogen kreeg, is hij vast tot actie overgegaan. Hij had tenslotte geëist dat de twee het bij zouden leggen. Hij wilde dat de rust weer terugkeerde in Hamaland en stuurde een leger naar de burcht Upladen, de motte van Balderik en Adela, die we nu Motte Montferland noemen. Balderik werd bang en vluchtte naar Duitsland. Adela bleef met haar personeel de burcht verdedigen, maar moest het uiteindelijk opgeven. Ook zij mocht naar Duitsland vertrekken. Maar al hun bezittingen werd hen afgenomen. Hun burcht werd in de fik gestoken en brandde tot de grond af.\n\nJullie hebben het antwoord gevonden! Het was dus de moord op Wichman van Vreden die ervoor gezorgd heeft dat Adela moest ‘vluchten’. Wat een verhaal zeg! Maar goed, hoe is het daarna afgelopen met Adela? Ik ben er wel benieuwd naar….'
      },
      {
        id: 441,
        type: ContentType.Guide,
        thumbnail: '',
        title: 'Conclusie',
        videoUrl: 'assets/hamaland/Opdracht 10/Opdracht 10 - Intro.webm',
        videoAppleUrl: 'assets/hamaland/Opdracht 10/Opdracht 10 - Intro.mov',
        transcription: 'Adela stierf uiteindelijk rond 1025 in Keulen. In die stad werd ze bij de Dom (de grote kathedraal) begraven.\n\nWat is jullie oordeel nu over Adela? Had de keizer gelijk? Was Adela inderdaad een doortrapte moordenaar? Of was Adela een vrouw die vocht voor haar rechten in een wereld waar mannen de baas waren? Zouden haar daden als ‘normaal’ worden beschouwd als ze een man zou zijn geweest?'
      },
      {
        id: 442,
        type: ContentType.Quiz,
        thumbnail: '',
        title: 'Conclusie',
        question: 'Wat is jullie oordeel over Adela?',
        correctIndex: -1,
        options: [
          'Adela was een koelbloedige moordenaar',
          'Adela liet zich niet domineren door mannen',
        ],
      },
    ]
  }

];

// ----------------------------------------------------------------------

export const route = (index) => routes.find(r => r.id === parseInt(index, 10));
