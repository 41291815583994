const navConfig = [
  {
    title: 'home',
    path: '/home',
    children: [],
  },
  {
    title: 'instellingen',
    path: '/settings',
    children: [{
      title: 'toestemmingen',
      path: '/settings/permissions',
    }]
  }
];

export default navConfig;
