
// @mui
import { Box, Stack, Container, Button, IconButton } from '@mui/material';

import { useRef, useState, useEffect, useImperativeHandle } from "react";

import Lightbox from "yet-another-react-lightbox";
// eslint-disable-next-line import/no-unresolved
import Captions from "yet-another-react-lightbox/plugins/captions";


// eslint-disable-next-line import/no-unresolved
import Zoom from "yet-another-react-lightbox/plugins/zoom";

// eslint-disable-next-line import/no-unresolved
import "yet-another-react-lightbox/styles.css";
// eslint-disable-next-line import/no-unresolved
import "yet-another-react-lightbox/plugins/captions.css";

import {
  SnapList,
  SnapItem,
  // useVisibleElements,
  useScroll,
} from 'react-snaplist-carousel';

import { isMobile } from 'react-device-detect';
import { CarouselImage } from '../../../components/image';

import { CarouselNavigationButton } from '../../../components/carousel';

import Iconify from "../../../components/iconify";

import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack } from '../../../components/content';

import '../../../components/image/styles.css';

import useResponsive from '../../../hooks/useResponsive';
import { QuestionComponent } from '../../../components/quiz';
import HintOverlay from '../../../components/hint-overlay/hintOverlay';
import { formatUrl } from '../../../utils/formatUrl';
import { HintDialog } from '../../../components/hint-overlay';

// ----------------------------------------------------------------------

export default function ContentImageQuiz(content) {

  const { images, title, text, onComplete, fromMulti, multiTitle, contentRef, question, answers, onBack, hintImage, hintButtonText, hintIcon, hintContent = null, hintDelay = 25000 } = content;
  const snapList = useRef(null);

  const goToElement = useScroll({ ref: snapList });

  const useRelativePath = true;

  const isDesktop = useResponsive('up', 'lg');

  const [lightboxState, setLightboxState] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const lightboxCaptionsRef = useRef(null);

  /*
  const selected = useVisibleElements(
    { ref: snapList, debounce: 100 },
    elements => elements[0],
  );
  */

  const imageComponents = getImageComponents(images);

  const lightboxUrl = images.map(img => ({ src: getImageUrl(img), title: img.title, description: img.text }));

  const captionsBackgroundClass = "captions-background-gradient";

  const questionRef = useRef();
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    goToElement(1, { animationEnabled: false });
  }, [goToElement]);

  // ContentPage back button intercept, return true if we already handled something.
  useImperativeHandle(contentRef, () => ({
    requestBack() {
      if (lightboxState) {
        setLightboxState(false);
        return true;
      }
      return false;
    }

  }), [lightboxState])

  return (
    <>
      {/* <OrientationOverlay/> */}
      {
        hintContent && hintDelay > 0 ?
          <HintDialog hintContent={hintContent} delay={hintDelay} /> : null
      }

      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
        <ContentStack spacing={2} sx={{ paddingBottom: { xs: '88px', sm: '112px' } }}>
          <Container sx={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'center', flexGrow: '0' }}>
            <Stack alignItems='center' spacing={2}>
              <CombinedTitle title={title} subtitle={multiTitle} />
              <SubTitle variant="body1">
                {text}
              </SubTitle>
            </Stack>
          </Container>
          {imageComponents.length > 1 ?
            <Container sx={{ alignItems: 'center', display: 'flex', p: '0px !important', justifyContent: 'center', position: 'relative', flexGrow: 2 }}>

              <SnapList
                ref={snapList}
                tabIndex={0} // so it can receive focus and can be scrolled with keyboard
                role="region" // context for screen readers
                aria-label="carousel" // for screen readers to read out loud on focus
                className="carousel"
                style={{
                  flex: '2',
                  scrollPadding: '0px',
                  padding: '0px 12px',
                  WebkitMaskImage: 'linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%)',
                  maskImage: 'linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%)',
                  alignItems: 'flex-start',
                }}
              >
                {imageComponents}
              </SnapList>

              {!isMobile &&
                // hide scroll buttons if mobile touchscreen
                <>
                  {currentImage !== 0 &&
                    <CarouselNavigationButton onClick={() => previousImage()} sx={{ left: '8px' }} aria-label="previous content">
                      <Iconify sx={{ width: '24px', height: '24px', color: '#FFF' }} icon='ic:baseline-navigate-before' />
                    </CarouselNavigationButton>
                  }

                  {currentImage < images.length - 1 &&
                    <CarouselNavigationButton onClick={() => nextImage()} sx={{ right: '8px' }} aria-label="next content">
                      <Iconify sx={{ width: '24px', height: '24px', color: '#FFF' }} icon='ic:baseline-navigate-next' />
                    </CarouselNavigationButton>
                  }

                </>
              }

            </Container>
            :
            <Container sx={{ alignItems: 'center', display: 'flex', p: '0px !important', justifyContent: 'center', position: 'relative', flexGrow: '2' }}>
              {
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events , jsx-a11y/no-noninteractive-element-interactions
                <img style={{ maxHeight: '40vh', maxWidth: 'min(720px, 87vw)' }} src={getImageUrl(images[0])} onClick={() => { setLightboxState(true); selectImage(0); }} alt="afbeelding" />
              }
              <IconButton sx={{ backgroundColor: 'primary.main', color: 'background.paper', "&:hover": { backgroundColor: "#8b2733", color: 'background.paper' }, width: '56px', height: '56px', position: 'absolute', left: '50%', top: '50%', zIndex: '3', transform: 'translateX(-50%) translateY(-50%)' }} variant="filled" onClick={() => { setLightboxState(true); selectImage(0); }}>
                <Iconify icon="eva:search-fill" />
              </IconButton>
            </Container>
          }

          <QuestionComponent question={question} answers={answers} myRef={questionRef} disabled={completed} ignoreCase hint={hintContent} />

          {hintImage ?
            <Container sx={{ width: '100%', height: 'auto', position: 'relative', display: 'flex', flexDirection: 'column', flexGrow: '0', padding: '0px 16px !important' }}>
              <HintOverlay buttonText={hintButtonText} image={hintImage} iconifyIcon={hintIcon} />
            </Container> : null
          }


          <ButtonsContainer>
            {completed ?
              <Button sx={{ mb: '0px' }} variant="contained" onClick={() => { nextButton() }}>
                {fromMulti ? "Terug naar overzicht" : "Verder"}
              </Button>
              :
              <Button sx={{ mb: '0px' }} variant="contained" onClick={() => { checkAnswer() }}>
                {"Controleer antwoord"}
              </Button>
            }
            {
              onBack ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button> : null
            }
          </ButtonsContainer>
        </ContentStack>
      </Box>

      <Lightbox
        open={lightboxState}
        close={() => setLightboxState(false)}
        index={currentImage}
        slides={lightboxUrl}
        carousel={{
          finite: images.length <= 1
        }}
        toolbar={{
          buttons: [
            // define button order in toolbar
            isDesktop ? "captions" : null,
            "zoom",
            "close",
          ],
        }}
        captions={{
          showToggle: isDesktop, // show iconCaptionsVisible /Hidden
          ref: lightboxCaptionsRef,
          descriptionTextAlign: "start",
          descriptionMaxLines: 3,
        }}
        on={{
          click: () => {
            if (lightboxCaptionsRef.current?.visible) {
              lightboxCaptionsRef.current?.hide();
              toggleCaptionsGradientBackground(false);
            } else {
              lightboxCaptionsRef.current?.show();
              toggleCaptionsGradientBackground(true);
            }

            console.log(lightboxCaptionsRef);
          },
        }}
        render={{
          buttonPrev: images.length > 1 ? undefined : () => null,
          buttonNext: images.length > 1 ? undefined : () => null,
          iconCaptionsVisible: () => <Iconify sx={{ width: '24px', height: '24px' }} icon='ic:baseline-subtitles' />,
          iconCaptionsHidden: () => <Iconify sx={{ width: '24px', height: '24px' }} icon='ic:baseline-subtitles-off' />,
          iconZoomIn: () => <Iconify sx={{ width: '24px', height: '24px' }} icon='mdi:magnify-plus-outline' />,
          iconZoomOut: () => <Iconify sx={{ width: '24px', height: '24px' }} icon='mdi:magnify-minus-outline' />,
          iconClose: () => <Iconify sx={{ width: '24px', height: '24px' }} icon='ic:close' />
        }}
        plugins={[Zoom, Captions]}
        styles={{ root: { "--yarl__icon_size": "24px" } }}
        className={captionsBackgroundClass}
      />
    </>
  );

  function nextButton() {
    questionRef.current?.clearTextField();
    setCompleted(false);
    onComplete();
  }

  function checkAnswer() {
    if (questionRef.current?.checkAnswer()) {
      setCompleted(true);
    }

  }

  function toggleCaptionsGradientBackground(state) {
    const yarlRoot = document.getElementsByClassName("yarl__root")[0];

    if (state && !yarlRoot.classList.contains(captionsBackgroundClass)) {
      yarlRoot.classList.add(captionsBackgroundClass);
    }
    else if (yarlRoot.classList.contains(captionsBackgroundClass)) {
      yarlRoot.classList.remove(captionsBackgroundClass);
    }
  }

  function getImageComponents(items) {
    const carouselItems = [];
    for (let i = 0; i < items.length; i += 1) {
      carouselItems.push(
        <SnapItem key={i} margin={{ left: i === 0 ? '20vw' : '12px', right: i === items.length - 1 ? '20vw' : '12px', }} snapAlign="center">
          <CarouselImage selected={currentImage === i} multiple={images.length > 1} onClick={() => { setLightboxState(true); selectImage(i); }} image={getImageUrl(images[i])} />
        </SnapItem>
      );
    }
    return carouselItems;
  }

  function getImageUrl(imageObj) {
    if (useRelativePath) {
      return formatUrl(imageObj.url);
    }
    else {
      return imageObj.url;
    }
  }

  function nextImage() {
    selectImage(currentImage + 1);
  }

  function previousImage() {
    selectImage(currentImage - 1);
  }

  function selectImage(i) {
    setCurrentImage(i);
    goToElement(i);
    console.log('current image', i);
  }
}
