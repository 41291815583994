
// @mui
import { Box, Stack, Container, Button, IconButton } from '@mui/material';
import { useState, useImperativeHandle } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import Lottie from "lottie-react";
import animation from '../../../components/panorama/spherical-view.json';
import Iconify from '../../../components/iconify';
import { Panorama } from '../../../components/panorama';

import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack, Title } from '../../../components/content';
import { formatUrl } from '../../../utils/formatUrl';
import { SupportsWebGL } from '../../../utils/supportsWebgl';


const useWebgl = SupportsWebGL(true);
// ----------------------------------------------------------------------

export default function ContentPanorama(content) {

  const { url, title, text, onComplete, fromMulti, multiTitle, contentRef, infoSpots, onBack, allowSkip = false, correctInfoSpot = undefined, infoSpotIcon } = content;
  
  const [started, setStarted] = useState(false);
  const [startedOnce, setStartedOnce] = useState(false);
  // assume completed if invalid infospot is given, should be integer
  const [completed, setCompleted] = useState(correctInfoSpot === undefined || !Number.isFinite(correctInfoSpot) );

  const isCompactHeight = useMediaQuery('(min-width:1200px)');

   // ContentPage back button intercept, return true if we already handled something.
  useImperativeHandle(contentRef, () => ({
    requestBack() {
      if(started) {
        setStarted(false);
        return true;
      }
  
      return false;
    }
  
  }), [started])

  function onInfoSpot(index) {
    if(!completed && index === correctInfoSpot) {
      setCompleted(true);
    }
  }
  
function startPanorama() {
  if(useWebgl) {
    setStarted(true); 
    setStartedOnce(true);
  } else {
    onComplete();
  }
}

  return (
    <>
      {!started || !useWebgl ?
        // camera not started
        <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex'}}>
          <ContentStack spacing={2}>
            <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              <Stack alignItems='center' spacing={0}>
                <CombinedTitle title={title} subtitle={multiTitle}/>
                <SubTitle variant="body1">
                  {text}
                </SubTitle>
              </Stack>
            </Container>
            <Container sx={{ flexGrow: '1', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>

             
                <Box sx={{ height: 'clamp(172px, 1rem + 26vh, 392px)', width: 'clamp(172px, 1rem + 26vh, 392px)' }}>
                { useWebgl ?
                    <Lottie animationData={animation} />
                    :  
                    <h4>
                        Deze 3D-ervaring werkt niet in deze browser, of wordt geblokkeerd door je organisatie.
                    </h4>
                }
                </Box>
            </Container>

        <ButtonsContainer>
              <Button sx={{ mb: '0px' }} variant={startedOnce ? "outlined" : "contained"} onClick={() => { startPanorama(); }}> {!useWebgl ? 'Overslaan':(startedOnce ? 'Opnieuw starten' : 'Start')}</Button>
              {
                onBack ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button>: 
                  allowSkip ? <Button variant="outlined" onClick={() => { onComplete(); }}>{"Overslaan"}</Button> : null
              }
             { /* <Button sx={{ mb: '0px' }} variant={startedOnce ? "contained" : "outlined"} onClick={() => onComplete()}>{fromMulti ? "Terug naar overzicht" : (startedOnce ? "Verder" : "Overslaan")}</Button><></> */ }
            </ButtonsContainer>
          </ContentStack>
        </Box>

        :

        <Box sx={{
          display: 'flex', 
          position: 'fixed', 
          height: '100%',  
          width: '100%', 
          p: '0px !important', 
          zIndex: '9', 
          alignItems: 'center', 
          inset: '0 0 0 0', 
          backgroundImage: `url('${formatUrl('assets/images/background.jpg')}')`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'center',
          transform: 'translate3d(0,0,0)'
        }}>

                <style>
                    {
                    `header {
                        display: none !important;
                    }
                    nav {
                      display: none !important;
                    }`
                }
                </style>

          <Stack sx={{ width: '100%', height: '100%', position: isCompactHeight ? 'relative' : 'fixed', top: 0 }} textAlign="left">
            <Title variant="h4" sx={{position:'absolute', padding: '4px 8px', bottom: {xs:'88px', sm: '112px'}, left:'16px', right: '16px', margin:'auto', width: 'fit-content' }}> 
              {text} 
            </Title>
            <Panorama imageSrc={formatUrl(url)} infoSpots={infoSpots}  infoSpotIcon={infoSpotIcon} infoSpotCallback={val => onInfoSpot(val)} />
            { 
            <ButtonsContainer sx={{ position: 'absolute', bottom: '0', maxWidth: {md:'unset'}}}>
              <Button variant="contained" disabled={completed ? null : true}  onClick={() => onComplete()}>{fromMulti ? "Terug naar overzicht" : "Verder"}</Button>
            </ButtonsContainer>
            }
            {
              /* back button */
                <Container sx={{ display: 'flex', justifyContent: 'space-between', margin: '0', padding: '0 !important', position: 'absolute', top: '16px', left: '16px', width: 'auto' }}>
                  <IconButton sx={{  width: '40px', p: '8px', textTransform: 'none', color: '#FBEEED', backgroundColor: 'rgb(54 47 47 / 60%)', "&:hover": { backgroundColor: "rgb(54 47 47 / 68%)"}, }} onClick={() => { setStarted(false); }} aria-label="back" component="label">
                    <Iconify icon="ic:baseline-arrow-back" width='100%' height='100%' />
                  </IconButton>
                </Container>
            }
          </Stack>
        </Box>
      }
    </>
  );
}