
// @mui
import { useState, useRef, useImperativeHandle, useEffect } from 'react'
import { Box, Container, Button, Stack, IconButton } from '@mui/material';
// import useMediaQuery from '@mui/material/useMediaQuery';


// custom dialog
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';


import { ModelViewer } from '../../../components/modelviewer';
import Iconify from '../../../components/iconify';


import { ButtonsContainer, Title, CombinedTitle, SubTitle, ContentStack } from '../../../components/content';
import { QuestionComponent } from '../../../components/quiz';
import { formatUrl } from '../../../utils/formatUrl';
import { SupportsWebGL } from '../../../utils/supportsWebgl';
import { HintDialog } from '../../../components/hint-overlay';
import HintOverlay from '../../../components/hint-overlay/hintOverlay';


const useWebgl = SupportsWebGL(true);
// ----------------------------------------------------------------------

export default function ContentModelQuiz(content) {

    // const isCompactHeight = useMediaQuery('(min-width:1200px)');

    const { title, text, model, iosModel, poster, onComplete, fromMulti, allowPan, multiTitle, contentRef, question, answers, onBack, orbitScale, minOrbitOffset, hintContent = null, hintImage, hintButtonText, hintIcon, hintDelay = 25000 } = content;

    const iosMod = iosModel ? formatUrl(iosModel) : undefined;
    const [started, setStarted] = useState(false);
    const [startedOnce, setStartedOnce] = useState(false);
    // const [arInfoDialogState, setArInfoDialogState] = useState(false);

    const questionRef = useRef();
    const [completed, setCompleted] = useState(false);

    const touchRef = useRef(null);

    // const arHelpText = "Hier komt een handige instructie over AR.";


    // block mobile (safari) back drag gesture by intercepting touch on the background
    useEffect(() => {
        const handleTouch = (e) => {
            if(e.changedTouches && e.changedTouches.length > 0) {
                const touch = e.changedTouches[0]; 
                if(touch && (touch.pageX || touch.pageY)) {
                    // dont block the top x pixels to not block any buttons
                    if (e.pageY && e.pageY < 0.15 * window.innerHeight || e.pageX > 0.5 * window.innerWidth) return; // safari only
                    if (touch.pageY < 0.15 * window.innerHeight || touch.pageX > 0.5 * window.innerWidth) return;

                    // prevent swipe to navigate gesture
                    e.preventDefault();
                }
            }
        }
    
        const element = touchRef.current;
        if(started && element) {
            element?.addEventListener('touchstart', handleTouch );
        }
    
        return () => {
          element?.removeEventListener('touchstart', handleTouch);
        };
    }, [started]);
    

     // ContentPage back button intercept, return true if we already handled something.
    useImperativeHandle(contentRef, () => ({
        requestBack() {
          if(started) {
            setStarted(false);
            return true;
          }
      
          return false;
        }
      
      }), [started])

      function open3d() {
        if(!completed && useWebgl) {
            setStarted(true); 
            setStartedOnce(true);
        }
      }

      function checkAnswer() {
        if(questionRef.current?.checkAnswer()) {
          setCompleted(true);
        }
    
      }

      function nextButton() {
        setCompleted(false);
        setStarted(false);
        setStartedOnce(false);
        questionRef.current?.clearTextField();
        onComplete();
      }

    return (<>

        {(!started || !useWebgl) ?
            <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex', position: 'relative' }}>

                <ContentStack spacing={2}>

                    <Container sx={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'center', flexGrow: '0' }}>
                        <Stack alignItems='center' spacing={0}>
                            <CombinedTitle title={title} subtitle={multiTitle}/>
                            <SubTitle variant="body1">
                                {text}
                            </SubTitle>
                        </Stack>
                    </Container>

                    <Container sx={{ maxHeight: '40vh', pb: 2, flex: '2', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', position:'relative' }}>
                        { useWebgl ?
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                            <img style={{ maxHeight: '100%' }} src={formatUrl(poster)} alt="artefact" onClick={() => {open3d();}} className='unselectable'/> 
                            :  
                                <h4>
                                    Deze 3D-ervaring werkt niet in deze browser, of wordt geblokkeerd door je organisatie.
                                </h4>
                        }
                        { completed || !useWebgl ? null : 
                          <IconButton sx={{backgroundColor: 'primary.main', color: 'background.paper', "&:hover": { backgroundColor: "#8b2733", color: 'background.paper' }, width:'56px', height:'56px', position:'absolute', left:'50%', top:'50%', zIndex:'3', transform: 'translateX(-50%) translateY(-50%)'}} variant="filled" onClick={() => {open3d()}}>
                            <Iconify icon="eva:search-fill" />
                        </IconButton>
                        }
                    </Container>
                    <QuestionComponent question={question} answers={answers} myRef={questionRef} disabled={completed} ignoreCase hint={hintContent} />

                    <ButtonsContainer>
                        {
                            completed || !useWebgl ? 
                            <Button sx={{ mb: '0px' }} variant="contained" onClick={() => nextButton()}>{fromMulti ? "Terug naar overzicht" : "Verder"}</Button>
                            : <Button sx={{ mb: '0px' }} variant={"contained"} onClick={() => { checkAnswer() }}> {"Controleer antwoord"}</Button>

                        }
                        {
                            onBack ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button>: null
                        }
                    </ButtonsContainer>
                </ContentStack>
            </Box>
            :
            <Box 
              ref={touchRef}
              sx={{
                display: 'flex', 
                position: 'fixed', 
                height: '100%',  
                width: '100%', 
                p: '0px !important', 
                zIndex: '9', 
                alignItems: 'center', 
                inset: '0 0 0 0', 
                backgroundImage: `url('${formatUrl('assets/images/background.jpg')}')`, 
                backgroundSize: 'cover', 
                backgroundPosition: 'center',
                transform: 'translate3d(0,0,0)'
            }}>

                <style>
                    {
                    `header {
                        display: none !important;
                    }
                    nav {
                      display: none !important;
                    }`
                }
                </style>

                <Stack sx={{ width: '100%', height: '100%', justifyContent: 'space-between', position:'relative' }} spacing={2} textAlign="left" alignItems='center' >
                    <ModelViewer
                        modelSrc={formatUrl(model)}
                        iosModelSrc={iosMod} 
                        posterSrc={formatUrl(poster)}
                        orbitScale={orbitScale}
                        minOrbitOffset={minOrbitOffset}
                        allowPan={allowPan ?? false} >            
                        <>
                        {
                            hintContent && hintDelay > 0 ?
                                <HintDialog hintContent={hintContent} delay={hintDelay} /> : null
                            }

                            <Box sx={{width:'100vw',position:'absolute', bottom:'0px', display:'flex', gap:'8px', flexDirection:'column', flexGrow:'0', padding: '16px 16px !important'}}>
                                { hintImage ? 
                                    <Box sx={{height:'auto', position:'relative'}}>
                                        <HintOverlay buttonText={hintButtonText} image={hintImage} iconifyIcon={hintIcon} />
                                    </Box>
                                    : null 
                                }
                                <Title variant="h4" sx={{padding: '4px 8px', margin:'auto', width: 'fit-content' }}>
                                    {question}
                                </Title>
                            </Box> 

                            {/* <IconButton id="ar-info-button" sx={{ position: 'absolute', width: '56px', p: '16px', right: '16px', top: '16px', textTransform: 'none', color: 'common.black' }} onClick={() => { setArInfoDialogState(true) }} aria-label="info" component="label">
                                <Iconify icon="mdi:help-circle-outline" width='100%' height='100%' />
                            </IconButton> */}

                            { /* buttons needs to be a child with slot exit-webxr-ar-button */}
                            <IconButton slot="exit-webxr-ar-button" id="ar-close-button" sx={{ position: 'absolute', width: '40px', p: '8px', left: '16px', top: '16px', textTransform: 'none', color: '#FBEEED', background: 'rgb(54 47 47 / 60%)' }} aria-label="close" component="label">
                                <Iconify icon="ic:baseline-arrow-back" width='100%' height='100%' />
                            </IconButton>

                            { /* buttons needs to be a child with slot ar-button */}
                            <IconButton slot="ar-button" id="ar-button" sx={{ backgroundColor: 'primary.main', color: 'background.paper', "&:hover": { backgroundColor: "#8b2733", color: 'background.paper' }, top: '16px' }}>
                                <Iconify icon="mdi:cube-scan" width='100%' height='100%' />
                            </IconButton>

                            { /* custom dialog as modal dialog doesn't work over fullscreen ar */}
                            {/* <Container onClick={() => setArInfoDialogState(false)} sx={{ zIndex: '99', display: arInfoDialogState ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '0px', left: '0px', height: '100vh', width: '100vw', backgroundColor: '#00000099' }}>

                                <Container sx={{
                                    padding: '0px',
                                    boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
                                    borderRadius: '28px',
                                    backgroundColor: (theme) => theme.palette.background.appbar,
                                    minWidth: '240px'
                                }}>
                                    <DialogTitle id="alert-dialog-title" sx={{ padding: '24px 24px 16px 24px' }}>
                                        {title}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description" variant='body2' style={{ whiteSpace: 'pre-wrap' }}>
                                            {arHelpText}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions sx={{ padding: '0px 16px 8px 16px' }}>
                                        <Button sx={{ textTransform: 'none' }} onClick={() => { }} autoFocus>Sluiten</Button>
                                    </DialogActions>
                                </Container>
                            </Container> */}
                        </>
                    </ModelViewer>
                    



                </Stack>
                {
                    /* back button */
                    <Container sx={{ display: 'flex', justifyContent: 'space-between', margin: '0', padding: '0 !important', position: 'absolute', top: '16px', left: '16px', width: 'auto' }}>
                        <IconButton sx={{ width: '40px', p: '8px', textTransform: 'none', color: '#FBEEED', backgroundColor: 'rgb(54 47 47 / 60%)', "&:hover": { backgroundColor: "rgb(54 47 47 / 68%)"}, }} onClick={() => { setStarted(false); }} aria-label="back" component="label">
                            <Iconify icon="ic:baseline-arrow-back" width='100%' height='100%' />
                        </IconButton>
                    </Container>
                }

            </Box >
        }
    </>
    );
}

