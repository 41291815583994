
import { Box, Stack, Typography } from '@mui/material';
import { isMobile, isTablet } from 'react-device-detect';
import { useState } from 'react';
import OrientationIcon from "./OrientationIcon";

// ----------------------------------------------------------------------

function OrientationOverlay() {

  const [isLandscapeState, setOrientationState] = useState(isLandscape());

  window.onresize = (() => {
    if (isMobile || isTablet) {
      setTimeout(() => {
        setOrientationState(isLandscape());
      }, 100); 
    }
  });

  function isLandscape() {
    const { clientWidth, clientHeight } = document.documentElement;

    if (!(clientHeight > clientWidth)) {
      return true;
    }

    return false;
  }

  return (
    <>
      {(isMobile || isTablet) && isLandscapeState ?

        <Box sx={{ display: 'flex', position: 'fixed', height: '100vh', width: '100vw', p: '0px !important', zIndex: '10000', background: '#000000f0', alignItems: 'center', inset: '0 0 0 0', maxWidth: 'unset' }}>
          <Stack width='100%' sx={{ alignItems: 'center' }}>
            <Typography color="#fff" sx={{ p: '32px' }} variant="body1">
              Draai het scherm naar portret-modus
            </Typography>
            <OrientationIcon />
          </Stack>
        </Box>

        : null}
    </>
  );
}

export default OrientationOverlay;
