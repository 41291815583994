import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContentStack = styled(Stack)(({ theme }) => ({
    width: '100%', 
    height: '100%', 
    justifyContent: 'space-between', 
    paddingTop: 'clamp(1rem, 1rem + 2vh, 64px)',
    paddingBottom: 'clamp(112px, 112px + 2vh, 160px)',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: 'clamp(88px, 88px + 1vh, 160px)'
    },
    textAlign:'left', 
    alignItems:'center'
}));

export default ContentStack;