// @mui
import { Box, Stack, Container, Button } from '@mui/material';

// components
import AudioPlayer from 'react-h5-audio-player';
import { styled } from '@mui/material/styles';
import 'react-h5-audio-player/lib/styles.css';
import '../../../components/audio2/style.css'

import { formatUrl } from '../../../utils/formatUrl';

import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack, ButtonsContainerHeightBlocker } from '../../../components/content';

// ----------------------------------------------------------------------

export default function ContentAudio(content) {

  const { url, title, text, onComplete, fromMulti, multiTitle, onBack } = content;

  function onPlay() {
    console.log('onPlay');
  }

  // override some colors from stle.css to use theme values
  const StyledAudioPlayer = styled(AudioPlayer)(({ theme }) => ({
    '.rhap_play-pause-button': {
      color: theme.palette.primary.main,
    },
    '.rhap_progress-indicator': {
      backgroundColor: theme.palette.primary.main,
    },
    '.rhap_progress-filled': {
      backgroundColor: theme.palette.primary.main,
    },

     /* '.rhap_container': { */
    backgroundColor: theme.palette.background.alternative,
    background: `linear-gradient(0deg, ${theme.palette.background.appbar}, ${theme.palette.background.alternative})`,

  }));

  return (
    <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex'}}>
      <ContentStack spacing={2}>
        <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          <Stack alignItems='center' spacing={2}>
            <CombinedTitle title={title} subtitle={multiTitle}/>
            <SubTitle variant="body1">
              {text}
            </SubTitle>
          </Stack>
        </Container>
        <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <StyledAudioPlayer
            autoPlay={false}
            src={formatUrl(url)}
            loop={false}
            onPlay={() => onPlay()}
            hasDefaultKeyBindings={false}
            style={{ maxWidth: '600px' }}
            // hide most default buttons
            showSkipControls={false}
            showJumpControls={false}
            customAdditionalControls={[]}
          />
        </Container>
        
        <ButtonsContainerHeightBlocker />
        <ButtonsContainer>
          <Button variant="contained" onClick={() => { onComplete() }}>{fromMulti ? "Terug naar overzicht" : "Verder"}</Button>
          {
            onBack ? <Button variant="outlined" onClick={() => { onBack(); }}>{"Terug"}</Button>: null
          }
        </ButtonsContainer>
      </ContentStack>
    </Box>
  );
}
