import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
//
import Header from './header';
import Nav from './nav';
import { formatUrl } from '../../utils/formatUrl';

import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.neutral,
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
  position: 'relative',
}));

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  height: '100%',
  paddingTop: APP_BAR_MOBILE, // + 24,
  // paddingBottom: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    paddingTop: '0px', // APP_BAR_DESKTOP + 0,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  // backgroundImage: `url('${formatUrl('assets/images/background.jpg')}')`,
  backgroundAttachment: 'fixed',
}));

// ----------------------------------------------------------------------

export default function HomePageLayout() {
  const [open, setOpen] = useState(false);

  const isDesktop = useResponsive('up', 'lg');

  return (
    <StyledRoot sx={{marginLeft: isDesktop ? '280px' : '0px'}}>
      <StyledContainer sx={{padding: '0px !important'}}>
        <img style={{position: 'fixed', height: '100vh', width: '1200px', objectFit: 'cover', objectPosition: '50% 12%'}} alt="background" src={`${formatUrl('assets/images/background.jpg')}`} />
        <Header onOpenNav={() => setOpen(true)} />
        <Nav openNav={open} onCloseNav={() => setOpen(false)} />
        <Main sx={{ boxShadow: '0 0 8px 0px #00000014', zIndex: '1', position: 'relative'}}>
          <Outlet />
        </Main>
      </StyledContainer>
    </StyledRoot>
  );
}
