
// @mui
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { formatUrl } from '../../utils/formatUrl';
import Iconify from "../iconify";



export default function HintOverlay({ buttonText = 'Hint', iconifyIcon = 'mdi:eye', image}) {

  const [hidden, setHidden] = useState(true);

    return (
        <Box sx={{ width:'100%', alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end', flexDirection:'column', flexGrow: '0' }}>
            {
               !hidden ?
                <Box sx={{ background: '#FFF8F7', padding: '16px' ,position: 'absolute', bottom: '56px', maxWidth:{xs:'80%', sm:'50%', md:'40%'}, overflow:'hidden', filter: 'drop-shadow(4px 4px 4px #0000001f)', zIndex: '2'}}>
                    <img style={{maxHeight:'40vh'}} src={formatUrl(image)} alt="hint"/>
                </Box> : null
            }
            
            <Button sx={{ display: 'flex', flexDirection:'row', gap:'12px', backgroundColor: '#755A2F', '&:hover': { backgroundColor: '#5B421A'},}} variant="contained" onClick={() => { setHidden(!hidden); }}>
                {iconifyIcon ? <Iconify sx={{width: '20px', height: '20px', }} icon={iconifyIcon} />  : null}
                <span>{buttonText}</span>
            </Button>
        </Box>
    );

}

HintOverlay.propTypes = {
    buttonText: PropTypes.string,
    image: PropTypes.string,
    iconifyIcon: PropTypes.string,
}
