import { styled } from '@mui/material/styles';

import { IconButton } from '@mui/material';

const CarouselNavigationButton = styled(IconButton)({
  position: 'absolute',
  color: 'primary',
  background: 'rgb(0 0 0 / 60%)',
  ":hover": {
    background: 'rgb(0 0 0 / 87%)'
  }
});

export default CarouselNavigationButton;