import { ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

const NavListItemText = styled(ListItemText)(() => ({
  fontFamily: 'veneer-two',
  fontSize: '1.5rem',
  textTransform: 'uppercase',
  paddingTop: '0.25rem'
}));

export default NavListItemText;