/* eslint-disable react/prop-types */
import { useDroppable, useDraggable, DndContext } from '@dnd-kit/core';


import {
  restrictToWindowEdges,
  // restrictToParentElement,
} from '@dnd-kit/modifiers';


import { Box } from '@mui/material';

import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { formatUrl } from '../../utils/formatUrl';

function restrictToBoundingRect(
  transform,
  rect,
  boundingRect
) {
  const value = {
    ...transform,
  };

  if (rect.top + transform.y <= boundingRect.top) {
    value.y = boundingRect.top - rect.top;
  } else if (
    rect.bottom + transform.y >=
    boundingRect.top + boundingRect.height
  ) {
    value.y = boundingRect.top + boundingRect.height - rect.bottom;
  }

  if (rect.left + transform.x <= boundingRect.left) {
    value.x = boundingRect.left - rect.left;
  } else if (
    rect.right + transform.x >=
    boundingRect.left + boundingRect.width
  ) {
    value.x = boundingRect.left + boundingRect.width - rect.right;
  }

  return value;
}


// restrict only undropped draggables to parent.
export const customRestrictToParentElement = (props) => {

  const {
    containerNodeRect,
    draggingNodeRect,
    transform,
    active
  } = props;
  if (!draggingNodeRect || !containerNodeRect) {
    return transform;
  }
  if (active && active.id.includes('dropped')) {
    return transform;
  }

  return restrictToBoundingRect(transform, draggingNodeRect, containerNodeRect);
};





function DragPuzzle({ background, destinations, requireMatchingSize = true, onComplete }) {



  const [completed, setCompleted] = useState(false);

  const dropsToContent = useRef({});
  const dragsToDestination = useRef({});

  function dropDraggable(dragId, dropId) {
    dropsToContent.current[dropId] = dragId;
    dragsToDestination.current[dragId] = dropId;
  }

  function removeDraggable(dragId, dropId) {
    dragsToDestination.current[dragId] = undefined;
    dropsToContent.current[dropId] = undefined;
  }


  return (

    <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToWindowEdges]}>
      <Box sx={{ position: 'relative', maxHeight: '90vh', maxWidth: '100vw', display: 'inline-block' }}>
        <img
          height="100%"
          src={formatUrl(background)}
          className={"unclickable unselectable"}
          alt="background"
          loading="lazy"
          style={{ width: 'auto', height: 'auto', maxHeight: '90vh', maxWidth: '100vw', display: 'block' }}
        />
        {destinations.map(dest => (
          <Droppable key={`dest-${dest.id}`} id={`${dest.id}`} width={dest.sizeX} height={dest.sizeY} left={dest.x} top={dest.y} image={dest.image} />
        ))}

        { /* TODO make spawning more generic or define spawn positions in json */}
        {destinations.map((dest, index) => (
          <Dragable key={`drag-${dest.id}`} id={`${dest.id}`} width={dest.sizeX} height={dest.sizeY} left={5 + dest.sizeX * (index * 1.25)} top={90} image={dest.image} />
        ))}
      </Box>
    </DndContext>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    // trim unique id used for placed tiles
    const dragId = active.id.replace('dropped-', '');

    // hovering on a target
    if (over) {

      const dropId = over.id;
      const dest = destinations.find(d => d.id.toString() === dropId);
      const item = destinations.find(d => d.id.toString() === dragId);

      if (requireMatchingSize && (dest.sizeX !== item.sizeX || dest.sizeY !== item.sizeY)) {
        return;
      }

      const previousDrop = dragsToDestination.current[dragId];
      const alreadyInTarget = dropsToContent.current[dropId];

      // swap item in target to source
      if (previousDrop && alreadyInTarget && previousDrop !== dropId && alreadyInTarget !== dragId) {
        dropDraggable(alreadyInTarget, previousDrop);

        dropDraggable(dragId, dropId);
      } else {

        // if something is already dropped here, remove it.
        if (alreadyInTarget && alreadyInTarget !== dragId) {
          removeDraggable(alreadyInTarget, dropId);
        }
        // if we dragged from another drop point
        else if (previousDrop && previousDrop !== dropId) {
          removeDraggable(dragId, previousDrop);
        }

        dropDraggable(dragId, dropId);
      }
      checkCorrect();

    } else {
      // not over a dropable
      const currentDropId = dragsToDestination.current[dragId];

      if (currentDropId) {
        removeDraggable(dragId, currentDropId);
      } else {
        active.data.current?.updateOffset(event.delta.x, event.delta.y);
      }

    }
  }

  function checkCorrect() {
    let correct = true;
    for (let i = 0; i < destinations.length; i += 1) {
      // check if letter in the id matches the desired letter.
      if ((dragsToDestination.current[i] && dragsToDestination.current[i] === destinations[i].id.toString())) {
        // alright
      } else {
        correct = false;
        break;
      }
    }
    if (correct) {
      setCompleted(true);
      if (onComplete) {
        onComplete();
      }
    }
  }


  function Dragable(props) {

    const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
      id: props.id,
      disabled: props.disabled, // || completed,
    });


    const style = {
      visibility: dragsToDestination.current[props.id] ? 'hidden' : '',
      transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : '',
      position: 'absolute',
      top: props.dropped ? 'unset' : `${props.top}%`,
      left: props.dropped ? 'unset' : `${props.left}%`,

      zIndex: isDragging ? '4' : '3',
      width: `${props.width}%`,
      height: `${props.height}%`,
      outline: (props.dropped && !isDragging && !completed) ? '2px #ECE0DF solid' : 'unset',
      boxShadow: '0px 2px 4px #000000',
      cursor: props.disabled ? 'default' : 'grab',
      touchAction: 'none', // fix mobile chrome performance
    };

    return (
      <Box ref={setNodeRef} sx={style} {...listeners} {...attributes}>
        <img
          src={formatUrl(props.image)}
          alt="background"
          className={"unclickable unselectable"}
          loading="lazy"
          style={{ width: '100%' }}
        />
      </Box>
    );
  }


  function Droppable(props) {
    const { isOver, setNodeRef } = useDroppable({
      id: props.id,
      disabled: completed,
    });

    const style = {
      position: 'absolute',
      backgroundColor: isOver ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0)',
      width: `${props.width}%`,
      height: `${props.height}%`,
      left: `${props.left}%`,
      top: `${props.top}%`,
    };

    // get destination object to retrieve image
    let obj = null;
    if (dropsToContent.current[props.id]) {
      obj = destinations.find(d => d.id.toString() === dropsToContent.current[props.id]);
    }

    return (
      <Box ref={setNodeRef} sx={style}>
        {
          obj ?
            <Dragable disabled={completed} id={`dropped-${obj.id}`} dropped="true" image={obj.image} />
            : null
        }
      </Box>
    );
  }

}







DragPuzzle.propTypes = {
  onComplete: PropTypes.func,
  background: PropTypes.string,
  destinations: PropTypes.arrayOf(PropTypes.object),
  requireMatchingSize: PropTypes.bool,
};

export default DragPuzzle;
