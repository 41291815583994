// ----------------------------------------------------------------------

export default function Alert(/* theme */) {
    return {
        MuiAlert: {
            styleOverrides: {
                standardWarning: {
                    backgroundColor: '#FFF4E4',
                    color: '#6E3F0A'
                  },
         /*     standardSuccess: {
                backgroundColor: 'green',
                color: 'white'
              },
              standardError: {
                backgroundColor: 'red',
                color: 'white'
              },
              standardWarning: {
                backgroundColor: 'orange',
                color: 'white'
              },
              standardInfo: {
                backgroundColor: 'grey',
                color: 'black'
              }
            }
         */   
          }
        },
         
    };
  }
  