/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

// @mui
import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { formatUrl } from '../../utils/formatUrl';
import Iconify from "../iconify";
// import './styleVertical.css';

export default function HintOverlayDialog({ buttonText = 'Hint', iconifyIcon = 'mdi:eye', image}) {

  const [hidden, setHidden] = useState(true);

    return (
        <Box className="hint-container" sx={{ width: '100%', display:'flex', justifyContent: 'end' }}>
            <Box className='hint-button-container' sx={{}}>           
                <Button className='hint-button' sx={{ }} variant="contained" onClick={() => { setHidden(!hidden); }}>
                    {iconifyIcon ? <Iconify sx={{width: '20px', height: '20px', marginRight: '8px'}} icon={iconifyIcon} />  : null}
                    <span>{buttonText}</span>
                </Button>
            </Box>
            <Dialog
                open={!hidden}
                onClose={() => {setHidden(true)}}
                PaperProps={{ sx: { boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)', borderRadius: '4px', backgroundColor: 'background.appbar', minWidth: '240px', maxWidth: '560px' } }}
            >
                <img style={{maxWidth:'100%', maxHeight:'100%'}} src={formatUrl(image)} alt="hint" onClick={() => {setHidden(true);} }/>
            </Dialog>
        </Box>
    );

}

HintOverlayDialog.propTypes = {
    buttonText: PropTypes.string,
    image: PropTypes.string,
    iconifyIcon: PropTypes.string,
}
