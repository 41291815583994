// @mui
import { Stack, Container, TextField } from '@mui/material';
import { useState, useCallback, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Title } from '../content';
import { AlertDialog } from '../dialog';



export default function QuestionComponent({ question, answers, myRef, ignoreCase = false, disabled = false, hint }) {

    const [answer, setAnswer] = useState('');
    const [showError, setShowError] = useState(false);


    const [completeDialogState, SetCompleteDialogState] = useState(false); // complete Dialog
    const [wrongDialogState, SetWrongDialogState] = useState(false); // wrong answer Dialog

    const checkAnswer = useCallback(() => {
        let correct = answers && answers.includes(answer.trim());
        if(ignoreCase && !correct) {
           correct = answers && answers.map((a) => a.toLowerCase()).includes(answer.trim().toLowerCase());
        }
        setShowError(!correct);
        if (correct) {
            SetCompleteDialogState(true);
        } else {
            SetWrongDialogState(true);
        }
        return correct;
    }, [answer, answers, ignoreCase]);

    const clearTextField = useCallback(() => {
        setAnswer('');
    }, [setAnswer]);


    useImperativeHandle(myRef, () => ({
        checkAnswer,
        clearTextField
    }), [checkAnswer, clearTextField])






    return (
        <Container sx={{ alignItems: 'flex-end', display: 'flex', justifyContent: 'center', position: 'relative', flexGrow: '0' }}>
            <Stack sx={{ width: '100%', maxWidth: '600px' }} component="form" noValidate autoComplete="off">
                <Title variant="body1" sx={{ lineHeight: '1.2', marginRight: 'auto', padding: '4px 8px', marginBottom:'12px', rotate: '0deg' }}>
                    {question}
                </Title>
                <TextField value={answer} onChange={(event) => { setAnswer(event.target.value); setShowError(false); }}
                    error={showError} disabled={disabled}
                    onKeyDown={e => { 
                        if(e.key === 'Enter') { 
                            e.preventDefault();
                            // checkAnswer();
                            e.target.blur();
                        }
                    }}
                    sx={(theme) => ({
                            width: '100%', 
                            "& .MuiFilledInput-input": {
                                backgroundColor: '#FFF8F7',
                            }, 
                            "& .MuiFilledInput-input:focus": {
                                backgroundColor: '#FFF8F7' // select or focus
                            }, 
                            [theme.breakpoints.down("sm")]: {
                                "& .MuiFilledInput-input": {
                                    padding:'21px 12px 4px 12px'
                                }, 
                                "& .MuiInputLabel-root": {
                                    transform:'translate(12px, 13px) scale(1)'
                                },
                                "& .Mui-focused.MuiInputLabel-root , & .MuiFormLabel-filled": {
                                    transform: 'translate(12px, 7px) scale(0.75)',
                                }, 
                            },
                        })}
                    id="filled-basic" label="Antwoord" variant="filled" />
            </Stack>
            {/* correct dialog */}
            <AlertDialog open={completeDialogState} handleClose={() => SetCompleteDialogState(false)} title="Voltooid"
                content={"Dat is goed!"}
                primaryButtonText="Sluiten" secondaryButtonText=""
                primaryButtonFunc={() => { SetCompleteDialogState(false) }} secondaryButtonFunc={() => { }} />

            {/* wrong answer dialog */}
            <AlertDialog open={wrongDialogState} handleClose={() => SetWrongDialogState(false)} title="Verkeerd"
                content={`Dit is niet het juiste antwoord.${hint ? `\n\nHint: ${hint}` : ''}`}
                primaryButtonText="Opnieuw proberen" secondaryButtonText=""
                primaryButtonFunc={() => { SetWrongDialogState(false) }} secondaryButtonFunc={() => { }} />

        </Container>
    );

}

QuestionComponent.propTypes = {
    question: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.string),
    myRef: PropTypes.object,
    hint: PropTypes.string,
    ignoreCase: PropTypes.bool,
    disabled: PropTypes.bool,
}
