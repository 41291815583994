import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Button, Container, Box, Stack } from '@mui/material';
import { formatUrl } from '../utils/formatUrl';
import { ButtonsContainer, CombinedTitle, SubTitle, ContentStack,  } from '../components/content';

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <>
      <Helmet>
        <title> 404 | Expeditie Liemers </title>
      </Helmet>

      <Box sx={{ height: '100%', width: '100%', alignItems: 'baseline', display: 'flex' }}>
        <ContentStack spacing={2}>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Stack alignItems='center' spacing={2}>
              <CombinedTitle title='Sorry, pagina niet gevonden!'/>
              <SubTitle variant="body1">
                Sorry, we kunnen de pagina die u zoekt niet vinden. Misschien heeft u de URL verkeerd getypt? Controleer uw spelling.
              </SubTitle>
            </Stack>
          </Container>
          <Container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative', flexGrow: '2', maxWidth: '900px !important'}}>

          <Box
              component="img"
              src={ formatUrl('assets/illustrations/illustration_404.svg')}
              sx={{ height: 'clamp(172px, 1rem + 26vh, 392px)', width: 'clamp(172px, 1rem + 26vh, 392px)', mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          </Container>

          
          <ButtonsContainer>
            <Button  to="/" sx={{ mb: '0px' }} variant="contained" component={RouterLink}>Naar homepage</Button>
          </ButtonsContainer>
        </ContentStack>
      </Box>
  </>
  );
}