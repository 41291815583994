import { forwardRef } from "react";
import { ButtonBase, Typography, Box } from '@mui/material';

import PropTypes from 'prop-types';
import {Title} from '../content';
import { formatUrl } from '../../utils/formatUrl';


// const CAROUSEL_ITEM_SIZE = 300;

export const GRID_ITEM_HEIGHT =  '360px';

const GridItem = forwardRef(({ ...props }, ref) => (


  <Box sx={{ position:'relative', display:'flex',flexDirection: 'column', height: '100%', justifyContent:'flex-start', backgroundColor: 'background.paper' }}>
    <Box sx={{position: 'absolute', zIndex:'1', left: '50%', top:'-5%', transform: 'translateX(-50%)'}}>
      <Title variant="h4" primary={"true"} sx={{padding: '0.375rem 0.75rem 0.25rem 0.75rem',rotate: '-2deg', whiteSpace: 'nowrap'}}>
      {props.title}
      </Title>
    </Box>
    <ButtonBase sx={{height:'calc(100% - 50px)', overflow: 'hidden'}} onClick={() => {
        if(!props.locked) {
          props.callback(); 
        }
      }}>
      <Box
        sx={{
          // maxWidth: '70vw',
          // width: `${CAROUSEL_ITEM_SIZE}px`,
          // height: `${CAROUSEL_ITEM_SIZE}px`,
          backgroundColor: 'background.paper',
          backgroundSize: 'cover',
          borderRadius: '0px',
          height: '100%',
          width: '100%',
          display: 'flex',
        }}
        ref={ref}
      >

        {props.content.thumbnail !== (undefined || "") ?
          <img className={"unclickable unselectable"} style={{height: 'auto', width: '100%', objectFit: 'cover' }} src={formatUrl(props.content.thumbnail)} alt="img" />
          : null
        }

        { props.locked  ?
          <Box sx={{backgroundColor: '#a59a9aE6', position: 'absolute', left:'0', right:'0', top:'0', bottom:'0', display: 'flex', alignItems:'center', justifyContent:'center', }}>
            <img className={"unclickable unselectable"} style={{ width:'50px', objectFit: 'contain'}} src={formatUrl("assets/images/lock.png")} alt="img" />
          </Box>
          : null}
        {props.completed ?
          <img className={"unclickable unselectable"} style={{position: 'absolute', height:'60%', objectFit: 'contain', right:'12px', bottom:'12px', filter: 'drop-shadow(0px 2px 2px black)', WebkitFilter: 'drop-shadow(0px 2px 2px black)' }} src={formatUrl("assets/images/grid_completed.png")} alt="img" />
          : null}

      </Box>

    </ButtonBase>
    <Box sx={{height:'50px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#362F2F', color:  props.locked ? '#a59a9a' : 'background.paper' }}>
        <Typography variant='h4' sx={{ textAlign: 'center', textOverflow: 'ellipsis', width: '100%', padding: '0px 24px', whiteSpace: 'nowrap', overflow: 'hidden' }}>{props.content.title}</Typography>
    </Box>
  </Box>
));

GridItem.propTypes = {
  content: PropTypes.object,
  children: PropTypes.object,
  callback: PropTypes.func,
  completed: PropTypes.bool,
  locked: PropTypes.bool,
  title: PropTypes.string,
}

GridItem.displayName = "gridImage";

export default GridItem;