export function RequestCamera(onSuccess, onDenied, onFail) {
    try {
        if (navigator.mediaDevices.getUserMedia !== null) {
            const options = { audio: false, video: true }

            navigator.mediaDevices.getUserMedia(options)
                .then((stream) => {
                    // kill video feed that just opened
                    stream.getTracks().forEach(track => track.stop());
                    onSuccess?.call();
                })
                .catch(() => {
                    onDenied?.call();
                });
        }
        else {
            onFail?.call();
        }
    } catch (e) {
        onFail?.call();
    }
}