import Iconify from "../iconify";

import './styles.css';

// ----------------------------------------------------------------------

function OrientationIcon() {
  return (

    <Iconify className="rotate-90-ccw" color="#fff" icon="ic:baseline-screen-rotation" width="48px" height="48px" />
  );
}

export default OrientationIcon;
