
// @mui
import { Card, Box, Typography } from '@mui/material';

// components

// ----------------------------------------------------------------------

export default function Content() {
  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <Typography variant="h4" noWrap>
          NO CONTENT TYPE FOUND
        </Typography>
      </Box>
    </Card>
  );
}
